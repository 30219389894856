import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingService } from 'src/app/admin/_services/setting.service';
import { TelerikReportViewerComponent } from '@progress/telerik-angular-report-viewer';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { environment } from 'src/environments/environment';
import $ from 'jquery';
@Component({
   selector: 'app-report-reconciliation-overview-by-account',
   templateUrl: './report-reconciliation-overview-by-account.component.html',
   styleUrls: ['./report-reconciliation-overview-by-account.component.scss'],
   animations: [fadeInOnEnterAnimation()],
})
export class ReportReconciliationOverviewByAccountComponent implements OnInit, AfterViewInit {
   @ViewChild('viewer1') viewer: TelerikReportViewerComponent;
   dateUpdated = false; // Flag to indicate that date format has been set
   reportName: string;
   companyName: string;
   registrationNumber: string;
   logo: string;
   registerDescription: string;

   financialYearEnd: Date;
   monthStart: Date;
   monthEnd: Date;
   reportMonth: string;

   //{ path: 'reports/:type/:registerDescription/:approvedById/:approvalDate/:workflowTransactionId', component: ReportJournalApprovalComponent },
   //{ path: 'reports/:type/:financialYearEnd/:month/:monthStart/:monthEnd', component: ReportReconciliationOverviewByAccountComponent },
   viewerContainerStyle = {
      position: 'fixed',
      top: '74px',
      bottom: '0',
      right: '0',
      left: '240px',
      ['font-family']: 'ms sans serif',
   };

   Url = environment.apiUrl + 'reports';
   constructor(private route: ActivatedRoute, private settingService: SettingService) {}

   ngAfterViewInit(): void {
      this.settingService.getSettings().subscribe((settingList) => {
         settingList.forEach((setting) => {
            if (setting.id === 'LOGO') {
               if (setting.settingValue === '') {
                  this.logo = 'Company.png';
               } else {
                  this.logo = '../../' + setting.settingValue;
               }
            }
            if (setting.id === 'COMPNAME') {
               this.companyName = setting.settingValue;
            }
            if (setting.id === 'COMPREGNO') {
               this.registrationNumber = setting.settingValue;
            }
         });
         this.route.paramMap.subscribe((params) => {
            this.reportName = 'Reconciliation' + '.trdp';

            var mDate = new Date(new Date(params.get('financialYearEnd')));
            var userTimezoneOffsetTo = mDate.getTimezoneOffset() * 60000;
            this.financialYearEnd = new Date(mDate.getTime() - userTimezoneOffsetTo);

            this.reportMonth = params.get('reportMonth');

            var mDate = new Date(new Date(params.get('monthStart')));
            var userTimezoneOffsetTo = mDate.getTimezoneOffset() * 60000;
            this.monthStart = new Date(mDate.getTime() - userTimezoneOffsetTo);

            var mDate = new Date(new Date(params.get('monthEnd')));
            var userTimezoneOffsetTo = mDate.getTimezoneOffset() * 60000;
            this.monthEnd = new Date(mDate.getTime() - userTimezoneOffsetTo);
         });

         const rs = {
            report: this.reportName,
            parameters: {
               Logo: this.logo,
               CompanyName: this.companyName + ' - ' + this.registrationNumber,
               FinancialYearEnd: this.financialYearEnd,
               ReportMonth: this.reportMonth,
               MonthStart: this.monthStart,
               MonthEnd: this.monthEnd,
               ReconVal: '1',
            },
         };
         this.viewer.setReportSource(rs);
      });
   }

   ngOnInit() {}

   setDateFormat(dateFormat) {
      var datepicker = $('#reportViewer1').find("[data-role='datepicker']");
      if (datepicker.length > 0) {
         this.dateUpdated = true;

         for (var i = 0; i < datepicker.length; i += 1) {
            var dp = $(datepicker[i]).data('kendoDatePicker');
            var oldValue = dp.value();

            dp.setOptions({
               format: dateFormat,
            });
            dp.value(oldValue);
         }
      }
   }

   onUpdateUi() {
      // Skip if date format already set
      if (!this.dateUpdated) {
         this.setDateFormat('dd/MM/yyyy'); //SPECIFY THE DATE FORMAT HERE
      }
   }
}
