import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BusinessArea } from '../../_models/businessArea';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessAreaService {
  baseUrl = environment.apiUrl;
  businessareaSelected = new Subject<BusinessArea>();
  costCentreSelected = new Subject<BusinessArea>();
  ImportSelected = new Subject<BusinessArea>();

  constructor(private http: HttpClient) {}

  changeEditState(ba: BusinessArea) {
    this.businessareaSelected.next(ba);
  }
  changeImportState(businessarea: BusinessArea) {
    this.ImportSelected.next(businessarea);
  }
  showCostCentres(ba: BusinessArea) {
    this.costCentreSelected.next(ba);
  }

  getBusinessAreasFull() {
    return this.http.get<BusinessArea[]>(this.baseUrl + 'businessarea/list/full');
  }

  getBusinessArea(id): Observable<BusinessArea> {
    return this.http.get<BusinessArea>(this.baseUrl + 'businessarea/' + encodeURIComponent(id));
  }
  updateBusinessArea(id: string, businessArea: BusinessArea) {
    return this.http.put(this.baseUrl + 'businessarea/' + encodeURIComponent(id), businessArea);
  }
  createBusinessArea(businessArea: BusinessArea) {
    return this.http.post(this.baseUrl + 'businessarea', businessArea);
  }
  deleteBusinessArea(id: string) {
    return this.http.delete(this.baseUrl + 'businessarea/' + encodeURIComponent(id));
  }
  export(OnlyTemplate: boolean): Observable<Blob> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(this.baseUrl + 'businessarea/export/' + OnlyTemplate, {
      responseType: 'blob'
    });
  }
}
