import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { environment } from 'src/environments/environment';
import { toODataString, toDataSourceRequestString, translateDataSourceResultGroups } from '@progress/kendo-data-query';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

@Injectable({
   providedIn: 'root',
})
export class ListService extends BehaviorSubject<GridDataResult> {
   public loading: boolean;
   public tableName: string;
   public param1: string;
   public param2: string;
   public param3: string;
   public param4: string;
   public param5: string;
   private BASE_URL = environment.apiUrl;
   refreshTrigger = new Subject<string>();
   importStarted = new Subject<string>();
   constructor(private http: HttpClient) {
      super(null);
   }
   refreshTable(gridName: string, param1: string = '', param2: string = '', param3: string = '', param4: string = '', param5: string = '') {
      if (param1 !== '') {
         this.param1 = param1;
      }
      if (param2 !== '') {
         this.param2 = param2;
      }
      if (param3 !== '') {
         this.param3 = param3;
      }
      if (param4 !== '') {
         this.param4 = param4;
      }
      if (param5 !== '') {
         this.param5 = param5;
      }
      this.refreshTrigger.next(gridName);
   }
   importTable(gridName: string) {
      this.importStarted.next(gridName);
   }
   public query(state: any): void {
      this.fetch(this.tableName, this.param1, this.param2, this.param3, this.param4, this.param5, state).subscribe((x) => super.next(x));
   }

   protected fetch(tableName: string, param1: string, param2: string, param3: string, param4: string, param5: string, state: any): Observable<GridDataResult> {
      const queryStr = `${toDataSourceRequestString(state)}`; // Serialize the state
      const hasGroups = state.group && state.group.length;
      this.loading = true;
      let url = `${this.BASE_URL}${tableName}?${queryStr}`;
      if (tableName === 'Roles') {
         url = `${this.BASE_URL}${tableName}/paging?${queryStr}`;
      } else if (tableName === 'Asset') {
         url = `${this.BASE_URL}${tableName}/grouping/${encodeURIComponent(param1)}?${queryStr}`;
      } else if (tableName === 'journalintegration/journallist') {
         url = `${this.BASE_URL}${tableName}/${encodeURIComponent(param1)}/${encodeURIComponent(param2)}/${encodeURIComponent(param3)}/${encodeURIComponent(
            param4
         )}/${encodeURIComponent(param5)}?${queryStr}`;
      } else if (tableName === 'rfid/rfiMovement') {
         url = `${this.BASE_URL}${tableName}/?isSecurity=${encodeURIComponent(param1)}&${queryStr}`;
      } else if (tableName === 'ImportAsset') {
         url = `${this.BASE_URL}${tableName}?reviewStatus=${encodeURIComponent(param1)}&${queryStr}`;
      } else if (tableName === 'Question') {
         if (param1 === undefined) {
            param1 = '0';
         }
         url = `${this.BASE_URL}${tableName}/unlinkedlist/${encodeURIComponent(param1)}?${queryStr}`;
      } else if (tableName === 'InventoryList') {
         url = `${this.BASE_URL}${tableName}/${encodeURIComponent(param1)}/${encodeURIComponent(param2)}?${queryStr}`;
      } else if (tableName === 'InventoryListPhoto') {
         if (param2 === '') {
            param2 = '==';
         }
         url = `${this.BASE_URL}InventoryList/lists/photos/${encodeURIComponent(param1)}/${encodeURIComponent(param2)}?${queryStr}`;
      } else if (tableName === 'Transaction') {
         url = `${this.BASE_URL}${tableName}/${encodeURIComponent(param1)}?${queryStr}`;
      } else if (tableName === 'Commission') {
         url = `${this.BASE_URL}${tableName}/processed/${encodeURIComponent(param1)}/${param2}?${queryStr}`;
      } else if (tableName === 'ClassificationAccount') {
         url = `${this.BASE_URL}${tableName}/classaccountlist?${queryStr}`;
      } else if (tableName === 'Workflowopen') {
         url = `${this.BASE_URL}workflow/transaction/list/open?${queryStr}`;
      } else if (tableName === 'Workflowall') {
         url = `${this.BASE_URL}workflow/transaction/list/all?${queryStr}`;
      } else if (tableName === 'movementArchive') {
         url = `${this.BASE_URL}movement/MovementArchive?${queryStr}`;
      } else if (tableName === 'Workflowrejected') {
         url = `${this.BASE_URL}workflow/transaction/list/rejected?${queryStr}`;
      } else if (tableName === 'Condition') {
         url = `${this.BASE_URL}condition/header?${queryStr}`;
      } else if (tableName === 'Component' && param1 != null) {
         url = `${this.BASE_URL}${tableName}/masterasset/${encodeURIComponent(param1)}?${queryStr}`;
      } else if (!(param1 === undefined || param1 === null)) {
         url = `${this.BASE_URL}${tableName}/${encodeURIComponent(param1)}?${queryStr}`;
      }
      else if (tableName === 'BmmBooking' && param1 != null) {
         url = `${this.BASE_URL}${tableName}/BmmBookings?${queryStr}`;
      }
      else if (tableName === 'LeaseAgreementAsset' && param1 != null) {
         url = `${this.BASE_URL}${tableName}/${encodeURIComponent(param1)}?${queryStr}`;
      }
      else if (tableName === 'LeaseAgreement/additionalpayments' && param1 != null) {
         url = `${this.BASE_URL}${tableName}/${encodeURIComponent(param1)}?${queryStr}`;
      }
      else if (tableName === 'LeaseAgreement/interestratechanges' && param1 != null) {
         url = `${this.BASE_URL}${tableName}/${encodeURIComponent(param1)}?${queryStr}`;
      }
      else if (tableName === 'LeaseAgreement/takeons' && param1 != null) {
         url = `${this.BASE_URL}${tableName}/${encodeURIComponent(param1)}?${queryStr}`;
      }

      return this.http.get(url).pipe(
         map(
            (
               { data, total /*, aggregateResults*/ }: GridDataResult // Process the response
            ) =>
               <GridDataResult>{
                  // If there are groups, convert them to a compatible format
                  data: hasGroups ? translateDataSourceResultGroups(data) : data,
                  total: total,
               }
         ),
         tap(() => (this.loading = false))
      );
   }
}
