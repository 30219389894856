<mdb-card cascade="true" class="p-2">
   <div class="d-flex">
      <kendo-skeleton shape="rectangle" animation="pulse" [width]="'70px'"></kendo-skeleton>

      <div class="flex-1 m-2">
         <kendo-skeleton shape="text" animation="pulse" [width]="'100%'"></kendo-skeleton>
         <kendo-skeleton shape="text" animation="pulse" [width]="'100%'"></kendo-skeleton>
      </div>
   </div>
   <div *ngIf="Size === 'LARGE'">
      <kendo-skeleton shape="text" animation="pulse" [width]="'100%'"></kendo-skeleton>
      <kendo-skeleton shape="text" animation="pulse" [width]="'100%'"></kendo-skeleton>
      <kendo-skeleton shape="text" animation="pulse" [width]="'100%'"></kendo-skeleton>
      <kendo-skeleton shape="text" animation="pulse" [width]="'100%'"></kendo-skeleton>
   </div>
</mdb-card>
