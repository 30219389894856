import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { User } from '../_models/user';
import { UserService } from '../admin/_services/user.service';
import { AMSClient } from '../_models/amsClient';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.apiUrl + 'auth/';
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  currentUser: User;
  photoUrl = new BehaviorSubject<string>('');
  currentPhotoUrl = this.photoUrl.asObservable();

  constructor(private http: HttpClient, private userService: UserService) { }

  changeMemberPhoto(photoUrl: string) {
    this.photoUrl.next(photoUrl);
  }

  portallogin(model: any) {
    if (model) {
      localStorage.setItem('token', model.backend.token);
      localStorage.setItem('user', JSON.stringify(model.user));

      localStorage.setItem('isReset', 'false');
      this.currentUser = model.user;
      this.decodedToken = this.jwtHelper.decodeToken(model.backend.token);

      this.changeMemberPhoto(this.currentUser.url);
    }
  }

  login(model: any) {
    return this.http.post(this.baseUrl + 'login', model).pipe(
      map((response: any) => {
        const user = response;
        if (user) {
          localStorage.setItem('token', user.token);
          localStorage.setItem('user', JSON.stringify(user.user));
          localStorage.setItem('isReset', JSON.stringify(user.isPasswordReset));
          this.currentUser = user.user;

          this.decodedToken = this.jwtHelper.decodeToken(user.token);

          this.changeMemberPhoto(this.currentUser.url);
        }
      })
    );
  }
  register(user: User) {
    return this.http.post(this.baseUrl + 'register', user);
  }

  registerClient(client: AMSClient) {
    return this.http.post(environment.apiUrl + 'AMSClient', client);
  }
  loggedIn() {
    const isReset = localStorage.getItem('isReset');
    const token = localStorage.getItem('token');
    if (isReset === 'true') {
      return false;
    }
    return !this.jwtHelper.isTokenExpired(token);
  }

  roleMatch(allowedRoles): boolean {
    let isMatch = false;
    const token = localStorage.getItem('token');
    if (this.jwtHelper.isTokenExpired(token)) {
      return false;
    }
    if (this.decodedToken) {
      const userRoles = this.decodedToken.Permission as Array<string>;
      if (!userRoles) {
        return isMatch;
      }
      allowedRoles.forEach((element: string) => {
        if (Array.isArray(userRoles)) {
          if (userRoles.includes(element)) {
            isMatch = true;
            return isMatch;
          }
        } else {
          if (userRoles === element) {
            isMatch = true;
            return isMatch;
          }
        }
      });
    }
    return isMatch;
  }
  checkMigration(): Observable<MigrationMessage> {
    return this.http.get<MigrationMessage>(environment.apiUrl + 'auth/migrationcheck');
  }

  checkSiteExpired(): Observable<number> {
    return this.http.get<number>(environment.apiUrl + 'AMSClient/check');
  }
}

export interface MigrationMessage {
  message: string;
}
