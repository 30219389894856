import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Notify } from '../_models/notify';

@Injectable({
   providedIn: 'root',
})
export class NotifyService {
   notifySubject = new BehaviorSubject<Notify>({
      content: '-',
      type: '-',
   });
   constructor() {}

   notify(n: Notify) {
      this.notifySubject.next(n);
   }
   success(message: string) {
      this.notify({
         content: message,
         type: 'success',
      });
   }
   error(message: string) {
      this.notify({
         content: message,
         type: 'error',
      });
   }
   warning(message: string) {
      this.notify({
         content: message,
         type: 'warning',
      });
   }
   info(message: string) {
      this.notify({
         content: message,
         type: 'info',
      });
   }
}
