import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/_models/user';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/admin/_services/user.service';
import { NotifyService } from 'src/app/_services/notify.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
   selector: 'app-login-Password-Change',
   templateUrl: './loginPasswordChange.component.html',
   styleUrls: ['./loginPasswordChange.component.scss'],
   animations: [fadeInOnEnterAnimation()],
})
export class LoginPasswordChangeComponent implements OnInit, OnDestroy {
   public opened = false;
   passwordForm: UntypedFormGroup;
   user: User;
   loading = false;
   @Input()
   isPasswordReset: boolean;
   private userPasswordSubscription: Subscription;
   constructor(private userService: UserService, private notify: NotifyService, private routerService: Router, private fb: UntypedFormBuilder) {}

   ngOnInit() {
      this.createForm();
      this.userPasswordSubscription = this.userService.passwordSelected.subscribe((user: User) => {
         this.user = user;
         this.opened = true;
         // if (user) {
         //   this.userService.getUser(user.id).subscribe((userData: User) => {
         //     this.user = userData;
         //     this.opened = true;
         //   });
         // }
      });
   }
   ngOnDestroy() {
      this.userPasswordSubscription.unsubscribe();
   }
   createForm() {
      this.passwordForm = this.fb.group(
         {
            password: ['', [Validators.minLength(6), Validators.maxLength(15), Validators.required]],
            confirmPassword: [''],
         },
         { validator: this.passwordMatchValidator }
      );
   }
   passwordMatchValidator(g: UntypedFormGroup) {
      return g.get('password').value === g.get('confirmPassword').value ? null : { mismatch: true };
   }
   closeDialog() {
      this.opened = false;
   }
   save() {
      this.loading = true;
      let passUser: User;
      passUser = Object.assign({}, this.passwordForm.value);
      passUser.isPasswordReset = this.isPasswordReset;
      passUser.username = this.user.username;

      this.userService
         .resetPassword(passUser)
         .pipe(
            finalize(() => {
               this.loading = false;
            })
         )
         .subscribe(
            () => {
               var message = 'Reset Successful';
               this.notify.success(message);
               this.closeDialog();
               this.passwordForm.reset();
               const isReset = localStorage.getItem('isReset');
               if (isReset === 'true') {
                  localStorage.setItem('isReset', JSON.stringify(false));
                  this.routerService.navigate(['/']);
               }
            },
            (error) => {
               var message = error;
               this.notify.error(message);
            }
         );
   }
}
