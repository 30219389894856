import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { toDataSourceRequestString, translateDataSourceResultGroups } from '@progress/kendo-data-query';
import { tap, map } from 'rxjs/operators';

@Injectable({
   providedIn: 'root',
})
export class ListSecondService extends BehaviorSubject<GridDataResult> {
   public loading: boolean;
   public tableName: string;
   public param1: string;
   private BASE_URL = environment.apiUrl;
   refreshTrigger = new Subject<string>();
   importStarted = new Subject<string>();
   constructor(private http: HttpClient) {
      super(null);
   }
   refreshTable(gridName: string, param1: string = '') {
      if (param1 !== '') {
         this.param1 = param1;
      }
      this.refreshTrigger.next(gridName);
   }
   importTable(gridName: string) {
      this.importStarted.next(gridName);
   }
   public query(state: any): void {
      this.fetch(this.tableName, this.param1, state).subscribe(x => super.next(x));
   }

   protected fetch(tableName: string, param1: string, state: any): Observable<GridDataResult> {
      const queryStr = `${toDataSourceRequestString(state)}`; // Serialize the state
      const hasGroups = state.group && state.group.length;
      this.loading = true;
      let url = `${this.BASE_URL}${tableName}?${queryStr}`;

      if (tableName === 'QuestionSetup') {
         if (param1 === undefined) {
            param1 = '0';
         }
         url = `${this.BASE_URL}${tableName}/linkedlist/${encodeURIComponent(param1)}?${queryStr}`;
      } else if (tableName === 'Component' && param1 != null) {
         url = `${this.BASE_URL}${tableName}/masterasset/${encodeURIComponent(param1)}?${queryStr}`;
      }
      return this.http.get(url).pipe(
         map(
            (
               { data, total /*, aggregateResults*/ }: GridDataResult // Process the response
            ) =>
               <GridDataResult>{
                  // If there are groups, convert them to a compatible format
                  data: hasGroups ? translateDataSourceResultGroups(data) : data,
                  total: total,
               }
         ),
         tap(() => (this.loading = false))
      );
   }
}
