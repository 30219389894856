<div class="k-overlay" *ngIf="opened"></div>
<kendo-window title="Change Password" *ngIf="opened" (close)="closeDialog()" [@fadeInOnEnter]>
  <div>
    <mdb-card cascade="true" narrower="true">
      <!-- <div class="view view-cascade gradient-card-header peach-gradient narrower py-4 mx-4 mb-3 d-flex align-items-center">
        <h6 class="white-text font-weight-bold text-uppercase mb-0">Change Password</h6>
      </div> -->
      <form [formGroup]="passwordForm" (ngSubmit)="save()">
        <div class="modal-body mx-3">
          <div>
            <kendo-floatinglabel text="Password *" class="w-100">
              <input kendoTextBox type="password" id="passwordEdit" name="password" formControlName="password" class="w-100" />
            </kendo-floatinglabel>
            <div class="red-text error-text" *ngIf="passwordForm.controls['password'].hasError('required') && (passwordForm.controls['password'].dirty || passwordForm.controls['password'].touched)">
              Password required
            </div>
            <div class="red-text error-text" *ngIf="passwordForm.controls['password'].hasError('maxlength') && (passwordForm.controls['password'].dirty || passwordForm.controls['password'].touched)">
              Max length of 15 characters exceeded
            </div>
            <div class="red-text error-text" *ngIf="passwordForm.controls['password'].hasError('minlength') && (passwordForm.controls['password'].dirty || passwordForm.controls['password'].touched)">
              Min length of 6 characters
            </div>
            <div class="red-text error-text" *ngIf="passwordForm.hasError('mismatch') && passwordForm.get('confirmPassword').touched">Passwords must match</div>
          </div>
          <div>
            <kendo-floatinglabel text="Confirm Password *" class="w-100">
              <input kendoTextBox type="password" id="confirmPasswordEdit" name="confirmPassword" formControlName="confirmPassword" class="w-100" />
            </kendo-floatinglabel>

            <div
              class="red-text error-text"
              *ngIf="passwordForm.controls['confirmPassword'].hasError('required') && (passwordForm.controls['confirmPassword'].dirty || passwordForm.controls['confirmPassword'].touched)"
            >
              Confirm Password required
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button *ngIf="!loading" [disabled]="passwordForm.invalid" look="flat" kendoButton type="submit" icon="save" [primary]="true">Save</button>
          <mdb-spinner *ngIf="loading" spinnerType="small"></mdb-spinner>
        </div>
      </form>
    </mdb-card>
  </div>
</kendo-window>
