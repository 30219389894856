<div class="container mt-5" kendoRippleContainer>
  <div class="row align-items-center justify-content-center mt-5">
    <mdb-card>
      <mdb-card-header class="primary-color white-text text-center py-4">
        <img class="block-center rounded ml-5 mr-5" src="assets/img/amsLogo.png" alt="Image" />
      </mdb-card-header>

      <!--Card content-->
      <mdb-card-body class="px-lg-5 pt-0">
        <div class="text-center pt-4">
          <h5>
            <strong>Register</strong>
          </h5>
        </div>

        <form [formGroup]="valForm" class="form-validate mb-3" role="form" name="valForm" novalidate="" (submit)="register($event)">
          <div>
            <kendo-floatinglabel text="Client Code" class="w-100">
              <input id="clientCode" name="clientCode" formControlName="clientCode" kendoTextBox />
            </kendo-floatinglabel>
            <div class="red-text error-text" *ngIf="valForm.controls['clientCode'].hasError('required') && (valForm.controls['clientCode'].dirty || valForm.controls['clientCode'].touched)">
              Client Code required
            </div>
          </div>

          <div>
            <kendo-floatinglabel text="Site Code" class="w-100">
              <input type="siteCode" id="siteCode" name="siteCode" formControlName="siteCode" kendoTextBox />
            </kendo-floatinglabel>
            <div class="red-text error-text" *ngIf="valForm.controls['siteCode'].hasError('required') && (valForm.controls['siteCode'].dirty || valForm.controls['siteCode'].touched)">
              Site required
            </div>
         
          </div>

          <div>
            <kendo-floatinglabel text="Password" class="w-100">
              <input type="password" id="password" name="password" formControlName="password" kendoTextBox />
            </kendo-floatinglabel>
            <div class="red-text error-text" *ngIf="valForm.controls['password'].hasError('required') && (valForm.controls['password'].dirty || valForm.controls['password'].touched)">
              Password required
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button *ngIf="!loading" [disabled]="valForm.invalid" kendoButton type="submit" icon="lock" class="w-100 mt-3" [primary]="true">Register</button>

            <mdb-spinner *ngIf="loading" spinnerType="small"></mdb-spinner>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
