<!--Double navigation-->
<header>
   <!-- Sidebar navigation -->
   <!-- <div *ngIf="showMenu"></div> ---------------- Disabled portal menu ----------------  -->
      <mdb-side-nav #sidenav class="wide sn-bg-1 fixed" [fixed]="true">
         <mdb-navbar-brand>
            <!-- Logo -->
            <li>
               <div class="logo-wrapper waves-light">
                  <a href="#"><img src="../../assets/img/amsLogo.png" class="img-fluid flex-center" /></a>
               </div>
            </li>
            <li>
               <small title="Version" class="text-white mt-1 flex-center">V3.13.8</small>
            </li>
            <!--/. Logo -->
         </mdb-navbar-brand>

         <links>
            <!-- Side navigation links -->
            <li>
               <ul class="collapsible collapsible-accordion">
                  <mdb-accordion [multiple]="true" aria-multiselectable="false">
                     <!-- Collapsible link -->
                     <mdb-accordion-item
                        li
                        *appHasRole="['SYSTEM-ADMIN', 'ADMIN-USERS-VIEW', 'ADMIN-ROLES-VIEW', 'ADMIN-SYSTEM_ERRORS-VIEW', 'ADMIN-REPORTS-VIEW', 'ADMIN-RELEASE_NOTES-VIEW']"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Admin</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <li *appHasRole="['SYSTEM-ADMIN', 'ADMIN-USERS-VIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/admin/user']" class="waves-effect" mdbWavesEffect><i class="fa fa-user mr-2"></i> Users</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'ADMIN-ROLES-VIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/admin/role']" class="waves-effect" mdbWavesEffect><i class="fa fa-users mr-2"></i> Roles</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'ADMIN-SYSTEM_ERRORS-VIEW', 'ADMIN-SYSTEM_ERRORS-PRINTVIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/admin/errorlog']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-exclamation-circle mr-2"></i> System Errors</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN']">
                                 <a routerLinkActive="active" [routerLink]="['/admin/auditlog']" class="waves-effect" mdbWavesEffect><i class="fa fa-pen mr-2"></i> Audit Log</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'ADMIN-REPORTS-VIEW', 'ADMIN-REPORTS-PRINTVIEW']">
                                 <a [routerLink]="['/admin/reportdashboard']" class="waves-effect" mdbWavesEffect><i class="fa fa-print mr-2"></i> Reports</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'ADMIN-RELEASE_NOTES-VIEW', 'ADMIN-RELEASE_NOTES-PRINTVIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/admin/releasenotes']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-clipboard mr-2"></i> Release Notes</a
                                 >
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>

                     <mdb-accordion-item
                        li
                        *appHasRole="[
                           'SYSTEM-ADMIN',
                           'SETUP-GENERAL-BUSINESS_AREA-VIEW',
                           'SETUP-GENERAL-COST_CENTRE-VIEW',
                           'SETUP-GENERAL-DIMENSIONS-VIEW',
                           'SETUP-GENERAL-SUPPLIER-VIEW',
                           'SETUP-LOCATION-SITE-VIEW',
                           'SETUP-LOCATION-BUILDING-VIEW',
                           'SETUP-LOCATION-ROOM-VIEW'
                        ]"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Setup</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <li
                                 class="SecondLevelMenu"
                                 *appHasRole="[
                                    'SYSTEM-ADMIN',
                                    'SETUP-GENERAL-BUSINESS_AREA-VIEW',
                                    'SETUP-GENERAL-COST_CENTRE-VIEW',
                                    'SETUP-GENERAL-DIMENSIONS-VIEW',
                                    'SETUP-GENERAL-SUPPLIER-VIEW'
                                 ]"
                              >
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect
                                       ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>General Setup</mdb-accordion-item-head
                                    >
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'SETUP-GENERAL-BUSINESS_AREA-VIEW']">
                                             <a routerLinkActive="active" [routerLink]="['/setup/businessarea']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-clone mr-2"></i> Business Area</a
                                             >
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'SETUP-GENERAL-COST_CENTRE-VIEW']">
                                             <a routerLinkActive="active" [routerLink]="['/setup/costcentre']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-coins mr-2"></i> Cost Centre</a
                                             >
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'SETUP-GENERAL-DIMENSIONS-VIEW']">
                                             <a routerLinkActive="active" [routerLink]="['/setup/dimension']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-cubes mr-2"></i> Dimensions</a
                                             >
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'SETUP-GENERAL-SUPPLIER-VIEW']">
                                             <a routerLinkActive="active" [routerLink]="['/setup/supplier']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-hand-holding-usd mr-2"></i> Supplier</a
                                             >
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>
                              <li class="SecondLevelMenu" *appHasRole="['SYSTEM-ADMIN', 'SETUP-LOCATION-SITE-VIEW', 'SETUP-LOCATION-BUILDING-VIEW', 'SETUP-LOCATION-ROOM-VIEW']">
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect
                                       ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Location Setup</mdb-accordion-item-head
                                    >
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'SETUP-LOCATION-SITE-VIEW']">
                                             <a [routerLink]="['/setup/site']" class="waves-effect" mdbWavesEffect><i class="fa fa-flag mr-2"></i> Sites</a>
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'SETUP-LOCATION-BUILDING-VIEW']">
                                             <a [routerLink]="['/setup/building']" class="waves-effect" mdbWavesEffect><i class="fa fa-building mr-2"></i> Buildings</a>
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'SETUP-LOCATION-ROOM-VIEW']">
                                             <a [routerLink]="['/setup/room']" class="waves-effect" mdbWavesEffect><i class="fa fa-home mr-2"></i> Rooms</a>
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>

                     <mdb-accordion-item
                        li
                        *appHasRole="[
                           'SYSTEM-ADMIN',
                           'CLASSIFICATION_SETUP-CLASSIFICATIONS-VIEW',
                           'CLASSIFICATION_SETUP-ACCOUNTS-VIEW',
                           'CLASSIFICATION_SETUP-ASSET_GROUP-VIEW',
                           'CLASSIFICATION_SETUP-ASSET_TYPE-VIEW',
                           'CLASSIFICATION_SETUP-COMPONENT_TYPE-VIEW',
                           'CLASSIFICATION_SETUP-DESCRIPTOR_TYPE-VIEW',
                           'CLASSIFICATION_SETUP-ATTRIBUTES-VIEW',
                           'CLASSIFICATION_SETUP-GL_ACCOUNTS-VIEW'
                        ]"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Classification Setup</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <li *appHasRole="['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-CLASSIFICATIONS-VIEW', 'CLASSIFICATION_SETUP-ACCOUNTS-VIEW']">
                                 <a [routerLink]="['/setupclass/classification']" class="waves-effect" mdbWavesEffect
                                    ><i class="k-icon k-i-thumbnails-down mr-2"></i> Classification</a
                                 >
                              </li>

                              <li *appHasRole="['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-ASSET_GROUP-VIEW']">
                                 <a [routerLink]="['/setupclass/assetgroup']" class="waves-effect" mdbWavesEffect><i class="fa fa-cogs mr-2"></i> Asset Group</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-ASSET_TYPE-VIEW']">
                                 <a [routerLink]="['/setupclass/assettype']" class="waves-effect" mdbWavesEffect><i class="fa fa-tools mr-2"></i> Asset Type</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-COMPONENT_TYPE-VIEW']">
                                 <a [routerLink]="['/setupclass/componenttype']" class="waves-effect" mdbWavesEffect><i class="k-icon k-i-wrench mr-2"></i> Component Type</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-DESCRIPTOR_TYPE-VIEW']">
                                 <a [routerLink]="['/setupclass/descriptortype']" class="waves-effect" mdbWavesEffect><i class="fa fa-atom mr-2"></i> Descriptor Type</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-ATTRIBUTES-VIEW']">
                                 <a [routerLink]="['/setupclass/attribute']" class="waves-effect" mdbWavesEffect><i class="k-icon k-i-page-properties mr-2"></i> Attributes</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-GL_ACCOUNTS-VIEW']">
                                 <a [routerLink]="['/setupclass/account']" class="waves-effect" mdbWavesEffect><i class="k-icon k-i-file-ascx mr-2"></i> GL Accounts</a>
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>

                     <mdb-accordion-item
                        *appHasRole="[
                           'SYSTEM-ADMIN',
                           'ASSET_MNGT-REGISTER-FAR_EXPORT',
                           'ASSET_MNGT-REGISTER-JOURNAL_INTEGRATION-VIEW',
                           'ASSET_MNGT-REGISTER-CORRECTION_JOURNAL-VIEW',
                           'ASSET_MNGT-REGISTER-3RD_PARTY_EXPORT',
                           'ASSET_MNGT-DIMENSION_CHANGES-VIEW',
                           'ASSET_MNGT-COST_CENTRE_CHANGES-VIEW',
                           'ASSET_MNGT-CLASSIFICATION_CHANGE-VIEW',
                           'ASSET_MNGT-BARCODE_REPLACEMENT-VIEW',
                           'ASSET_MNGT-TRANSACTION_HISTORY_REPORT-VIEW'
                        ]"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Asset Management</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <li *appHasRole="['SYSTEM-ADMIN', 'ASSET_MNGT-REGISTER-FAR_EXPORT', 'ASSET_MNGT-REGISTER-JOURNAL_INTEGRATION-VIEW']">
                                 <a [routerLink]="['/assetmanagement/asset/register']" class="waves-effect" mdbWavesEffect><i class="fa fa-chart-line mr-2"></i> Asset Register</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'ASSET_MNGT-REGISTER-JOURNAL_INTEGRATION-VIEW', 'ASSET_MNGT-REGISTER-CORRECTION_JOURNAL-VIEW']">
                                 <a [routerLink]="['/assetmanagement/asset/correctionjournalintegration']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-check-circle mr-2"></i> Correction Journals</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'ASSET_MNGT-REGISTER-3RD_PARTY_EXPORT']">
                                 <a [routerLink]="['/assetmanagement/asset/exportthirdparty']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-cogs mr-2"></i> 3rd Party Exports</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'ASSET_MNGT-DIMENSION_CHANGES-VIEW']">
                                 <a [routerLink]="['/assetmanagement/asset/dimensionchange']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-layer-group mr-2"></i> Dimension Change</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'ASSET_MNGT-COST_CENTRE_CHANGES-VIEW']">
                                 <a [routerLink]="['/assetmanagement/asset/costcentrechange']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-coins mr-2"></i> Cost Centre Change</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'ASSET_MNGT-CLASSIFICATION_CHANGE-VIEW']">
                                 <a [routerLink]="['/assetmanagement/asset/classificationchange']" class="waves-effect" mdbWavesEffect
                                    ><i class="k-icon k-i-wrench mr-2"></i> Classification Change</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'ASSET_MNGT-BARCODE_REPLACEMENT-VIEW']">
                                 <a [routerLink]="['/assetmanagement/asset/barcodereplacement']" class="waves-effect" mdbWavesEffect
                                    ><i class="fas fa-barcode mr-2"></i> Barcode Replacement</a
                                 >
                              </li>

                              <li *appHasRole="['SYSTEM-ADMIN', 'ASSET_MNGT-TRANSACTION_HISTORY_REPORT-VIEW']">
                                 <a [routerLink]="['assetmanagement/asset/transactionhistory']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-history mr-2"></i> Transaction History</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'ASSET_MNGT-GROUPS-GRP-CANCEL']">
                                 <a [routerLink]="['assetmanagement/asset/removebulk']" class="waves-effect" mdbWavesEffect><i class="fa fa-trash mr-2"></i> Remove Bulk Assets</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'ASSET_MNGT-GROUPS-GRP-VIEW']">
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Groups</mdb-accordion-item-head>
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li>
                                             <a [routerLink]="['/assetmanagement/asset/general', 'GENERAL']" class="waves-effect" mdbWavesEffect
                                                ><i class="k-icon k-i-toggle-full-screen-mode mr-2"></i> General</a
                                             >
                                          </li>
                                          <li>
                                             <a [routerLink]="['/assetmanagement/asset/vehicle', 'VEHICLE']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-truck mr-2"></i> Vehicle</a
                                             >
                                          </li>
                                          <li>
                                             <a [routerLink]="['/assetmanagement/asset/road', 'ROAD']" class="waves-effect" mdbWavesEffect><i class="fa fa-road mr-2"></i> Road</a>
                                          </li>
                                          <li>
                                             <a [routerLink]="['/assetmanagement/asset/land', 'LAND']" class="waves-effect" mdbWavesEffect><i class="fa fa-tree mr-2"></i> Land</a>
                                          </li>
                                          <li>
                                             <a [routerLink]="['/assetmanagement/asset/building', 'BUILDING']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-building mr-2"></i> Building</a
                                             >
                                          </li>
                                          <li>
                                             <a [routerLink]="['/assetmanagement/asset/water', 'WATER']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-bath mr-2"></i> Water</a
                                             >
                                          </li>
                                          <li>
                                             <a [routerLink]="['/assetmanagement/asset/stormwater', 'STORMWATER']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-water mr-2"></i> Storm Water</a
                                             >
                                          </li>
                                          <li>
                                             <a [routerLink]="['/assetmanagement/asset/sanitation', 'SANITATION']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-toilet-paper mr-2"></i> Sanitation</a
                                             >
                                          </li>
                                          <li>
                                             <a [routerLink]="['/assetmanagement/asset/electricity', 'ELECTRICITY']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-lightbulb mr-2"></i> Electricity</a
                                             >
                                          </li>
                                          <li>
                                             <a [routerLink]="['/assetmanagement/asset/railway', 'RAILWAY']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-train mr-2"></i> Railway</a
                                             >
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>

                     <mdb-accordion-item
                        *appHasRole="[
                           'SYSTEM-ADMIN',
                           'TRANSACTIONS-MONTHEND_YEAREND-RUN_MONTH_END-RUN',
                           'TRANSACTIONS-MONTHEND_YEAREND-RUN_MONTH_END-APPROVE',
                           'TRANSACTIONS-MONTHEND_YEAREND-RUN_MONTH_END-ROLLBACK',
                           'TRANSACTIONS-MONTH_END_REPORT-VIEW',
                           'TRANSACTIONS-MONTH_END_REPORT-PRINTVIEW',
                           'TRANSACTIONS-RECONCILLIATIONS-VIEW',
                           'TRANSACTIONS-RECONCILLIATIONS-PRINTVIEW',
                           'TRANSACTIONS-DEPRECIATION-RUN_MONTHLY_DEP-RUN',
                           'TRANSACTIONS-DEPRECIATION-PROD_OF_UNIT-VIEW',
                           'TRANSACTIONS-DEPRECIATION-FORECAST-EXPORT',
                           'TRANSACTIONS-TRANSACTIONS-ACQUISITIONS-VIEW',
                           'TRANSACTIONS-TRANSACTIONS-DISPOSALS-VIEW',
                           'TRANSACTIONS-TRANSACTIONS-RUL_CHANGE-VIEW',
                           'TRANSACTIONS-TRANSACTIONS-RESIDUAL_CHANGE-VIEW',
                           'TRANSACTIONS-TRANSACTIONS-UPGRADES-VIEW',
                           'TRANSACTIONS-TRANSACTIONS-IMPAIRMENTS-VIEW',
                           'TRANSACTIONS-TRANSACTIONS-REVALUATION-VIEW',
                           'TRANSACTIONS-TRANSACTIONS-ADJUSTMENTS-VIEW',
                           'TRANSACTIONS-TRANSACTIONS-JOURNAL_ADJUSTMENTS-VIEW',
                           'TRANSACTIONS-REPORTS-VIEW',
                           'TRANSACTIONS-REPORTS-PRINTVIEW'
                        ]"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Transactions</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <li
                                 *appHasRole="[
                                    'SYSTEM-ADMIN',
                                    'TRANSACTIONS-MONTHEND_YEAREND-RUN_MONTH_END-RUN',
                                    'TRANSACTIONS-MONTHEND_YEAREND-RUN_MONTH_END-APPROVE',
                                    'TRANSACTIONS-MONTHEND_YEAREND-RUN_MONTH_END-ROLLBACK'
                                 ]"
                              >
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect
                                       ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Month/Year End</mdb-accordion-item-head
                                    >
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'TRANSACTIONS-MONTHEND_YEAREND-RUN_MONTH_END-RUN',
                                                'TRANSACTIONS-MONTHEND_YEAREND-RUN_MONTH_END-APPROVE',
                                                'TRANSACTIONS-MONTHEND_YEAREND-RUN_MONTH_END-ROLLBACK',
                                                'TRANSACTIONS-MONTH_END_REPORT-VIEW',
                                                'TRANSACTIONS-MONTH_END_REPORT-PRINTVIEW'
                                             ]"
                                          >
                                             <a [routerLink]="['/financialTransaction/monthend']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-running mr-2"></i> Month End</a
                                             >
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'TRANSACTIONS-RECONCILLIATIONS-VIEW', 'TRANSACTIONS-RECONCILLIATIONS-PRINTVIEW']">
                                             <a [routerLink]="['/financialTransaction/reconciliation']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-balance-scale mr-2"></i> Reconciliations</a
                                             >
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>

                              <li
                                 *appHasRole="[
                                    'SYSTEM-ADMIN',
                                    'TRANSACTIONS-DEPRECIATION-RUN_MONTHLY_DEP-RUN',
                                    'TRANSACTIONS-DEPRECIATION-PROD_OF_UNIT-VIEW',
                                    'TRANSACTIONS-DEPRECIATION-FORECAST-EXPORT'
                                 ]"
                              >
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect
                                       ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Depreciation</mdb-accordion-item-head
                                    >
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'TRANSACTIONS-DEPRECIATION-RUN_MONTHLY_DEP-RUN']">
                                             <a [routerLink]="['/financialTransaction/depreciation']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-running mr-2"></i> Run Monthly</a
                                             >
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'TRANSACTIONS-DEPRECIATION-PROD_OF_UNIT-VIEW']">
                                             <a [routerLink]="['/financialTransaction/depreciation/productionofunit']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-truck-moving mr-2"></i> Production Of Unit</a
                                             >
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'TRANSACTIONS-DEPRECIATION-FORECAST-EXPORT']">
                                             <a [routerLink]="['/financialTransaction/depreciationforecast']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-chart-line mr-2"></i> Forecast</a
                                             >
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>

                              <!-- <li *appHasRole="['SYSTEM-ADMIN', 'BUDGET-VIEW', 'BUDGET-EDIT']">
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Budgeting</mdb-accordion-item-head>
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li>
                                             <a [routerLink]="['/financialTransaction/budget']" class="waves-effect" mdbWavesEffect><i class="fa fa-running mr-2"></i> Budget</a>
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li> -->

                              <li *appHasRole="['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-ACQUISITIONS-VIEW']">
                                 <a [routerLink]="['/financialTransaction/acquisition']" class="waves-effect" mdbWavesEffect><i class="fa fa-credit-card mr-2"></i> Acquisitions</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-DISPOSALS-VIEW']">
                                 <a [routerLink]="['/financialTransaction/disposal']" class="waves-effect" mdbWavesEffect><i class="fa fa-recycle mr-2"></i> Disposals</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-RUL_CHANGE-VIEW']">
                                 <a [routerLink]="['/financialTransaction/rulchange']" class="waves-effect" mdbWavesEffect><i class="fa fa-hourglass mr-2"></i> Rul Changes</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-RESIDUAL_CHANGE-VIEW']">
                                 <a [routerLink]="['/financialTransaction/residualchange']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-coins mr-2"></i> Residual Changes</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-UPGRADES-VIEW']">
                                 <a [routerLink]="['/financialTransaction/upgrade']" class="waves-effect" mdbWavesEffect><i class="fa fa-redo mr-2"></i> Upgrades</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-IMPAIRMENTS-VIEW']">
                                 <a [routerLink]="['/financialTransaction/impairment']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-hand-point-down mr-2"></i> Impairments</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-REVALUATION-VIEW']">
                                 <a [routerLink]="['/financialTransaction/revaluation']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-money-bill-wave mr-2"></i> Revaluations</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-ADJUSTMENTS-VIEW']">
                                 <a [routerLink]="['/financialTransaction/adjustment']" class="waves-effect" mdbWavesEffect><i class="fa fa-adjust mr-2"></i> Adjustments</a>
                              </li>

                              <li *appHasRole="['SYSTEM-ADMIN', 'TRANSACTIONS-REPORTS-VIEW', 'TRANSACTIONS-REPORTS-PRINTVIEW']">
                                 <a [routerLink]="['/financialTransaction/reportdashboard']" class="waves-effect" mdbWavesEffect><i class="fa fa-print mr-2"></i> Reports</a>
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>

                     <mdb-accordion-item
                        li
                        *appHasRole="[
                           'SYSTEM-ADMIN',
                           'CLASSIFICATION_SETUP-WAT-WAT_TYPE-VIEW',
                           'CLASSIFICATION_SETUP-WAT-WAT_VERSION-VIEW',
                           'CLASSIFICATION_SETUP-WAT-WAT_REGISTER-VIEW',
                           'CLASSIFICATION_SETUP-WAT-WAT_REGISTER-PRINTVIEW'
                        ]"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i>Wear and Tear</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <li *appHasRole="['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-WAT-WAT_TYPE-VIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/setupclass/wat-type']" class="waves-effect" mdbWavesEffect><i class="fa fa-clone mr-2"></i> Type</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-WAT-WAT_VERSION-VIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/setupclass/wat-version']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-coins mr-2"></i> Version</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-WAT-WAT_REGISTER-VIEW', 'CLASSIFICATION_SETUP-WAT-WAT_REGISTER-PRINTVIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/setupclass/wat-register']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-chart-line mr-2"></i> WAT Register</a
                                 >
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>
                     <mdb-accordion-item
                        *appHasRole="[
                           'SYSTEM-ADMIN',
                           'WIP-SETUP-ENGINEERS-VIEW',
                           'WIP-SETUP-CONTRACTORS-VIEW',
                           'WIP-SETUP-FUND-VIEW',
                           'WIP-CHECK_LIST-QUESTIONS-VIEW',
                           'WIP-CHECK_LIST-TEMPLATE-VIEW',
                           'WIP-WIP-PROJECT-VIEW',
                           'WIP-WIP-TRANSACTIONS-VIEW',
                           'WIP-WIP-ALLOCATION_ADJUSTMENTS-VIEW',
                           'WIP-MASTER_ASSETS-COMPONENTS-VIEW',
                           'WIP-WIP-REPORTS-VIEW',
                           'WIP-WIP-REPORTS-PRINTVIEW'
                        ]"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Work in Progress</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <li class="SecondLevelMenu">
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Setup</mdb-accordion-item-head>
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'WIP-SETUP-ENGINEERS-VIEW']">
                                             <a routerLinkActive="active" [routerLink]="['/wip/engineer']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-pencil-ruler mr-2"></i> Engineers</a
                                             >
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'WIP-SETUP-CONTRACTORS-VIEW']">
                                             <a routerLinkActive="active" [routerLink]="['/wip/contractor']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-hard-hat mr-2"></i> Contractors</a
                                             >
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'WIP-SETUP-FUND-VIEW']">
                                             <a routerLinkActive="active" [routerLink]="['/wip/fund']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-university mr-2"></i> Fund</a
                                             >
                                          </li>

                                          <li>
                                             <mdb-accordion-item>
                                                <mdb-accordion-item-head mdbWavesEffect
                                                   ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Checklist Setup</mdb-accordion-item-head
                                                >
                                                <mdb-accordion-item-body>
                                                   <ul>
                                                      <li *appHasRole="['SYSTEM-ADMIN', 'WIP-CHECK_LIST-QUESTIONS-VIEW']">
                                                         <a routerLinkActive="active" [routerLink]="['/wip/question']" class="waves-effect" mdbWavesEffect
                                                            ><i class="fa fa-question-circle mr-2"></i> Questions</a
                                                         >
                                                      </li>
                                                      <li *appHasRole="['SYSTEM-ADMIN', 'WIP-CHECK_LIST-TEMPLATE-VIEW']">
                                                         <a routerLinkActive="active" [routerLink]="['/wip/checklisttemplate']" class="waves-effect" mdbWavesEffect
                                                            ><i class="fa fa-clipboard-list mr-2"></i> Checklist Template</a
                                                         >
                                                      </li>
                                                   </ul>
                                                </mdb-accordion-item-body>
                                             </mdb-accordion-item>
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>

                              <li *appHasRole="['SYSTEM-ADMIN', 'WIP-WIP-PROJECT-VIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/wip/project']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-project-diagram mr-2"></i> Project</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'WIP-WIP-CERTIFICATES-VIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/wip/certificate']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-certificate mr-2"></i> Certificate</a
                                 >
                              </li>

                              <li *appHasRole="['SYSTEM-ADMIN', 'WIP-WIP-TRANSACTIONS-VIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/wip/transaction']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-money-bill mr-2"></i> Transaction</a
                                 >
                              </li>

                              <li *appHasRole="['SYSTEM-ADMIN', 'WIP-WIP-ALLOCATION_ADJUSTMENTS-VIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/wip/allocationAdjustment']" class="waves-effect" mdbWavesEffect
                                    ><i class="fas fa-money-bill-alt mr-2"></i> Allocation Adjustment</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'WIP-MASTER_ASSETS-COMPONENTS-VIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/wip/masterAsset']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-truck-monster mr-2"></i> Master Asset</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'WIP-WIP-REPORTS-VIEW', 'WIP-WIP-REPORTS-PRINTVIEW']">
                                 <a [routerLink]="['/wip/reportdashboard1']" class="waves-effect" mdbWavesEffect><i class="fa fa-print mr-2"></i> Reports</a>
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>

                     <!--Lease Management -->
                     <!------------------>
                     <mdb-accordion-item *appHasRole="['SYSTEM-ADMIN']">
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Lease Management</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <li *appHasRole="['SYSTEM-ADMIN', 'LEASE-MANAGER']" title="Lease">
                                 <a [routerLink]="['/leaseManagement/leaseAgreement']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fas fa-balance-scale mr-2"></i>Lease Agreements</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'LEASE-MANAGER']" title="Lease Agreement Journals">
                                 <a routerLinkActive="active" [routerLink]="['/leaseManagement/leaseAgreementJournal']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-running mr-2"></i> Lease Agreement Journals</a
                                 >
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>
                     <mdb-accordion-item
                        *appHasRole="['SYSTEM-ADMIN', 'SCANNED_ASSETS-SCAN_PROJECT-VIEW', 'SCANNED_ASSETS-CAS-VIEW', 'SCANNED_ASSETS-ITEMS-VIEW', 'SCANNED_ASSETS-DUMP-VIEW']"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Scanned Assets</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <li *appHasRole="['SYSTEM-ADMIN', 'SCANNED_ASSETS-SCAN_PROJECT-VIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/scan/scanproject']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-project-diagram mr-2"></i> Scan Project</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'SCANNED_ASSETS-CAS-VIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/conditionAssesment/condition']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-clipboard-check mr-2"></i> Condition Assessment Setup</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'SCANNED_ASSETS-ITEMS-VIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/scan/inventorylist']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-tasks mr-2"></i> Scanned Items</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'SCANNED_ASSETS-DUMP-VIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/scan/dumpinventorylist']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-layer-group mr-2"></i> Scanned Items Dump</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'SCANNED_ASSETS-REPORTS-VIEW', 'SCANNED_ASSETS-REPORTS-PRINTVIEW']">
                                 <a [routerLink]="['/scan/reportdashboard']" class="waves-effect" mdbWavesEffect><i class="fa fa-print mr-2"></i> Reports</a>
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>

                     <mdb-accordion-item
                        *appHasRole="[
                           'SYSTEM-ADMIN',
                           'RFID-RFID-ZONES-VIEW',
                           'RFID-RFID-READERS-VIEW',
                           'RFID-READ_LOG-VIEW',
                           'RFID-READ_LOG-PRINTVIEW',
                           'RFID-RFID-MOVEMENTS-VIEW',
                           'RFID-RFID-SECURITY_MOVEMENTS-VIEW',
                           'RFID-ERROR_LOGS-VIEW',
                           'RFID-ERROR_LOGS-PRINTVIEW'
                        ]"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> RFID Tracking</mdb-accordion-item-head>
                        <mdb-accordion-item-body *appHasRole="['SYSTEM-ADMIN', 'RFID-VIEW', 'RFID-EDIT']">
                           <ul>
                              <li *appHasRole="['SYSTEM-ADMIN', 'RFID-RFID-ZONES-VIEW']">
                                 <a *appHasRole="['SYSTEM-ADMIN', 'RFID-VIEW', 'RFID-EDIT']" [routerLink]="['/rfid/zone']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-tasks mr-2"></i> Zones</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'RFID-RFID-READERS-VIEW']">
                                 <a *appHasRole="['SYSTEM-ADMIN', 'RFID-VIEW', 'RFID-EDIT']" [routerLink]="['/rfid/reader']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-clone mr-2"></i> Readers</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'RFID-READ_LOG-VIEW', 'RFID-READ_LOG-PRINTVIEW']">
                                 <a *appHasRole="['SYSTEM-ADMIN', 'RFID-VIEW', 'RFID-EDIT']" [routerLink]="['/rfid/readlog']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-folder mr-2"></i> Read Log</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'RFID-RFID-MOVEMENTS-VIEW']">
                                 <a *appHasRole="['SYSTEM-ADMIN', 'RFID-VIEW', 'RFID-EDIT']" [routerLink]="['/rfid/movement', 'false']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-bars mr-2"></i> Movements</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'RFID-RFID-SECURITY_MOVEMENTS-VIEW']">
                                 <a *appHasRole="['SYSTEM-ADMIN', 'RFID-VIEW', 'RFID-EDIT']" [routerLink]="['/rfid/securitymovement', 'true']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-home mr-2"></i> Security Movements</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'RFID-ERROR_LOGS-VIEW', 'RFID-ERROR_LOGS-PRINTVIEW']">
                                 <a *appHasRole="['SYSTEM-ADMIN', 'RFID-VIEW', 'RFID-EDIT']" [routerLink]="['/rfid/errorlog']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-exclamation-circle mr-2"></i> Error Log</a
                                 >
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>

                     <mdb-accordion-item
                        *appHasRole="[
                           'SYSTEM-ADMIN',
                           'GEO_INFO-GEO_INFO-REGIONS-VIEW',
                           'GEO_INFO-GEO_INFO-LAYERS-VIEW',
                           'GEO_INFO-GEO_INFO-LAYERS_PER_TYPE-VIEW',
                           'GEO_INFO-GEO_INFO-GEO_ASSETS-VIEW'
                        ]"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Geographical Info</mdb-accordion-item-head>
                        <mdb-accordion-item-body *appHasRole="['SYSTEM-ADMIN', 'GIS-VIEW', 'GIS-EDIT']">
                           <ul>
                              <li *appHasRole="['SYSTEM-ADMIN', 'GEO_INFO-GEO_INFO-REGIONS-VIEW']">
                                 <a *appHasRole="['SYSTEM-ADMIN', 'GIS-VIEW', 'GIS-EDIT']" [routerLink]="['/gis/region']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-map-pin mr-2"></i> Regions</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'GEO_INFO-GEO_INFO-LAYERS-VIEW']">
                                 <a *appHasRole="['SYSTEM-ADMIN', 'GIS-VIEW', 'GIS-EDIT']" [routerLink]="['/gis/layer']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-layer-group mr-2"></i> Layers</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'GEO_INFO-GEO_INFO-LAYERS_PER_TYPE-VIEW']">
                                 <a *appHasRole="['SYSTEM-ADMIN', 'GIS-VIEW', 'GIS-EDIT']" [routerLink]="['/gis/layertype']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-layer-group mr-2"></i> Layers Per Type</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'GEO_INFO-GEO_INFO-GEO_ASSETS-VIEW']">
                                 <a *appHasRole="['SYSTEM-ADMIN', 'GIS-VIEW', 'GIS-EDIT']" [routerLink]="['/gis/map']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-map-marked-alt mr-2"></i> Geo Assets</a
                                 >
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>

                     <mdb-accordion-item
                        li
                        *appHasRole="[
                           'SYSTEM-ADMIN',
                           'MONITORING-MONITORING-DASHBOARD-VIEW',
                           'MONITORING-ACTIONS-LOST_STOLEN_ASSETS-VIEW',
                           'MONITORING-ASSET_MOVEMENTS-NEW_MOVEMENTS-VIEW',
                           'MONITORING-ASSET_MOVEMENTS-ARCHIVE-VIEW'
                        ]"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Monitoring</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <li *appHasRole="['SYSTEM-ADMIN', 'MONITORING-MONITORING-DASHBOARD-VIEW']">
                                 <a routerLinkActive="active" [routerLink]="['monitoring/dashboard']" class="waves-effect" mdbWavesEffect>
                                    <i class="fa fa-tachometer-alt mr-2"></i> Dashboard
                                 </a>
                              </li>
                              <li
                                 *appHasRole="['SYSTEM-ADMIN', 'MONITORING-ACTIONS-LOST_STOLEN_ASSETS-VIEW', 'MONITORING-ASSET_MOVEMENTS-NEW_MOVEMENTS-VIEW']"
                                 class="SecondLevelMenu"
                              >
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect
                                       ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Monitoring Actions</mdb-accordion-item-head
                                    >
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'MONITORING-ACTIONS-LOST_STOLEN_ASSETS-VIEW']">
                                             <a routerLinkActive="active" [routerLink]="['monitoring/list/loss']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-trash-alt mr-2"></i> Lost/Stolen Assets</a
                                             >
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>
                              <li
                                 *appHasRole="['SYSTEM-ADMIN', 'MONITORING-ASSET_MOVEMENTS-NEW_MOVEMENTS-VIEW', 'MONITORING-ASSET_MOVEMENTS-ARCHIVE-VIEW']"
                                 class="SecondLevelMenu"
                              >
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect
                                       ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Asset Movements</mdb-accordion-item-head
                                    >
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'MONITORING-ASSET_MOVEMENTS-NEW_MOVEMENTS-VIEW']">
                                             <a routerLinkActive="active" [routerLink]="['monitoring/assetmovement']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-project-diagram mr-2"></i> New Movements</a
                                             >
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'MONITORING-ASSET_MOVEMENTS-ARCHIVE-VIEW']">
                                             <a routerLinkActive="active" [routerLink]="['monitoring/assetmovementarc']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-box-open mr-2"></i> Archive</a
                                             >
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>

                     <mdb-accordion-item
                        *appHasRole="[
                           'SYSTEM-ADMIN',
                           'MAINTENANCE-MANAGER',
                           'MTC-SETUP-LABOUR_RESOURCE_TYPE-CREATE',
                           'MTC-SETUP-LABOUR_RESOURCE_TYPE-APPROVE',
                           'MTC-SETUP-LABOUR_RESOURCE_TYPE-EDIT',
                           'MTC-SETUP-LABOUR_RESOURCE_TYPE-DELETE',
                           'MTC-SETUP-LABOUR_RESOURCE_TYPE-VIEW',
                           'MTC-SETUP-LABOUR_RESOURCE-CREATE',
                           'MTC-SETUP-LABOUR_RESOURCE-APPROVE',
                           'MTC-SETUP-LABOUR_RESOURCE-EDIT',
                           'MTC-SETUP-LABOUR_RESOURCE-DELETE',
                           'MTC-SETUP-LABOUR_RESOURCE-VIEW',
                           'MTC-SETUP-ADJUST_LABOUR_RATES-CREATE',
                           'MTC-SETUP-ADJUST_LABOUR_RATES-APPROVE',
                           'MTC-SETUP-ADJUST_LABOUR_RATES-EDIT',
                           'MTC-SETUP-ADJUST_LABOUR_RATES-DELETE',
                           'MTC-SETUP-ADJUST_LABOUR_RATES-VIEW',
                           'MTC-SETUP-ASSET_RESOURCE_TYPE-CREATE',
                           'MTC-SETUP-ASSET_RESOURCE_TYPE-APPROVE',
                           'MTC-SETUP-ASSET_RESOURCE_TYPE-EDIT',
                           'MTC-SETUP-ASSET_RESOURCE_TYPE-DELETE',
                           'MTC-SETUP-ASSET_RESOURCE_TYPE-VIEW',
                           'MTC-SETUP-ASSET_RESOURCES-CREATE',
                           'MTC-SETUP-ASSET_RESOURCES-APPROVE',
                           'MTC-SETUP-ASSET_RESOURCES-EDIT',
                           'MTC-SETUP-ASSET_RESOURCES-DELETE',
                           'MTC-SETUP-ASSET_RESOURCES-VIEW',
                           'MTC-SETUP-ADJUST_ASSET_RATES-CREATE',
                           'MTC-SETUP-ADJUST_ASSET_RATES-APPROVE',
                           'MTC-SETUP-ADJUST_ASSET_RATES-EDIT',
                           'MTC-SETUP-ADJUST_ASSET_RATES-DELETE',
                           'MTC-SETUP-ADJUST_ASSET_RATES-VIEW',
                           'MTC-SETUP-TOOL_RESOURCE_TYPE-CREATE',
                           'MTC-SETUP-TOOL_RESOURCE_TYPE-APPROVE',
                           'MTC-SETUP-TOOL_RESOURCE_TYPE-EDIT',
                           'MTC-SETUP-TOOL_RESOURCE_TYPE-DELETE',
                           'MTC-SETUP-TOOL_RESOURCE_TYPE-VIEW',
                           'MTC-SETUP-TOOL_RESOURCES-CREATE',
                           'MTC-SETUP-TOOL_RESOURCES-APPROVE',
                           'MTC-SETUP-TOOL_RESOURCES-EDIT',
                           'MTC-SETUP-TOOL_RESOURCES-DELETE',
                           'MTC-SETUP-TOOL_RESOURCES-VIEW',
                           'MTC-SETUP-ADJUST_TOOL_RATES-CREATE',
                           'MTC-SETUP-ADJUST_TOOL_RATES-APPROVE',
                           'MTC-SETUP-ADJUST_TOOL_RATES-EDIT',
                           'MTC-SETUP-ADJUST_TOOL_RATES-DELETE',
                           'MTC-SETUP-ADJUST_TOOL_RATES-VIEW',
                           'MTC-SETUP-ACTIVITY_TYPE-CREATE',
                           'MTC-SETUP-ACTIVITY_TYPE-APPROVE',
                           'MTC-SETUP-ACTIVITY_TYPE-EDIT',
                           'MTC-SETUP-ACTIVITY_TYPE-DELETE',
                           'MTC-SETUP-ACTIVITY_TYPE-VIEW',
                           'MTC-SETUP-STRATEGY-VIEW',
                           'MTC-SETUP-TENANTS-CREATE',
                           'MTC-SETUP-TENANTS-APPROVE',
                           'MTC-SETUP-TENANTS-EDIT',
                           'MTC-SETUP-TENANTS-DELETE',
                           'MTC-SETUP-TENANTS-VIEW',
                           'MTC-SETUP-TEAMS-CREATE',
                           'MTC-SETUP-TEAMS-APPROVE',
                           'MTC-SETUP-TEAMS-EDIT',
                           'MTC-SETUP-TEAMS-DELETE',
                           'MTC-SETUP-TEAMS-VIEW',
                           'MTC-INVENTORY_SETUP-WAREHOUSES-CREATE',
                           'MTC-INVENTORY_SETUP-WAREHOUSES-APPROVE',
                           'MTC-INVENTORY_SETUP-WAREHOUSES-EDIT',
                           'MTC-INVENTORY_SETUP-WAREHOUSES-DELETE',
                           'MTC-INVENTORY_SETUP-WAREHOUSES-VIEW',
                           'MTC-INVENTORY_SETUP-STOREROOMS-CREATE',
                           'MTC-INVENTORY_SETUP-STOREROOMS-APPROVE',
                           'MTC-INVENTORY_SETUP-STOREROOMS-EDIT',
                           'MTC-INVENTORY_SETUP-STOREROOMS-DELETE',
                           'MTC-INVENTORY_SETUP-STOREROOMS-VIEW',
                           'MTC-INVENTORY_SETUP-ISLES-CREATE',
                           'MTC-INVENTORY_SETUP-ISLES-APPROVE',
                           'MTC-INVENTORY_SETUP-ISLES-EDIT',
                           'MTC-INVENTORY_SETUP-ISLES-DELETE',
                           'MTC-INVENTORY_SETUP-ISLES-VIEW',
                           'MTC-INVENTORY_SETUP-ROWS-CREATE',
                           'MTC-INVENTORY_SETUP-ROWS-APPROVE',
                           'MTC-INVENTORY_SETUP-ROWS-EDIT',
                           'MTC-INVENTORY_SETUP-ROWS-DELETE',
                           'MTC-INVENTORY_SETUP-ROWS-VIEW',
                           'MTC-INVENTORY_SETUP-BINS-CREATE',
                           'MTC-INVENTORY_SETUP-BINS-APPROVE',
                           'MTC-INVENTORY_SETUP-BINS-EDIT',
                           'MTC-INVENTORY_SETUP-BINS-DELETE',
                           'MTC-INVENTORY_SETUP-BINS-VIEW',
                           'MTC-PARTS-PART_CATEGORY-CREATE',
                           'MTC-PARTS-PART_CATEGORY-APPROVE',
                           'MTC-PARTS-PART_CATEGORY-EDIT',
                           'MTC-PARTS-PART_CATEGORY-DELETE',
                           'MTC-PARTS-PART_CATEGORY-VIEW',
                           'MTC-PARTS-MANAGE_PARTS-CREATE',
                           'MTC-PARTS-MANAGE_PARTS-APPROVE',
                           'MTC-PARTS-MANAGE_PARTS-EDIT',
                           'MTC-PARTS-MANAGE_PARTS-DELETE',
                           'MTC-PARTS-MANAGE_PARTS-VIEW',
                           'MTC-PARTS-MANAGE_PARTS-RECEIVE',
                           'MTC-PARTS-STOCK_REQUISITION-CREATE',
                           'MTC-PARTS-STOCK_REQUISITION-APPROVE',
                           'MTC-PARTS-STOCK_REQUISITION-EDIT',
                           'MTC-PARTS-STOCK_REQUISITION-DELETE',
                           'MTC-PARTS-STOCK_REQUISITION-VIEW',
                           'MTC-PARTS-COLLECT_RETURN-VIEW',
                           'MTC-PARTS-COLLECT_RETURN-COLLECT',
                           'MTC-PARTS-COLLECT_RETURN-RETURN',
                           'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-COMPLETE',
                           'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-EDIT',
                           'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-DELETE',
                           'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-VIEW',
                           'MTC-USAGE_MONITORING-ASSET_TRACKING_READY-ADD_READING',
                           'MTC-USAGE_MONITORING-ASSET_TRACKING_READY-REMOVE',
                           'MTC-USAGE_MONITORING-ASSET_TRACKING_READY-VIEW',
                           'MTC-WORKORDER-REQUESTS-CREATE',
                           'MTC-WORKORDER-REQUESTS-APPROVE',
                           'MTC-WORKORDER-REQUESTS-EDIT',
                           'MTC-WORKORDER-REQUESTS-DELETE',
                           'MTC-WORKORDER-REQUESTS-VIEW',
                           'MTC-WORKORDER-ACTIVITIES-CREATE',
                           'MTC-WORKORDER-ACTIVITIES-APPROVE',
                           'MTC-WORKORDER-ACTIVITIES-EDIT',
                           'MTC-WORKORDER-ACTIVITIES-DELETE',
                           'MTC-WORKORDER-ACTIVITIES-VIEW',
                           'MTC-WORKORDER-TRIGGERED_ASSETS-CREATE',
                           'MTC-WORKORDER-TRIGGERED_ASSETS-VIEW',
                           'MTC-WORKORDER-WORKORDERS-CREATE',
                           'MTC-WORKORDER-WORKORDERS-EDIT',
                           'MTC-WORKORDER-WORKORDERS-VIEW',
                           'MTC-WORKORDER-WORKORDERS-SCHEDULE',
                           'MTC-WORKORDER-SCHEDULE_WORKORDERS-SCHEDULE',
                           'MTC-WORKORDER-SCHEDULE_WORKORDERS-EDIT_SCHEDULE',
                           'MTC-WORKORDER-SCHEDULE_WORKORDERS-VIEW_SCHEDULE',
                           'MTC-WORKORDER-PICK_WORKORDER-VIEW',
                           'MTC-WORKORDER-MY_WORKORDERS-VIEW',
                           'MTC-WORKORDER-JOBCARD_ASSESSMENTS-CREATE',
                           'MTC-WORKORDER-JOBCARD_ASSESSMENTS-APPROVE',
                           'MTC-WORKORDER-JOBCARD_ASSESSMENTS-EDIT',
                           'MTC-WORKORDER-JOBCARD_ASSESSMENTS-DELETE',
                           'MTC-WORKORDER-JOBCARD_ASSESSMENTS-VIEW',
                           'MTC-REPORTS-VIEW',
                           'MTC-REPORTS-PRINTVIEW'
                        ]"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Maintenance</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <li class="SecondLevelMenu">
                                 <mdb-accordion-item
                                    *appHasRole="[
                                       'SYSTEM-ADMIN',
                                       'MAINTENANCE-MANAGER',
                                       'MTC-SETUP-LABOUR_RESOURCE_TYPE-CREATE',
                                       'MTC-SETUP-LABOUR_RESOURCE_TYPE-APPROVE',
                                       'MTC-SETUP-LABOUR_RESOURCE_TYPE-EDIT',
                                       'MTC-SETUP-LABOUR_RESOURCE_TYPE-DELETE',
                                       'MTC-SETUP-LABOUR_RESOURCE_TYPE-VIEW',
                                       'MTC-SETUP-LABOUR_RESOURCE-CREATE',
                                       'MTC-SETUP-LABOUR_RESOURCE-APPROVE',
                                       'MTC-SETUP-LABOUR_RESOURCE-EDIT',
                                       'MTC-SETUP-LABOUR_RESOURCE-DELETE',
                                       'MTC-SETUP-LABOUR_RESOURCE-VIEW',
                                       'MTC-SETUP-ADJUST_LABOUR_RATES-CREATE',
                                       'MTC-SETUP-ADJUST_LABOUR_RATES-APPROVE',
                                       'MTC-SETUP-ADJUST_LABOUR_RATES-EDIT',
                                       'MTC-SETUP-ADJUST_LABOUR_RATES-DELETE',
                                       'MTC-SETUP-ADJUST_LABOUR_RATES-VIEW',
                                       'MTC-SETUP-ASSET_RESOURCE_TYPE-CREATE',
                                       'MTC-SETUP-ASSET_RESOURCE_TYPE-APPROVE',
                                       'MTC-SETUP-ASSET_RESOURCE_TYPE-EDIT',
                                       'MTC-SETUP-ASSET_RESOURCE_TYPE-DELETE',
                                       'MTC-SETUP-ASSET_RESOURCE_TYPE-VIEW',
                                       'MTC-SETUP-ASSET_RESOURCES-CREATE',
                                       'MTC-SETUP-ASSET_RESOURCES-APPROVE',
                                       'MTC-SETUP-ASSET_RESOURCES-EDIT',
                                       'MTC-SETUP-ASSET_RESOURCES-DELETE',
                                       'MTC-SETUP-ASSET_RESOURCES-VIEW',
                                       'MTC-SETUP-ADJUST_ASSET_RATES-CREATE',
                                       'MTC-SETUP-ADJUST_ASSET_RATES-APPROVE',
                                       'MTC-SETUP-ADJUST_ASSET_RATES-EDIT',
                                       'MTC-SETUP-ADJUST_ASSET_RATES-DELETE',
                                       'MTC-SETUP-ADJUST_ASSET_RATES-VIEW',
                                       'MTC-SETUP-TOOL_RESOURCE_TYPE-CREATE',
                                       'MTC-SETUP-TOOL_RESOURCE_TYPE-APPROVE',
                                       'MTC-SETUP-TOOL_RESOURCE_TYPE-EDIT',
                                       'MTC-SETUP-TOOL_RESOURCE_TYPE-DELETE',
                                       'MTC-SETUP-TOOL_RESOURCE_TYPE-VIEW',
                                       'MTC-SETUP-TOOL_RESOURCES-CREATE',
                                       'MTC-SETUP-TOOL_RESOURCES-APPROVE',
                                       'MTC-SETUP-TOOL_RESOURCES-EDIT',
                                       'MTC-SETUP-TOOL_RESOURCES-DELETE',
                                       'MTC-SETUP-TOOL_RESOURCES-VIEW',
                                       'MTC-SETUP-ADJUST_TOOL_RATES-CREATE',
                                       'MTC-SETUP-ADJUST_TOOL_RATES-APPROVE',
                                       'MTC-SETUP-ADJUST_TOOL_RATES-EDIT',
                                       'MTC-SETUP-ADJUST_TOOL_RATES-DELETE',
                                       'MTC-SETUP-ADJUST_TOOL_RATES-VIEW',
                                       'MTC-SETUP-ACTIVITY_TYPE-CREATE',
                                       'MTC-SETUP-ACTIVITY_TYPE-APPROVE',
                                       'MTC-SETUP-ACTIVITY_TYPE-EDIT',
                                       'MTC-SETUP-ACTIVITY_TYPE-DELETE',
                                       'MTC-SETUP-ACTIVITY_TYPE-VIEW',
                                       'MTC-SETUP-STRATEGY-VIEW',
                                       'MTC-SETUP-TENANTS-CREATE',
                                       'MTC-SETUP-TENANTS-APPROVE',
                                       'MTC-SETUP-TENANTS-EDIT',
                                       'MTC-SETUP-TENANTS-DELETE',
                                       'MTC-SETUP-TENANTS-VIEW',
                                       'MTC-SETUP-TEAMS-CREATE',
                                       'MTC-SETUP-TEAMS-APPROVE',
                                       'MTC-SETUP-TEAMS-EDIT',
                                       'MTC-SETUP-TEAMS-DELETE',
                                       'MTC-SETUP-TEAMS-VIEW'
                                    ]"
                                 >
                                    <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Setup</mdb-accordion-item-head>
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li>
                                             <mdb-accordion-item
                                                *appHasRole="[
                                                   'SYSTEM-ADMIN',
                                                   'MAINTENANCE-MANAGER',
                                                   'MTC-SETUP-LABOUR_RESOURCE_TYPE-CREATE',
                                                   'MTC-SETUP-LABOUR_RESOURCE_TYPE-APPROVE',
                                                   'MTC-SETUP-LABOUR_RESOURCE_TYPE-EDIT',
                                                   'MTC-SETUP-LABOUR_RESOURCE_TYPE-DELETE',
                                                   'MTC-SETUP-LABOUR_RESOURCE_TYPE-VIEW',
                                                   'MTC-SETUP-LABOUR_RESOURCE-CREATE',
                                                   'MTC-SETUP-LABOUR_RESOURCE-APPROVE',
                                                   'MTC-SETUP-LABOUR_RESOURCE-EDIT',
                                                   'MTC-SETUP-LABOUR_RESOURCE-DELETE',
                                                   'MTC-SETUP-LABOUR_RESOURCE-VIEW',
                                                   'MTC-SETUP-ADJUST_LABOUR_RATES-CREATE',
                                                   'MTC-SETUP-ADJUST_LABOUR_RATES-APPROVE',
                                                   'MTC-SETUP-ADJUST_LABOUR_RATES-EDIT',
                                                   'MTC-SETUP-ADJUST_LABOUR_RATES-DELETE',
                                                   'MTC-SETUP-ADJUST_LABOUR_RATES-VIEW',
                                                   'MTC-SETUP-ASSET_RESOURCE_TYPE-CREATE',
                                                   'MTC-SETUP-ASSET_RESOURCE_TYPE-APPROVE',
                                                   'MTC-SETUP-ASSET_RESOURCE_TYPE-EDIT',
                                                   'MTC-SETUP-ASSET_RESOURCE_TYPE-DELETE',
                                                   'MTC-SETUP-ASSET_RESOURCE_TYPE-VIEW',
                                                   'MTC-SETUP-ASSET_RESOURCES-CREATE',
                                                   'MTC-SETUP-ASSET_RESOURCES-APPROVE',
                                                   'MTC-SETUP-ASSET_RESOURCES-EDIT',
                                                   'MTC-SETUP-ASSET_RESOURCES-DELETE',
                                                   'MTC-SETUP-ASSET_RESOURCES-VIEW',
                                                   'MTC-SETUP-ADJUST_ASSET_RATES-CREATE',
                                                   'MTC-SETUP-ADJUST_ASSET_RATES-APPROVE',
                                                   'MTC-SETUP-ADJUST_ASSET_RATES-EDIT',
                                                   'MTC-SETUP-ADJUST_ASSET_RATES-DELETE',
                                                   'MTC-SETUP-ADJUST_ASSET_RATES-VIEW',
                                                   'MTC-SETUP-TOOL_RESOURCE_TYPE-CREATE',
                                                   'MTC-SETUP-TOOL_RESOURCE_TYPE-APPROVE',
                                                   'MTC-SETUP-TOOL_RESOURCE_TYPE-EDIT',
                                                   'MTC-SETUP-TOOL_RESOURCE_TYPE-DELETE',
                                                   'MTC-SETUP-TOOL_RESOURCE_TYPE-VIEW',
                                                   'MTC-SETUP-TOOL_RESOURCES-CREATE',
                                                   'MTC-SETUP-TOOL_RESOURCES-APPROVE',
                                                   'MTC-SETUP-TOOL_RESOURCES-EDIT',
                                                   'MTC-SETUP-TOOL_RESOURCES-DELETE',
                                                   'MTC-SETUP-TOOL_RESOURCES-VIEW',
                                                   'MTC-SETUP-ADJUST_TOOL_RATES-CREATE',
                                                   'MTC-SETUP-ADJUST_TOOL_RATES-APPROVE',
                                                   'MTC-SETUP-ADJUST_TOOL_RATES-EDIT',
                                                   'MTC-SETUP-ADJUST_TOOL_RATES-DELETE',
                                                   'MTC-SETUP-ADJUST_TOOL_RATES-VIEW'
                                                ]"
                                             >
                                                <mdb-accordion-item-head mdbWavesEffect
                                                   ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Resources</mdb-accordion-item-head
                                                >
                                                <mdb-accordion-item-body>
                                                   <ul>
                                                      <li>
                                                         <mdb-accordion-item
                                                            *appHasRole="[
                                                               'SYSTEM-ADMIN',
                                                               'MAINTENANCE-MANAGER',
                                                               'MTC-SETUP-LABOUR_RESOURCE_TYPE-CREATE',
                                                               'MTC-SETUP-LABOUR_RESOURCE_TYPE-APPROVE',
                                                               'MTC-SETUP-LABOUR_RESOURCE_TYPE-EDIT',
                                                               'MTC-SETUP-LABOUR_RESOURCE_TYPE-DELETE',
                                                               'MTC-SETUP-LABOUR_RESOURCE_TYPE-VIEW',
                                                               'MTC-SETUP-ADJUST_LABOUR_RATES-CREATE',
                                                               'MTC-SETUP-ADJUST_LABOUR_RATES-APPROVE',
                                                               'MTC-SETUP-ADJUST_LABOUR_RATES-EDIT',
                                                               'MTC-SETUP-ADJUST_LABOUR_RATES-DELETE',
                                                               'MTC-SETUP-ADJUST_LABOUR_RATES-VIEW'
                                                            ]"
                                                         >
                                                            <mdb-accordion-item-head mdbWavesEffect
                                                               ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Labour Resources</mdb-accordion-item-head
                                                            >
                                                            <mdb-accordion-item-body>
                                                               <ul>
                                                                  <li
                                                                     *appHasRole="[
                                                                        'SYSTEM-ADMIN',
                                                                        'MAINTENANCE-MANAGER',
                                                                        'MTC-SETUP-LABOUR_RESOURCE_TYPE-CREATE',
                                                                        'MTC-SETUP-LABOUR_RESOURCE_TYPE-APPROVE',
                                                                        'MTC-SETUP-LABOUR_RESOURCE_TYPE-EDIT',
                                                                        'MTC-SETUP-LABOUR_RESOURCE_TYPE-DELETE',
                                                                        'MTC-SETUP-LABOUR_RESOURCE_TYPE-VIEW'
                                                                     ]"
                                                                  >
                                                                     <a [routerLink]="['/maintenance/labourresourcetype']" class="waves-effect" mdbWavesEffect
                                                                        ><i class="fa fa-users-cog mr-2"></i> Labour Resource Type</a
                                                                     >
                                                                  </li>
                                                                  <li
                                                                     *appHasRole="[
                                                                        'SYSTEM-ADMIN',
                                                                        'MAINTENANCE-MANAGER',
                                                                        'MTC-SETUP-LABOUR_RESOURCE-CREATE',
                                                                        'MTC-SETUP-LABOUR_RESOURCE-APPROVE',
                                                                        'MTC-SETUP-LABOUR_RESOURCE-EDIT',
                                                                        'MTC-SETUP-LABOUR_RESOURCE-DELETE',
                                                                        'MTC-SETUP-LABOUR_RESOURCE-VIEW'
                                                                     ]"
                                                                  >
                                                                     <a [routerLink]="['/maintenance/labourresource']" class="waves-effect" mdbWavesEffect
                                                                        ><i class="fa fa-users mr-2"></i> Labour Resource</a
                                                                     >
                                                                  </li>
                                                                  <li
                                                                     *appHasRole="[
                                                                        'SYSTEM-ADMIN',
                                                                        'MAINTENANCE-MANAGER',
                                                                        'MTC-SETUP-ADJUST_LABOUR_RATES-CREATE',
                                                                        'MTC-SETUP-ADJUST_LABOUR_RATES-APPROVE',
                                                                        'MTC-SETUP-ADJUST_LABOUR_RATES-EDIT',
                                                                        'MTC-SETUP-ADJUST_LABOUR_RATES-DELETE',
                                                                        'MTC-SETUP-ADJUST_LABOUR_RATES-VIEW'
                                                                     ]"
                                                                  >
                                                                     <a [routerLink]="['/maintenance/adjustlabourresource']" class="waves-effect" mdbWavesEffect
                                                                        ><i class="fa fa-people-arrows mr-2"></i> Adjust Labour Rate</a
                                                                     >
                                                                  </li>
                                                               </ul>
                                                            </mdb-accordion-item-body>
                                                         </mdb-accordion-item>
                                                      </li>
                                                      <li>
                                                         <mdb-accordion-item
                                                            *appHasRole="[
                                                               'SYSTEM-ADMIN',
                                                               'MAINTENANCE-MANAGER',
                                                               'MTC-SETUP-ASSET_RESOURCE_TYPE-CREATE',
                                                               'MTC-SETUP-ASSET_RESOURCE_TYPE-APPROVE',
                                                               'MTC-SETUP-ASSET_RESOURCE_TYPE-EDIT',
                                                               'MTC-SETUP-ASSET_RESOURCE_TYPE-DELETE',
                                                               'MTC-SETUP-ASSET_RESOURCE_TYPE-VIEW',
                                                               'MTC-SETUP-ASSET_RESOURCES-CREATE',
                                                               'MTC-SETUP-ASSET_RESOURCES-APPROVE',
                                                               'MTC-SETUP-ASSET_RESOURCES-EDIT',
                                                               'MTC-SETUP-ASSET_RESOURCES-DELETE',
                                                               'MTC-SETUP-ASSET_RESOURCES-VIEW',
                                                               'MTC-SETUP-ADJUST_ASSET_RATES-CREATE',
                                                               'MTC-SETUP-ADJUST_ASSET_RATES-APPROVE',
                                                               'MTC-SETUP-ADJUST_ASSET_RATES-EDIT',
                                                               'MTC-SETUP-ADJUST_ASSET_RATES-DELETE',
                                                               'MTC-SETUP-ADJUST_ASSET_RATES-VIEW'
                                                            ]"
                                                         >
                                                            <mdb-accordion-item-head mdbWavesEffect
                                                               ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Asset Resources</mdb-accordion-item-head
                                                            >
                                                            <mdb-accordion-item-body>
                                                               <ul>
                                                                  <li
                                                                     *appHasRole="[
                                                                        'SYSTEM-ADMIN',
                                                                        'MAINTENANCE-MANAGER',
                                                                        'MTC-SETUP-ASSET_RESOURCE_TYPE-CREATE',
                                                                        'MTC-SETUP-ASSET_RESOURCE_TYPE-APPROVE',
                                                                        'MTC-SETUP-ASSET_RESOURCE_TYPE-EDIT',
                                                                        'MTC-SETUP-ASSET_RESOURCE_TYPE-DELETE',
                                                                        'MTC-SETUP-ASSET_RESOURCE_TYPE-VIEW'
                                                                     ]"
                                                                  >
                                                                     <a routerLinkActive="active" [routerLink]="['/maintenance/resourcetype']" class="waves-effect" mdbWavesEffect
                                                                        ><i class="fas fa-truck"></i>Asset Resource Type</a
                                                                     >
                                                                  </li>
                                                                  <li
                                                                     *appHasRole="[
                                                                        'SYSTEM-ADMIN',
                                                                        'MAINTENANCE-MANAGER',
                                                                        'MTC-SETUP-ASSET_RESOURCES-CREATE',
                                                                        'MTC-SETUP-ASSET_RESOURCES-APPROVE',
                                                                        'MTC-SETUP-ASSET_RESOURCES-EDIT',
                                                                        'MTC-SETUP-ASSET_RESOURCES-DELETE',
                                                                        'MTC-SETUP-ASSET_RESOURCES-VIEW'
                                                                     ]"
                                                                  >
                                                                     <a routerLinkActive="active" [routerLink]="['/maintenance/resource']" class="waves-effect" mdbWavesEffect>
                                                                        <i class="fas fa-truck-pickup"></i>Asset Resource
                                                                     </a>
                                                                  </li>
                                                                  <li
                                                                     *appHasRole="[
                                                                        'SYSTEM-ADMIN',
                                                                        'MAINTENANCE-MANAGER',
                                                                        'MTC-SETUP-ADJUST_ASSET_RATES-CREATE',
                                                                        'MTC-SETUP-ADJUST_ASSET_RATES-APPROVE',
                                                                        'MTC-SETUP-ADJUST_ASSET_RATES-EDIT',
                                                                        'MTC-SETUP-ADJUST_ASSET_RATES-DELETE',
                                                                        'MTC-SETUP-ADJUST_ASSET_RATES-VIEW'
                                                                     ]"
                                                                  >
                                                                     <a [routerLink]="['/maintenance/adjustresource']" class="waves-effect" mdbWavesEffect
                                                                        ><i class="fa fa-people-arrows mr-2"></i> Adjust Resource Rate</a
                                                                     >
                                                                  </li>
                                                               </ul>
                                                            </mdb-accordion-item-body>
                                                         </mdb-accordion-item>
                                                      </li>
                                                      <li>
                                                         <mdb-accordion-item
                                                            *appHasRole="[
                                                               'SYSTEM-ADMIN',
                                                               'MAINTENANCE-MANAGER',
                                                               'MTC-SETUP-TOOL_RESOURCE_TYPE-CREATE',
                                                               'MTC-SETUP-TOOL_RESOURCE_TYPE-APPROVE',
                                                               'MTC-SETUP-TOOL_RESOURCE_TYPE-EDIT',
                                                               'MTC-SETUP-TOOL_RESOURCE_TYPE-DELETE',
                                                               'MTC-SETUP-TOOL_RESOURCE_TYPE-VIEW',
                                                               'MTC-SETUP-TOOL_RESOURCES-CREATE',
                                                               'MTC-SETUP-TOOL_RESOURCES-APPROVE',
                                                               'MTC-SETUP-TOOL_RESOURCES-EDIT',
                                                               'MTC-SETUP-TOOL_RESOURCES-DELETE',
                                                               'MTC-SETUP-TOOL_RESOURCES-VIEW',
                                                               'MTC-SETUP-ADJUST_TOOL_RATES-CREATE',
                                                               'MTC-SETUP-ADJUST_TOOL_RATES-APPROVE',
                                                               'MTC-SETUP-ADJUST_TOOL_RATES-EDIT',
                                                               'MTC-SETUP-ADJUST_TOOL_RATES-DELETE',
                                                               'MTC-SETUP-ADJUST_TOOL_RATES-VIEW'
                                                            ]"
                                                         >
                                                            <mdb-accordion-item-head mdbWavesEffect
                                                               ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Tool Resources</mdb-accordion-item-head
                                                            >
                                                            <mdb-accordion-item-body>
                                                               <ul>
                                                                  <li
                                                                     *appHasRole="[
                                                                        'SYSTEM-ADMIN',
                                                                        'MAINTENANCE-MANAGER',
                                                                        'MTC-SETUP-TOOL_RESOURCE_TYPE-CREATE',
                                                                        'MTC-SETUP-TOOL_RESOURCE_TYPE-APPROVE',
                                                                        'MTC-SETUP-TOOL_RESOURCE_TYPE-EDIT',
                                                                        'MTC-SETUP-TOOL_RESOURCE_TYPE-DELETE',
                                                                        'MTC-SETUP-TOOL_RESOURCE_TYPE-VIEW'
                                                                     ]"
                                                                  >
                                                                     <a routerLinkActive="active" [routerLink]="['/maintenance/tooltype']" class="waves-effect" mdbWavesEffect
                                                                        ><i class="fas fa-wrench"></i>Tool Type</a
                                                                     >
                                                                  </li>
                                                                  <li
                                                                     *appHasRole="[
                                                                        'SYSTEM-ADMIN',
                                                                        'MAINTENANCE-MANAGER',
                                                                        'MTC-SETUP-TOOL_RESOURCES-CREATE',
                                                                        'MTC-SETUP-TOOL_RESOURCES-APPROVE',
                                                                        'MTC-SETUP-TOOL_RESOURCES-EDIT',
                                                                        'MTC-SETUP-TOOL_RESOURCES-DELETE',
                                                                        'MTC-SETUP-TOOL_RESOURCES-VIEW'
                                                                     ]"
                                                                  >
                                                                     <a routerLinkActive="active" [routerLink]="['/maintenance/tool']" class="waves-effect" mdbWavesEffect
                                                                        ><i class="fas fa-tools"></i>Tool</a
                                                                     >
                                                                  </li>
                                                                  <li
                                                                     *appHasRole="[
                                                                        'SYSTEM-ADMIN',
                                                                        'MAINTENANCE-MANAGER',
                                                                        'MTC-SETUP-ADJUST_TOOL_RATES-CREATE',
                                                                        'MTC-SETUP-ADJUST_TOOL_RATES-APPROVE',
                                                                        'MTC-SETUP-ADJUST_TOOL_RATES-EDIT',
                                                                        'MTC-SETUP-ADJUST_TOOL_RATES-DELETE',
                                                                        'MTC-SETUP-ADJUST_TOOL_RATES-VIEW'
                                                                     ]"
                                                                  >
                                                                     <a [routerLink]="['/maintenance/adjusttool']" class="waves-effect" mdbWavesEffect
                                                                        ><i class="fa fa-exchange-alt mr-2"></i> Adjust Tool Rate</a
                                                                     >
                                                                  </li>
                                                               </ul>
                                                            </mdb-accordion-item-body>
                                                         </mdb-accordion-item>
                                                      </li>
                                                   </ul>
                                                </mdb-accordion-item-body>
                                             </mdb-accordion-item>
                                          </li>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-SETUP-ACTIVITY_TYPE-CREATE',
                                                'MTC-SETUP-ACTIVITY_TYPE-APPROVE',
                                                'MTC-SETUP-ACTIVITY_TYPE-EDIT',
                                                'MTC-SETUP-ACTIVITY_TYPE-DELETE',
                                                'MTC-SETUP-ACTIVITY_TYPE-VIEW'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/activitytype']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-pencil-ruler mr-2"></i> Activity Type</a
                                             >
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'MAINTENANCE-MANAGER', 'MTC-SETUP-STRATEGY-VIEW']">
                                             <a [routerLink]="['/maintenance/strategy']" class="waves-effect" mdbWavesEffect><i class="fa fa-directions mr-2"></i> Strategy</a>
                                          </li>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-SETUP-TENANTS-CREATE',
                                                'MTC-SETUP-TENANTS-APPROVE',
                                                'MTC-SETUP-TENANTS-EDIT',
                                                'MTC-SETUP-TENANTS-DELETE',
                                                'MTC-SETUP-TENANTS-VIEW'
                                             ]"
                                          >
                                             <a routerLinkActive="active" [routerLink]="['/maintenance/tenant']" class="waves-effect" mdbWavesEffect
                                                ><i class="fas fa-user-tag"></i>Client</a
                                             >
                                          </li>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-SETUP-TEAMS-CREATE',
                                                'MTC-SETUP-TEAMS-APPROVE',
                                                'MTC-SETUP-TEAMS-EDIT',
                                                'MTC-SETUP-TEAMS-DELETE',
                                                'MTC-SETUP-TEAMS-VIEW'
                                             ]"
                                          >
                                             <a routerLinkActive="active" [routerLink]="['/maintenance/team']" class="waves-effect" mdbWavesEffect
                                                ><i class="fas fa-user-friends"></i>Team</a
                                             >
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>
                              <li
                                 *appHasRole="[
                                    'SYSTEM-ADMIN',
                                    'MAINTENANCE-MANAGER',
                                    'MTC-INVENTORY_SETUP-WAREHOUSES-CREATE',
                                    'MTC-INVENTORY_SETUP-WAREHOUSES-APPROVE',
                                    'MTC-INVENTORY_SETUP-WAREHOUSES-EDIT',
                                    'MTC-INVENTORY_SETUP-WAREHOUSES-DELETE',
                                    'MTC-INVENTORY_SETUP-WAREHOUSES-VIEW',
                                    'MTC-INVENTORY_SETUP-STOREROOMS-CREATE',
                                    'MTC-INVENTORY_SETUP-STOREROOMS-APPROVE',
                                    'MTC-INVENTORY_SETUP-STOREROOMS-EDIT',
                                    'MTC-INVENTORY_SETUP-STOREROOMS-DELETE',
                                    'MTC-INVENTORY_SETUP-STOREROOMS-VIEW',
                                    'MTC-INVENTORY_SETUP-ISLES-CREATE',
                                    'MTC-INVENTORY_SETUP-ISLES-APPROVE',
                                    'MTC-INVENTORY_SETUP-ISLES-EDIT',
                                    'MTC-INVENTORY_SETUP-ISLES-DELETE',
                                    'MTC-INVENTORY_SETUP-ISLES-VIEW',
                                    'MTC-INVENTORY_SETUP-ROWS-CREATE',
                                    'MTC-INVENTORY_SETUP-ROWS-APPROVE',
                                    'MTC-INVENTORY_SETUP-ROWS-EDIT',
                                    'MTC-INVENTORY_SETUP-ROWS-DELETE',
                                    'MTC-INVENTORY_SETUP-ROWS-VIEW',
                                    'MTC-INVENTORY_SETUP-BINS-CREATE',
                                    'MTC-INVENTORY_SETUP-BINS-APPROVE',
                                    'MTC-INVENTORY_SETUP-BINS-EDIT',
                                    'MTC-INVENTORY_SETUP-BINS-DELETE',
                                    'MTC-INVENTORY_SETUP-BINS-VIEW'
                                 ]"
                              >
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect
                                       ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Inventory Setup</mdb-accordion-item-head
                                    >
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-INVENTORY_SETUP-WAREHOUSES-CREATE',
                                                'MTC-INVENTORY_SETUP-WAREHOUSES-APPROVE',
                                                'MTC-INVENTORY_SETUP-WAREHOUSES-EDIT',
                                                'MTC-INVENTORY_SETUP-WAREHOUSES-DELETE',
                                                'MTC-INVENTORY_SETUP-WAREHOUSES-VIEW'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/warehouses']" class="waves-effect" mdbWavesEffect><i class="fa fa-home mr-2"></i> Warehouses</a>
                                          </li>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-INVENTORY_SETUP-STOREROOMS-CREATE',
                                                'MTC-INVENTORY_SETUP-STOREROOMS-APPROVE',
                                                'MTC-INVENTORY_SETUP-STOREROOMS-EDIT',
                                                'MTC-INVENTORY_SETUP-STOREROOMS-DELETE',
                                                'MTC-INVENTORY_SETUP-STOREROOMS-VIEW'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/storerooms']" class="waves-effect" mdbWavesEffect><i class="fa fa-door-closed mr-2"></i> Storerooms</a>
                                          </li>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-INVENTORY_SETUP-ISLES-CREATE',
                                                'MTC-INVENTORY_SETUP-ISLES-APPROVE',
                                                'MTC-INVENTORY_SETUP-ISLES-EDIT',
                                                'MTC-INVENTORY_SETUP-ISLES-DELETE',
                                                'MTC-INVENTORY_SETUP-ISLES-VIEW'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/isles']" class="waves-effect" mdbWavesEffect><i class="fa fa-grip-lines mr-2"></i> Isles</a>
                                          </li>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-INVENTORY_SETUP-ROWS-CREATE',
                                                'MTC-INVENTORY_SETUP-ROWS-APPROVE',
                                                'MTC-INVENTORY_SETUP-ROWS-EDIT',
                                                'MTC-INVENTORY_SETUP-ROWS-DELETE',
                                                'MTC-INVENTORY_SETUP-ROWS-VIEW'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/rows']" class="waves-effect" mdbWavesEffect><i class="fa fa-sitemap mr-2"></i> Rows</a>
                                          </li>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-INVENTORY_SETUP-BINS-CREATE',
                                                'MTC-INVENTORY_SETUP-BINS-APPROVE',
                                                'MTC-INVENTORY_SETUP-BINS-EDIT',
                                                'MTC-INVENTORY_SETUP-BINS-DELETE',
                                                'MTC-INVENTORY_SETUP-BINS-VIEW'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/bins']" class="waves-effect" mdbWavesEffect><i class="fa fa-dumpster mr-2"></i> Bins</a>
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>

                              <li
                                 *appHasRole="[
                                    'SYSTEM-ADMIN',
                                    'MAINTENANCE-MANAGER',
                                    'MTC-PARTS-PART_CATEGORY-CREATE',
                                    'MTC-PARTS-PART_CATEGORY-APPROVE',
                                    'MTC-PARTS-PART_CATEGORY-EDIT',
                                    'MTC-PARTS-PART_CATEGORY-DELETE',
                                    'MTC-PARTS-PART_CATEGORY-VIEW',
                                    'MTC-PARTS-MANAGE_PARTS-CREATE',
                                    'MTC-PARTS-MANAGE_PARTS-APPROVE',
                                    'MTC-PARTS-MANAGE_PARTS-EDIT',
                                    'MTC-PARTS-MANAGE_PARTS-DELETE',
                                    'MTC-PARTS-MANAGE_PARTS-VIEW',
                                    'MTC-PARTS-MANAGE_PARTS-RECEIVE',
                                    'MTC-PARTS-STOCK_REQUISITION-CREATE',
                                    'MTC-PARTS-STOCK_REQUISITION-APPROVE',
                                    'MTC-PARTS-STOCK_REQUISITION-EDIT',
                                    'MTC-PARTS-STOCK_REQUISITION-DELETE',
                                    'MTC-PARTS-STOCK_REQUISITION-VIEW',
                                    'MTC-PARTS-COLLECT_RETURN-VIEW',
                                    'MTC-PARTS-COLLECT_RETURN-COLLECT',
                                    'MTC-PARTS-COLLECT_RETURN-RETURN'
                                 ]"
                              >
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Parts</mdb-accordion-item-head>
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-PARTS-PART_CATEGORY-CREATE',
                                                'MTC-PARTS-PART_CATEGORY-APPROVE',
                                                'MTC-PARTS-PART_CATEGORY-EDIT',
                                                'MTC-PARTS-PART_CATEGORY-DELETE',
                                                'MTC-PARTS-PART_CATEGORY-VIEW'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/partcategory']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-sitemap mr-2"></i> Part Category</a
                                             >
                                          </li>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-PARTS-MANAGE_PARTS-CREATE',
                                                'MTC-PARTS-MANAGE_PARTS-APPROVE',
                                                'MTC-PARTS-MANAGE_PARTS-EDIT',
                                                'MTC-PARTS-MANAGE_PARTS-DELETE',
                                                'MTC-PARTS-MANAGE_PARTS-VIEW',
                                                'MTC-PARTS-MANAGE_PARTS-RECEIVE'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/part']" class="waves-effect" mdbWavesEffect><i class="fa fa-wrench mr-2"></i> Manage Parts</a>
                                          </li>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-PARTS-STOCK_REQUISITION-CREATE',
                                                'MTC-PARTS-STOCK_REQUISITION-APPROVE',
                                                'MTC-PARTS-STOCK_REQUISITION-EDIT',
                                                'MTC-PARTS-STOCK_REQUISITION-DELETE',
                                                'MTC-PARTS-STOCK_REQUISITION-VIEW'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/stockrequisition']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-shopping-basket mr-2"></i> Stock Requisition</a
                                             >
                                          </li>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-PARTS-COLLECT_RETURN-VIEW',
                                                'MTC-PARTS-COLLECT_RETURN-COLLECT',
                                                'MTC-PARTS-COLLECT_RETURN-RETURN'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/workorder/collection']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-exchange-alt mr-2"></i> Collect/Return Parts</a
                                             >
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>
                              <li
                                 *appHasRole="[
                                    'SYSTEM-ADMIN',
                                    'MAINTENANCE-MANAGER',
                                    'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-COMPLETE',
                                    'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-EDIT',
                                    'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-DELETE',
                                    'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-VIEW',
                                    'MTC-USAGE_MONITORING-ASSET_TRACKING_READY-ADD_READING',
                                    'MTC-USAGE_MONITORING-ASSET_TRACKING_READY-REMOVE',
                                    'MTC-USAGE_MONITORING-ASSET_TRACKING_READY-VIEW'
                                 ]"
                              >
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect
                                       ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Asset Usage Monitoring</mdb-accordion-item-head
                                    >
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-COMPLETE',
                                                'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-EDIT',
                                                'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-DELETE',
                                                'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-VIEW'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/usage']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-tachometer-alt mr-2"></i> Assets Pending Setup</a
                                             >
                                          </li>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-USAGE_MONITORING-ASSET_TRACKING_READY-ADD_READING',
                                                'MTC-USAGE_MONITORING-ASSET_TRACKING_READY-REMOVE',
                                                'MTC-USAGE_MONITORING-ASSET_TRACKING_READY-VIEW'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/usage/active']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-chart-line mr-2"></i> Assets Tracking Active</a
                                             >
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>

                              <li
                                 *appHasRole="[
                                    'SYSTEM-ADMIN',
                                    'MAINTENANCE-MANAGER',
                                    'MTC-WORKORDER-REQUESTS-CREATE',
                                    'MTC-WORKORDER-REQUESTS-APPROVE',
                                    'MTC-WORKORDER-REQUESTS-EDIT',
                                    'MTC-WORKORDER-REQUESTS-DELETE',
                                    'MTC-WORKORDER-REQUESTS-VIEW',
                                    'MTC-WORKORDER-ACTIVITIES-CREATE',
                                    'MTC-WORKORDER-ACTIVITIES-APPROVE',
                                    'MTC-WORKORDER-ACTIVITIES-EDIT',
                                    'MTC-WORKORDER-ACTIVITIES-DELETE',
                                    'MTC-WORKORDER-ACTIVITIES-VIEW',
                                    'MTC-WORKORDER-TRIGGERED_ASSETS-CREATE',
                                    'MTC-WORKORDER-TRIGGERED_ASSETS-VIEW',
                                    'MTC-WORKORDER-WORKORDERS-CREATE',
                                    'MTC-WORKORDER-WORKORDERS-EDIT',
                                    'MTC-WORKORDER-WORKORDERS-VIEW',
                                    'MTC-WORKORDER-WORKORDERS-SCHEDULE',
                                    'MTC-WORKORDER-SCHEDULE_WORKORDERS-SCHEDULE',
                                    'MTC-WORKORDER-SCHEDULE_WORKORDERS-EDIT_SCHEDULE',
                                    'MTC-WORKORDER-SCHEDULE_WORKORDERS-VIEW_SCHEDULE',
                                    'MTC-WORKORDER-PICK_WORKORDER-VIEW',
                                    'MTC-WORKORDER-MY_WORKORDERS-VIEW',
                                    'MTC-WORKORDER-JOBCARD_ASSESSMENTS-CREATE',
                                    'MTC-WORKORDER-JOBCARD_ASSESSMENTS-APPROVE',
                                    'MTC-WORKORDER-JOBCARD_ASSESSMENTS-EDIT',
                                    'MTC-WORKORDER-JOBCARD_ASSESSMENTS-DELETE',
                                    'MTC-WORKORDER-JOBCARD_ASSESSMENTS-VIEW'
                                 ]"
                              >
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect
                                       ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Work Order</mdb-accordion-item-head
                                    >
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-WORKORDER-REQUESTS-CREATE',
                                                'MTC-WORKORDER-REQUESTS-APPROVE',
                                                'MTC-WORKORDER-REQUESTS-EDIT',
                                                'MTC-WORKORDER-REQUESTS-DELETE',
                                                'MTC-WORKORDER-REQUESTS-VIEW'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/requisition']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-shopping-basket mr-2"></i> Requests</a
                                             >
                                          </li>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-WORKORDER-ACTIVITIES-CREATE',
                                                'MTC-WORKORDER-ACTIVITIES-APPROVE',
                                                'MTC-WORKORDER-ACTIVITIES-EDIT',
                                                'MTC-WORKORDER-ACTIVITIES-DELETE',
                                                'MTC-WORKORDER-ACTIVITIES-VIEW'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/activity']" class="waves-effect" mdbWavesEffect><i class="fa fa-wrench mr-2"></i>Activities</a>
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'MAINTENANCE-MANAGER', 'MTC-WORKORDER-TRIGGERED_ASSETS-CREATE', 'MTC-WORKORDER-TRIGGERED_ASSETS-VIEW']">
                                             <a [routerLink]="['/maintenance/group']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-object-group mr-2"></i>Triggered Assets</a
                                             >
                                          </li>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-WORKORDER-WORKORDERS-CREATE',
                                                'MTC-WORKORDER-WORKORDERS-EDIT',
                                                'MTC-WORKORDER-WORKORDERS-VIEW',
                                                'MTC-WORKORDER-WORKORDERS-SCHEDULE'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/workorder']" class="waves-effect" mdbWavesEffect><i class="fa fa-briefcase mr-2"></i>Work Orders</a>
                                          </li>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-WORKORDER-SCHEDULE_WORKORDERS-SCHEDULE',
                                                'MTC-WORKORDER-SCHEDULE_WORKORDERS-EDIT_SCHEDULE',
                                                'MTC-WORKORDER-SCHEDULE_WORKORDERS-VIEW_SCHEDULE'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/schedule']" class="waves-effect" mdbWavesEffect><i class="fa fa-calendar-alt mr-2"></i> Schedule</a>
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'MAINTENANCE-MANAGER', 'MTC-WORKORDER-PICK_WORKORDER-VIEW']">
                                             <a [routerLink]="['/maintenance/pickworkorder/null']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-hand-pointer mr-2"></i> Pick Work Order</a
                                             >
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'MAINTENANCE-MANAGER', 'MTC-WORKORDER-MY_WORKORDERS-VIEW']">
                                             <a [routerLink]="['/maintenance/workorder/owner']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-user-edit mr-2"></i> My Work Orders</a
                                             >
                                          </li>
                                          <li
                                             *appHasRole="[
                                                'SYSTEM-ADMIN',
                                                'MAINTENANCE-MANAGER',
                                                'MTC-WORKORDER-JOBCARD_ASSESSMENTS-CREATE',
                                                'MTC-WORKORDER-JOBCARD_ASSESSMENTS-APPROVE',
                                                'MTC-WORKORDER-JOBCARD_ASSESSMENTS-EDIT',
                                                'MTC-WORKORDER-JOBCARD_ASSESSMENTS-DELETE',
                                                'MTC-WORKORDER-JOBCARD_ASSESSMENTS-VIEW'
                                             ]"
                                          >
                                             <a [routerLink]="['/maintenance/jobcard/jobcard-assessment']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-clipboard-check mr-2"></i> Job Card Assessment</a
                                             >
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'MAINTENANCE-MANAGER', 'MTC-REPORTS-VIEW', 'MTC-REPORTS-PRINTVIEW']">
                                 <a [routerLink]="['/maintenance/reportdashboard']" class="waves-effect" mdbWavesEffect><i class="fa fa-print mr-2"></i> Reports</a>
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>

                     <mdb-accordion-item
                        *appHasRole="[
                           'SYSTEM-ADMIN',
                           'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_1-VIEW',
                           'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_2-VIEW',
                           'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_3-VIEW',
                           'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_4-VIEW',
                           'ERP-OTHER_INVOICE_IMPORTS-EXTERNAL_INVOICES-VIEW',
                           'ERP-OTHER_INVOICE_IMPORTS-ARCHIVED_INVOICES-VIEW',
                           'ERP-AUDIT-ACCOUNTS-VIEW',
                           'ERP-AUDIT-ASSETS-VIEW',
                           'ERP-AUDIT-SUPPLIERS-VIEW',
                           'ERP-AUDIT-ERRORS-VIEW'
                        ]"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> ERP Integration</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <li *appHasRole="['SYSTEM-ADMIN', 'ERP-SCN_INTEGRATION-APPROVED_ASSETS-VIEW', 'ERP-SCN_INTEGRATION-ERRORS-VIEW']" class="SecondLevelMenu">
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect
                                       ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Scan Integration</mdb-accordion-item-head
                                    >
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'ERP-SCN_INTEGRATION-APPROVED_ASSETS-VIEW']">
                                             <a [routerLink]="['/scan/scanintegrated']" class="waves-effect" mdbWavesEffect
                                                ><i class="k-icon k-i-file-ascx mr-2"></i> Approved Assets</a
                                             >
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'ERP-SCN_INTEGRATION-ERRORS-VIEW']">
                                             <a [routerLink]="['/integration/errors']" class="waves-effect" mdbWavesEffect><i class="fa fa-exclamation-circle mr-2"></i> Errors</a>
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>
                              <li
                                 *appHasRole="[
                                    'SYSTEM-ADMIN',
                                    'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_1-VIEW',
                                    'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_2-VIEW',
                                    'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_3-VIEW',
                                    'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_4-VIEW',
                                    'ERP-OTHER_INVOICE_IMPORTS-EXTERNAL_INVOICES-VIEW',
                                    'ERP-OTHER_INVOICE_IMPORTS-ARCHIVED_INVOICES-VIEW'
                                 ]"
                                 class="SecondLevelMenu"
                              >
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect
                                       ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>ERP Imports</mdb-accordion-item-head
                                    >
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <ng-template [ngIf]="integrateWith" [ngIfElse]="advancedExternalImport">
                                             <li
                                                li
                                                *appHasRole="[
                                                   'SYSTEM-ADMIN',
                                                   'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_1-VIEW',
                                                   'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_2-VIEW',
                                                   'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_3-VIEW',
                                                   'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_4-VIEW'
                                                ]"
                                             >
                                                <a [routerLink]="['integration/importsNormal']" class="waves-effect" mdbWavesEffect
                                                   ><i class="fa fa-flag mr-2"></i> Asset Invoice Imports</a
                                                >
                                             </li>
                                             <li li *appHasRole="['SYSTEM-ADMIN', 'ERP-OTHER_INVOICE_IMPORTS-ARCHIVED_INVOICES-VIEW']">
                                                <a [routerLink]="['integration/importsNormalArchive']" class="waves-effect" mdbWavesEffect
                                                   ><i class="fa fa-flag mr-2"></i> Archived Invoices</a
                                                >
                                             </li>
                                          </ng-template>
                                          <ng-template #advancedExternalImport>
                                             <li>
                                                <a [routerLink]="['integration/imports']" class="waves-effect" mdbWavesEffect
                                                   ><i class="fa fa-flag mr-2"></i> Asset Invoice Imports</a
                                                >
                                             </li>
                                             <li>
                                                <a routerLinkActive="active" [routerLink]="['/integration/archived-invoices']" class="waves-effect" mdbWavesEffect
                                                   ><i class="fa fa-box-open mr-2"></i> Archived Invoices</a
                                                >
                                             </li>
                                          </ng-template>

                                          <li>
                                             <a routerLinkActive="active" [routerLink]="['/integration/asset-journal-imports']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-adjust mr-2"></i> Asset Journal Imports</a
                                             >
                                          </li>
                                          <li>
                                             <a routerLinkActive="active" [routerLink]="['/integration/asset-sales-imports']" class="waves-effect" mdbWavesEffect
                                                ><i class="fa fa-money-bill mr-2"></i> Asset Sales Imports</a
                                             >
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>
                              <li
                                 *appHasRole="['SYSTEM-ADMIN', 'ERP-AUDIT-ACCOUNTS-VIEW', 'ERP-AUDIT-ASSETS-VIEW', 'ERP-AUDIT-SUPPLIERS-VIEW', 'ERP-AUDIT-ERRORS-VIEW']"
                                 class="ThridLevelMenu"
                              >
                                 <mdb-accordion-item>
                                    <mdb-accordion-item-head mdbWavesEffect
                                       ><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Integration Audit</mdb-accordion-item-head
                                    >
                                    <mdb-accordion-item-body>
                                       <ul>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'ERP-AUDIT-ACCOUNTS-VIEW']">
                                             <a [routerLink]="['/integration/accounts']" class="waves-effect" mdbWavesEffect><i class="k-icon k-i-file-ascx mr-2"></i> Accounts</a>
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'ERP-AUDIT-ASSETS-VIEW']">
                                             <a [routerLink]="['/integration/assets']" class="waves-effect" mdbWavesEffect><i class="k-icon k-i-file-ascx mr-2"></i> Assets</a>
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'ERP-AUDIT-SUPPLIERS-VIEW']">
                                             <a [routerLink]="['/integration/suppliers']" class="waves-effect" mdbWavesEffect
                                                ><i class="k-icon k-i-file-ascx mr-2"></i> Suppliers</a
                                             >
                                          </li>
                                          <li *appHasRole="['SYSTEM-ADMIN', 'ERP-AUDIT-ERRORS-VIEW']">
                                             <a [routerLink]="['/integration/errors']" class="waves-effect" mdbWavesEffect><i class="fa fa-exclamation-circle mr-2"></i> Errors</a>
                                          </li>
                                       </ul>
                                    </mdb-accordion-item-body>
                                 </mdb-accordion-item>
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>

                     <mdb-accordion-item
                        *appHasRole="[
                           'SYSTEM-ADMIN',
                           'INSURANCE-CONFIGURE-INSURER-VIEW',
                           'INSURANCE-CONFIGURE-POLICIES-VIEW',
                           'INSURANCE-CONFIGURE-CONTACTS-VIEW',
                           'INSURANCE-CONFIGURE-GROUPS-VIEW',
                           'INSURANCE-CONFIGURE-LINK-VIEW',
                           'INSURANCE-PROCESSING-REGISTER-VIEW',
                           'INSURANCE-PROCESSING-ASSETS_PENDING_LIST-VIEW',
                           'INSURANCE-PROCESSING-CLAIMS-VIEW',
                           'INSURANCE-REPORTS-VIEW',
                           'INSURANCE-REPORTS-PRINTVIEW'
                        ]"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Insurance</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <mdb-accordion-item>
                                 <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Configure</mdb-accordion-item-head>
                                 <mdb-accordion-item-body>
                                    <ul>
                                       <li title="Create / Edit an Insurer" *appHasRole="['SYSTEM-ADMIN', 'INSURANCE-CONFIGURE-INSURER-VIEW']">
                                          <a [routerLink]="['/insurance/insurer']" class="waves-effect" mdbWavesEffect><i class="fa fa-user mr-2"></i> Insurer</a>
                                       </li>
                                       <li
                                          title="Create / Edit a Policy - this needs to be linked to an Insurer"
                                          *appHasRole="['SYSTEM-ADMIN', 'INSURANCE-CONFIGURE-POLICIES-VIEW']"
                                       >
                                          <a [routerLink]="['/insurance/policy']" class="waves-effect" mdbWavesEffect><i class="k-icon k-i-file-ascx mr-2"></i> Policies</a>
                                       </li>
                                       <li
                                          title="Create / Edit a Contact - this needs to be linked to an Insurer"
                                          *appHasRole="['SYSTEM-ADMIN', 'INSURANCE-CONFIGURE-CONTACTS-VIEW']"
                                       >
                                          <a [routerLink]="['/insurance/ins-contact']" class="waves-effect" mdbWavesEffect><i class="fa fa-users mr-2"></i> Contacts</a>
                                       </li>
                                       <li title="Create / Edit a Group - this needs to be linked to a Policy" *appHasRole="['SYSTEM-ADMIN', 'INSURANCE-CONFIGURE-GROUPS-VIEW']">
                                          <a [routerLink]="['/insurance/ins-group']" class="waves-effect" mdbWavesEffect><i class="fa fa-users-cog mr-2"></i> Groups</a>
                                       </li>
                                       <li
                                          title="Create / Edit links between Groups and Assets - this requires a Group"
                                          *appHasRole="['SYSTEM-ADMIN', 'INSURANCE-CONFIGURE-LINK-VIEW']"
                                       >
                                          <a [routerLink]="['/insurance/ins-assets']" class="waves-effect" mdbWavesEffect
                                             ><i class="k-icon k-i-toggle-full-screen-mode mr-2"></i> Link Assets to Groups</a
                                          >
                                       </li>
                                    </ul>
                                 </mdb-accordion-item-body>
                              </mdb-accordion-item>
                              <mdb-accordion-item>
                                 <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i> Processing</mdb-accordion-item-head>
                                 <mdb-accordion-item-body>
                                    <ul>
                                       <li
                                          title="Export all Assets from the Insurance Register for a chosen period"
                                          *appHasRole="['SYSTEM-ADMIN', 'INSURANCE-PROCESSING-REGISTER-VIEW']"
                                       >
                                          <a *appHasRole="['SYSTEM-ADMIN']" [routerLink]="['/insurance/insurance-register']" class="waves-effect" mdbWavesEffect
                                             ><i class="fa fa-chart-line mr-2"></i> Insurance Register</a
                                          >
                                       </li>
                                       <li
                                          title="View a list of Assets that are on the system, but not insured"
                                          *appHasRole="['SYSTEM-ADMIN', 'INSURANCE-PROCESSING-ASSETS_PENDING_LIST-VIEW']"
                                       >
                                          <a *appHasRole="['SYSTEM-ADMIN']" [routerLink]="['/insurance/pending-list']" class="waves-effect" mdbWavesEffect
                                             ><i class="fa fa-balance-scale mr-2"></i> Pending List</a
                                          >
                                       </li>
                                       <li title="Log a new Insurance Claim or process existing Claims" *appHasRole="['SYSTEM-ADMIN', 'INSURANCE-PROCESSING-CLAIMS-VIEW']">
                                          <a *appHasRole="['SYSTEM-ADMIN']" [routerLink]="['/insurance/claim']" class="waves-effect" mdbWavesEffect
                                             ><i class="fa fa-check mr-2"></i> Claim Processing</a
                                          >
                                       </li>
                                    </ul>
                                 </mdb-accordion-item-body>
                              </mdb-accordion-item>

                              <li *appHasRole="['SYSTEM-ADMIN', 'INSURANCE-REPORTS-VIEW', 'INSURANCE-REPORTS-PRINTVIEW']" title="View all available Insurance Reports">
                                 <a [routerLink]="['/insurance/ins-report']" class="waves-effect" mdbWavesEffect><i class="fa fa-print mr-2"></i> Reports</a>
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>

                     <mdb-accordion-item
                        *appHasRole="[
                           'SYSTEM-ADMIN',
                           'WARRANTY_MNGT-SETUP-TYPES-VIEW',
                           'WARRANTY_MNGT-SETUP-PROVIDERS-VIEW',
                           'WARRANTY_MNGT-WARRANTY_MNGT-CONTRACTORS-VIEW',
                           'WARRANTY_MNGT-WARRANTY_MNGT-ALL-VIEW',
                           'WARRANTY_MNGT-WARRANTY_MNGT-REPORTS-VIEW'
                        ]"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Warranty Management</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <mdb-accordion-item>
                                 <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-nothing"></i><i class="fa fa-folder yellow-text"></i>Setup</mdb-accordion-item-head>
                                 <mdb-accordion-item-body>
                                    <ul>
                                       <li *appHasRole="['SYSTEM-ADMIN', 'WARRANTY_MNGT-SETUP-TYPES-VIEW']">
                                          <a [routerLink]="['/warranty/types']" class="waves-effect" mdbWavesEffect><i class="fa fa-paperclip mr-2"></i> Types</a>
                                       </li>
                                       <li *appHasRole="['SYSTEM-ADMIN', 'WARRANTY_MNGT-SETUP-PROVIDERS-VIEW']">
                                          <a [routerLink]="['/warranty/providers']" class="waves-effect" mdbWavesEffect><i class="fa fa-truck mr-2"></i> Providers</a>
                                       </li>
                                       <li *appHasRole="['SYSTEM-ADMIN', 'WARRANTY_MNGT-WARRANTY_MNGT-CONTRACTORS-VIEW']">
                                          <a [routerLink]="['/warranty/contractors']" class="waves-effect" mdbWavesEffect><i class="fa fa-hard-hat mr-2"></i> Contractors</a>
                                       </li>
                                    </ul>
                                 </mdb-accordion-item-body>
                              </mdb-accordion-item>

                              <li *appHasRole="['SYSTEM-ADMIN', 'WARRANTY_MNGT-WARRANTY_MNGT-ALL-VIEW']">
                                 <a routerLinkActive="active" [routerLink]="['/warranty/all']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-list-alt mr-2"></i>All Warranties</a
                                 >
                              </li>
                              <li>
                                 <a [routerLink]="['/warranty/reports']" class="waves-effect" mdbWavesEffect><i class="fa fa-print mr-2"></i> Reports</a>
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>
                     <!-- Booking Manager -->
                     <!------------------>
                     <mdb-accordion-item *appHasRole="['SYSTEM-ADMIN', 'BOOKING-MANAGER']">
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Booking Management</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <li *appHasRole="['SYSTEM-ADMIN', 'BOOKING-MANAGER']" title="Booking">
                                 <a [routerLink]="['/bookingManagement/bookings']" class="waves-effect" mdbWavesEffect><i class="fa fa-exchange-alt mr-2"></i> Booking</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'BOOKING-MANAGER']" title="Clients">
                                 <a [routerLink]="['/bookingManagement/client']" class="waves-effect" mdbWavesEffect><i class="fa fa-user mr-2"></i> Clients</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'BOOKING-MANAGER']" title="Asset">
                                 <a [routerLink]="['/bookingManagement/bmm-asset']" class="waves-effect" mdbWavesEffect><i class="fa fa-project-diagram mr-2"></i> Asset</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'BOOKING-MANAGER']" title="Monitoring">
                                 <a [routerLink]="['/bookingManagement/monitorBookings']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-tachometer-alt mr-2"></i> Monitoring</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'BOOKING-MANAGER']" title="Reports">
                                 <a [routerLink]="['/bookingManagement/reportsBookings']" class="waves-effect" mdbWavesEffect><i class="fa fa-print mr-2"></i> Reports</a>
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>

                     <mdb-accordion-item
                        *appHasRole="[
                           'SYSTEM-ADMIN',
                           'WORKFLOW-PROCESSING-SETUP-VIEW',
                           'WORKFLOW-PROCESSING-ALL-VIEW',
                           'WORKFLOW-PROCESSING-APPROVALS-VIEW',
                           'WORKFLOW-PROCESSING-REJECTIONS-VIEW'
                        ]"
                     >
                        <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Workflow</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <ul>
                              <li *appHasRole="['SYSTEM-ADMIN', 'WORKFLOW-PROCESSING-SETUP-VIEW']">
                                 <a *appHasRole="['SYSTEM-ADMIN']" [routerLink]="['/workflow/setup']" class="waves-effect" mdbWavesEffect><i class="fa fa-cog mr-2"></i> Setup</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'WORKFLOW-PROCESSING-ALL-VIEW']">
                                 <a *appHasRole="['SYSTEM-ADMIN']" [routerLink]="['/workflow/all']" class="waves-effect" mdbWavesEffect
                                    ><i class="fa fa-list-alt mr-2"></i> All Workflows</a
                                 >
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'WORKFLOW-PROCESSING-APPROVALS-VIEW']">
                                 <a [routerLink]="['/workflow/open']" class="waves-effect" mdbWavesEffect><i class="fa fa-check mr-2"></i> Approvals</a>
                              </li>
                              <li *appHasRole="['SYSTEM-ADMIN', 'WORKFLOW-PROCESSING-REJECTIONS-VIEW']">
                                 <a [routerLink]="['/workflow/rejected']" class="waves-effect" mdbWavesEffect><i class="fa fa-thumbs-down mr-2"></i> Rejections to Review</a>
                              </li>
                           </ul>
                        </mdb-accordion-item-body>
                     </mdb-accordion-item>
                     <!-- <mdb-accordion-item>
      <mdb-accordion-item-head mdbWavesEffect><i class="fa fa-folder yellow-text"></i> Help</mdb-accordion-item-head>
      <mdb-accordion-item-body>
         <ul>
            <li>
               <a [routerLink]="['/help/main']" class="waves-effect" mdbWavesEffect><i class="fa fa-cog mr-2"></i> Main</a>
            </li>

         </ul>
      </mdb-accordion-item-body>
   </mdb-accordion-item> -->
                  </mdb-accordion>
               </ul>
            </li>
            <!--/. Side navigation links -->
         </links>
         <div class="sidenav-bg mask-strong"></div>
      </mdb-side-nav>

      <!--/. Sidebar navigation -->
      <!-- Navbar -->

      <mdb-navbar SideClass="navbar fixed-top navbar-toggleable-md navbar-expand-lg scrolling-navbar double-nav" [containerInside]="false">
         <navlinks class="navbar-container">
            <div class="float-left">
               <div class="d-flex" kendoTooltip tooltipClass="tooltip-ams">
                  <div>
                     <a (click)="sidenav.show()" class="button-collapse mb-2"><i class="fa fa-bars"></i></a>
                  </div>

                  <a title="Change Settings" class="ml-3 mt-2" *appHasRole="['SYSTEM-ADMIN']" style="z-index: 10000" [routerLink]="['/admin/setting']">
                     <div class="h3"><i class="fa fa-cog"></i></div>
                  </a>
                  <a title="Home" class="ml-3 mt-2" style="z-index: 10000" [routerLink]="['/']">
                     <div class="h3"><i class="fa fa-home"></i></div>
                  </a>
               </div>
            </div>
         </navlinks>

         <mdb-navbar-brand>
            <!-- Breadcrumb-->
            <div class="breadcrumbs breadcrumb-dn mr-auto">
               <p></p>
            </div>
            <!--/. Breadcrumb-->
         </mdb-navbar-brand>

         <navlinks>
            <ul class="nav navbar-nav nav-flex-icons ml-auto ie-double-nav">
               <!--   <li class="nav-item">
               <a class="nav-link waves-light" mdbWavesEffect><i class="fa fa-envelope"></i> <span class="clearfix d-none d-sm-inline-block">Contact</span></a>
           </li>
           <li class="nav-item">
               <a class="nav-link waves-light" mdbWavesEffect><i class="fa fa-comments-o"></i> <span class="clearfix d-none d-sm-inline-block">Support</span></a>
           </li>
           <li class="nav-item">
               <a class="nav-link waves-light" mdbWavesEffect><i class="fa fa-user"></i> <span class="clearfix d-none d-sm-inline-block">Account</span></a>
           </li> -->

               <img
                  style="height: 40px; width: 40px"
                  src="{{ this.user.url ? baseUrl + profilePic : '../assets/img/user.png' }}"
                  class="img-fluid rounded-circle z-depth-2"
                  alt="Profile"
               />

               <li class="nav-item dropdown btn-group" dropdown>
                  <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                     {{ user?.name }}
                  </a>
                  <div class="dropdown-menu dropdown-primary dropdown-menu-right" role="menu">
                     <a class="dropdown-item" [routerLink]="['/admin/profile']"><i class="fa fa-user-circle"></i> Profile</a>
                     <a class="dropdown-item" (click)="logout()"><i class="fa fa-power-off"></i> Logout</a>
                  </div>
               </li>
            </ul>
         </navlinks>
      </mdb-navbar>
   <!-- </div> ---------------- Disabled portal menu ---------------- -->

   <!--/. Navbar -->
</header>
<!--/.Double navigation-->
