<div [@fadeInOnEnter]>
   <div class="row" *ngIf="loadingNumbers">
      <div class="col-md-4">
         <app-skeleton size="small"></app-skeleton>
      </div>
      <div class="col-md-4">
         <app-skeleton size="small"></app-skeleton>
      </div>
      <div class="col-md-4">
         <app-skeleton size="small"></app-skeleton>
      </div>
   </div>
   <div class="row mt-2" *ngIf="loadingNumbers">
      <div class="col-md-4">
         <app-skeleton size="small"></app-skeleton>
      </div>
      <div class="col-md-4">
         <app-skeleton size="small"></app-skeleton>
      </div>
      <div class="col-md-4">
         <app-skeleton size="small"></app-skeleton>
      </div>
   </div>

   <section class="mt-lg-1" *ngIf="!loadingNumbers">
      <div class="row">
         <div class="col-xl-4 col-md-6 mb-r mt-2">
            <mdb-card cascade="true" class="cascading-admin-card">
               <div class="admin-up">
                  <mdb-icon far icon="money-bill-alt" class="primary-color"></mdb-icon>
                  <div class="data">
                     <p>BOOK VALUE</p>
                     <h4>
                        <strong>{{ dashboardNumbers?.bookValue | number : '1.2-2' }}</strong>
                     </h4>
                  </div>
               </div>
            </mdb-card>
         </div>
         <div class="col-xl-4 col-md-6 mb-r mt-2">
            <mdb-card cascade="true" class="cascading-admin-card">
               <div class="admin-up">
                  <mdb-icon fas icon="coins" class="warning-color"></mdb-icon>
                  <div class="data">
                     <p>COST</p>
                     <h4>
                        <strong>{{ dashboardNumbers?.cost | number : '1.2-2' }}</strong>
                     </h4>
                  </div>
               </div>
            </mdb-card>
         </div>
         <div class="col-xl-4 col-md-6 mb-r mt-2">
            <mdb-card cascade="true" class="cascading-admin-card">
               <div class="admin-up">
                  <mdb-icon fas icon="chart-line" class="light-blue lighten-1"></mdb-icon>
                  <div class="data">
                     <p>DEPRECIATION</p>
                     <h4>
                        <strong>{{ dashboardNumbers?.accDep | number : '1.2-2' }}</strong>
                     </h4>
                  </div>
               </div>
            </mdb-card>
         </div>
         <div class="col-xl-4 col-md-6 mb-r mt-2">
            <mdb-card cascade="true" class="cascading-admin-card">
               <div class="admin-up">
                  <mdb-icon fas icon="caret-square-down" class="red accent-2"></mdb-icon>
                  <div class="data">
                     <p>IMPAIRMENT</p>
                     <h4>
                        <strong>{{ dashboardNumbers?.accImp | number : '1.2-2' }}</strong>
                     </h4>
                  </div>
               </div>
            </mdb-card>
         </div>
         <div class="col-xl-4 col-md-6 mb-r mt-2">
            <mdb-card cascade="true" class="cascading-admin-card">
               <div class="admin-up">
                  <mdb-icon fas icon="caret-square-up" class="default-color"></mdb-icon>
                  <div class="data">
                     <p>REVALUATION</p>
                     <h4>
                        <strong>{{ dashboardNumbers?.revaluation | number : '1.2-2' }}</strong>
                     </h4>
                  </div>
               </div>
            </mdb-card>
         </div>
         <div class="col-xl-4 col-md-6 mb-r mt-2">
            <mdb-card cascade="true" class="cascading-admin-card">
               <div class="admin-up">
                  <mdb-icon fas icon="chart-bar" class="purple-gradient"></mdb-icon>
                  <div class="data">
                     <p>NO OF ASSETS</p>
                     <h4>
                        <strong>{{ dashboardNumbers?.countBarcode }}</strong>
                     </h4>
                  </div>
               </div>
            </mdb-card>
         </div>
      </div>
      <div class="d-flex mt-4">
         <button class="flex-fill mx-1 aqua-gradient text-white" (click)="openReportDepartment()" kendoButton type="submit">
            <i class="fa fa-search mr-2"></i>Company Overview
         </button>
         <button class="flex-fill mx-1 purple-gradient text-white" (click)="openReportClassification()" kendoButton type="submit">
            <i class="fa fa-search mr-2"></i>Asset Class Overview
         </button>
         <button class="flex-fill mx-1 peach-gradient text-white" (click)="openReportSupplier()" kendoButton type="submit">
            <i class="fa fa-search mr-2"></i>Supplier Overview
         </button>
      </div>
      <div class="row mt-4">
         <div class="col-md-12">
            <i class="fa fa-info-circle"></i> All Information is based on the last month end run. <b>{{ monthEndDate | date }}</b>
         </div>
      </div>
      <div class="row mt-4" *ngIf="!smtpHostDefault">
         <div class="col-md-12"><i class="fa fa-question-circle"></i> The default SMTP host has not been configured</div>
      </div>
      <div class="row mt-4" *ngIf="!smtpHostError">
         <div class="col-md-12"><i class="fa fa-question-circle"></i> The SMTP host is not able to send emails thus hampering Workflow and other Maintenance notifications</div>
      </div>
   </section>
</div>
