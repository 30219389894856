import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Setting } from '../../_models/setting';
import { Observable, Subject } from 'rxjs';
import { AppUpdate } from 'src/app/_models/appUpdate';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  baseUrl = environment.apiUrl;
  AvatarSelected = new Subject<boolean>();

  constructor(private http: HttpClient) { }
  changeAvatarState(IsOpen: boolean) {
    this.AvatarSelected.next(IsOpen);
  }
  getSettings() {
    return this.http.get<Setting[]>(this.baseUrl + 'setting');
  }

  getSetting(id): Observable<Setting> {
    return this.http.get<Setting>(this.baseUrl + 'setting/' + id);
  }
  getDepreciationEnabled(): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + 'setting/depreciationbuttonenabled')
  }

  updateSetting(settings: Setting[]) {
    return this.http.put(this.baseUrl + 'setting', settings);
  }
  updateApp(version: string) {
    return this.http.put(this.baseUrl + 'setting/updateApp/' + version, null);
  }
  checkForUpdates() {
    return this.http.get<AppUpdate[]>(this.baseUrl + 'setting/checkForUpdates/');
  }
  testEmail() {
    return this.http.put(this.baseUrl + 'setting/testemail', null);
  }
  updateSingleSetting(setting: Setting) {
    return this.http.put(this.baseUrl + 'setting/single/', setting);
  }
  exportApp(): Observable<Blob> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(this.baseUrl + 'setting/exportapp', {
      responseType: 'blob'
    });
  }
  removeAllDepreciation() {
    return this.http.delete(this.baseUrl + 'setting/delete/depreciation')
  }
  removeAllTransactions() {
    return this.http.delete(this.baseUrl + 'setting/delete/transactions')
  }
  backupDatabase() {
    return this.http.get(this.baseUrl + 'setting/backupdatabase')
  }

  restoreDatabase(path: string) {
    return this.http.get(this.baseUrl + 'setting/restoredatabase/' + path)
  }
}
