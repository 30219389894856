<app-login-Password-Change [isPasswordReset]="'false'"></app-login-Password-Change>
<div class="container mt-5" kendoRippleContainer>
   <div class="row align-items-center justify-content-center mt-5" *ngIf="!loginVisible">
      <mdb-spinner spinnerType="large"></mdb-spinner>
   </div>

   <div class="row align-items-center justify-content-center mt-5 animated pulse infinite z-depth-5 danger-color-dark text-white p-5" *ngIf="!licenseValid">
      <img class="block-center rounded ml-5 mr-5" src="assets/img/amsLogo.png" alt="Image" />
      <h1>License has expired</h1>
   </div>
   <div class="row align-items-center justify-content-center mt-5" *ngIf="licenseValid">
      <mdb-card *ngIf="loginVisible">
         <mdb-card-header class="primary-color white-text text-center py-4">
            <img class="block-center rounded ml-5 mr-5" src="assets/img/amsLogo.png" alt="Image" />
         </mdb-card-header>

         <!--Card content-->
         <mdb-card-body class="px-lg-5 pt-0">
            <div class="text-center pt-4">
               <h5>
                  <strong>Sign in</strong>
               </h5>
            </div>

            <form [formGroup]="valForm" class="form-validate mb-3" role="form" name="loginForm" novalidate="" (submit)="login($event, valForm.value)">
               <div>
                  <kendo-floatinglabel text="Username" class="w-100">
                     <input id="username" name="username" formControlName="username" kendoTextBox />
                  </kendo-floatinglabel>
                  <div
                     class="red-text error-text"
                     *ngIf="valForm.controls['username'].hasError('required') && (valForm.controls['username'].dirty || valForm.controls['username'].touched)"
                  >
                     Username required
                  </div>
               </div>

               <div>
                  <kendo-floatinglabel text="Password" class="w-100">
                     <input type="password" id="password" name="password" formControlName="password" kendoTextBox />
                  </kendo-floatinglabel>
                  <div
                     class="red-text error-text"
                     *ngIf="valForm.controls['password'].hasError('required') && (valForm.controls['password'].dirty || valForm.controls['password'].touched)"
                  >
                     Password required
                  </div>
               </div>
               <div class="modal-footer d-flex justify-content-center">
                  <button *ngIf="!loading" [disabled]="valForm.invalid" kendoButton type="submit" icon="lock" class="w-100 mt-3" [primary]="true">Sign In</button>

                  <mdb-spinner *ngIf="loading" spinnerType="small"></mdb-spinner>
               </div>
            </form>

            <small title="Version" class="mt-1 flex-center">V3.13.8</small>

            <div class="red-text error-text" *ngIf="migrationError !== ''">{{ migrationError }}</div>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>
