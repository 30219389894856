import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingService } from 'src/app/admin/_services/setting.service';
import { TelerikReportViewerComponent } from '@progress/telerik-angular-report-viewer';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { environment } from 'src/environments/environment';
import $ from 'jquery';

@Component({
   selector: 'app-report-parameter',
   templateUrl: './report-parameter.component.html',
   styleUrls: ['./report-parameter.component.scss'],
   animations: [fadeInOnEnterAnimation()],
})
export class ReportParameterComponent implements OnInit, AfterViewInit {
   @ViewChild('viewer1') viewer: TelerikReportViewerComponent;
   dateUpdated = false; // Flag to indicate that date format has been set
   reportName: string;
   companyName: string;
   registrationNumber: string;
   logo: string;
   reportId: string;

   viewerContainerStyle = {
      position: 'fixed',
      top: '74px',
      bottom: '0',
      right: '0',
      left: '240px',
      ['font-family']: 'ms sans serif',
   };

   Url = environment.apiUrl + 'reports';

   constructor(private route: ActivatedRoute, private settingService: SettingService) {}

   ngAfterViewInit(): void {
      this.settingService.getSettings().subscribe((settingList) => {
         settingList.forEach((setting) => {
            if (setting.id === 'LOGO') {
               if (setting.settingValue === '') {
                  this.logo = 'Company.png';
               } else {
                  this.logo = '../../' + setting.settingValue;
               }
            }
            if (setting.id === 'COMPNAME') {
               this.companyName = setting.settingValue;
            }
            if (setting.id === 'COMPREGNO') {
               this.registrationNumber = setting.settingValue;
            }
         });
         this.route.paramMap.subscribe((params) => {
            this.reportName = params.get('type') + '.trdp';
            this.reportId = params.get('reportId');
         });
         const rs = {
            report: this.reportName,
            parameters: { Logo: this.logo, CompanyName: this.companyName + ' - ' + this.registrationNumber, ReportId: this.reportId },
         };
         this.viewer.setReportSource(rs);
      });
   }

   ngOnInit() {}

   setDateFormat(dateFormat) {
      var datepicker = $('#reportViewer1').find("[data-role='datepicker']");
      if (datepicker.length > 0) {
         this.dateUpdated = true;

         for (var i = 0; i < datepicker.length; i += 1) {
            var dp = $(datepicker[i]).data('kendoDatePicker');
            var oldValue = dp.value();

            dp.setOptions({
               format: dateFormat,
            });
            dp.value(oldValue);
         }
      }
   }

   onUpdateUi() {
      // Skip if date format already set
      if (!this.dateUpdated) {
         this.setDateFormat('dd/MM/yyyy'); //SPECIFY THE DATE FORMAT HERE
      }
   }
}
