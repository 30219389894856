import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './_services/auth.service';
import { User } from './_models/user';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, RouterEvent } from '@angular/router';
import { NotifyService } from 'src/app/_services/notify.service';
import { Subscription } from 'rxjs';
import { Notify } from './_models/notify';
import { NotificationService } from '@progress/kendo-angular-notification';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styles: [
      `
         .notification-online {
            padding: 10px 5px;
            color: green;
         }
         .notification-offline {
            padding: 10px 5px;
            color: red;
         }
      `,
   ],
})
export class AppComponent implements OnInit, OnDestroy {
   loadingApp = true;
   jwtHelper = new JwtHelperService();
   isConnected = true;
   private subscription!: Subscription;
   @ViewChild('appendTo', { read: ViewContainerRef })
   public appendTo!: ViewContainerRef;
   constructor(private notifyService: NotifyService, private authService: AuthService, private notification: NotificationService, private router: Router) {
      router.events.subscribe((routerEvent: RouterEvent) => {
         this.checkRouterEvent(routerEvent);
      });
   }
   ngOnInit(): void {
      const token = localStorage.getItem('token');
      const user: User = JSON.parse(localStorage.getItem('user'));
      if (token) {
         this.authService.decodedToken = this.jwtHelper.decodeToken(token);

         if (user) {
            this.authService.currentUser = user;
         }
      }

      //Notification code
      this.subscription = this.notifyService.notifySubject.subscribe((_notify: Notify) => {
         if (_notify.type === 'success') {
            this.notification.show({
               appendTo: this.appendTo,
               content: _notify.content,
               cssClass: 'ams-notification',
               hideAfter: 3500,
               position: { horizontal: 'center', vertical: 'bottom' },
               animation: { type: 'fade', duration: 400 },
               type: { style: 'success', icon: true },
            });
         } else if (_notify.type === 'error') {
            this.notification.show({
               appendTo: this.appendTo,
               content: _notify.content,
               cssClass: 'ams-notification',
               position: { horizontal: 'center', vertical: 'top' },
               animation: { type: 'fade', duration: 400 },
               type: { style: 'error', icon: true },
               closable: true,
            });
         } else if (_notify.type === 'warning') {
            this.notification.show({
               appendTo: this.appendTo,
               content: _notify.content,
               cssClass: 'ams-notification',
               hideAfter: 2200,
               position: { horizontal: 'center', vertical: 'top' },
               animation: { type: 'fade', duration: 400 },
               type: { style: 'warning', icon: true },
               closable: true,
            });
         } else if (_notify.type === 'info') {
            this.notification.show({
               appendTo: this.appendTo,
               content: _notify.content,
               cssClass: 'ams-notification',
               hideAfter: 3500,
               position: { horizontal: 'center', vertical: 'bottom' },
               animation: { type: 'fade', duration: 400 },
               type: { style: 'info', icon: true },
            });
         }
      });
   }
   checkRouterEvent(routerEvent: RouterEvent): void {
      if (routerEvent instanceof NavigationStart) {
         this.loadingApp = true;
      }

      if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
         this.loadingApp = false;
      }
   }
   ngOnDestroy() {
      this.subscription.unsubscribe();
   }
   loggedIn() {
      return this.authService.loggedIn();
   }
}
