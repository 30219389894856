import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
   constructor(private authService: AuthService, private routerService: Router) {}
   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
         catchError((error) => {
            if (error instanceof HttpErrorResponse) {
               if (error.status === 401) {
                  if (this.authService.loggedIn) {
                     return throwError('Invalid Username or Password');
                  } else {
                     localStorage.removeItem('token');
                     localStorage.removeItem('user');
                     this.authService.decodedToken = null;
                     this.authService.currentUser = null;
                     this.routerService.navigate(['/login']);
                  }
               }
               if (error.status === 403) {
                  return throwError('You are not authorized to perform this action');
               }
               const serverError = error.error ? error.error : error;
               let modalStateErrors = '';

               if (serverError instanceof Blob) {
                  return new Promise<any>((resolve, reject) => {
                     let reader = new FileReader();
                     reader.onload = (e: Event) => {
                        reject((<any>e.target).result);
                     };
                     reader.onerror = (e) => {
                        reject(serverError);
                     };
                     reader.readAsText(serverError);
                  });
               } else {
                  if (Array.isArray(serverError)) {
                     // We know we have a list
                     for (const key in serverError) {
                        if (serverError[key]) {
                           if (serverError[key].description) {
                              modalStateErrors += serverError[key].description + '\n';
                           } else {
                              modalStateErrors += serverError[key] + '\n';
                           }
                        }
                     }
                  } else {
                     if (serverError.errors && typeof serverError.errors === 'object') {
                        for (const key in serverError.errors) {
                           if (serverError.errors[key]) {
                              if (serverError.errors[key].description) {
                                 modalStateErrors += serverError.errors[key].description + '\n';
                              } else {
                                 modalStateErrors += serverError.errors[key] + '\n';
                              }
                           }
                        }
                     } else {
                        if (serverError === 'License Expired') {
                           localStorage.removeItem('token');
                           localStorage.removeItem('user');
                           this.authService.decodedToken = null;
                           this.authService.currentUser = null;
                           this.routerService.navigate(['/login']);
                        }
                     }
                  }

                  const ApplicationError = error.headers.get('Application-Error');
                  if (ApplicationError) {
                     return throwError(ApplicationError);
                  }
                  return throwError(modalStateErrors || serverError || 'Server Error');
               }
            }
         })
      );
   }
}
export const ErrorInterceptorProvider = {
   provide: HTTP_INTERCEPTORS,
   useClass: ErrorInterceptor,
   multi: true,
};
