import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardNumbers } from 'src/app/_models/dashboardNumbers';
import { SmtpConfiguration } from 'src/app/_models/smtpConfiguration';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getDashboardNumbers(): Observable<DashboardNumbers> {
    return this.http.get<DashboardNumbers>(this.baseUrl + 'dashboard/numbers');
  }

  getDashboardSmtp(): Observable<SmtpConfiguration> {
    return this.http.get<SmtpConfiguration>(this.baseUrl + 'dashboard/smtp');
  }
 
}
