import { Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { LayoutComponent } from './_layout/layout.component';
import { LoginComponent } from './_login/login.component';
import { ReportComponent } from './_reportViewer/report/report.component';
import { ReportParameterComponent } from './_reportViewer/report/report-parameter/report-parameter.component';
import { RegisterComponent } from './_register/register.component';
import { ReportDateParameterComponent } from './_reportViewer/report/report-date-parameter/report-date-parameter.component';
import { ReportJournalApprovalComponent } from './_reportViewer/report/report-journal-approval/report-journal-approval.component';
import { ReportReconciliationOverviewByAccountComponent } from './_reportViewer/report/report-reconciliation-overview-by-account/report-reconciliation-overview-by-account.component';
import { ReportRequisitionComponent } from './_reportViewer/report/report-requisition/report-requisition.component';
export const routes: Routes = [
  { path: '', component: LayoutComponent, canActivate: [AuthGuard] },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'reports/:type', component: ReportComponent },
      { path: 'reports/:type/:reportId', component: ReportParameterComponent },
      { path: 'reports/date/:type/:fromDate/:toDate', component: ReportDateParameterComponent },
      { path: 'reports/:type/:registerDescription/:approvedById/:approvalDate/:workflowTransactionId', component: ReportJournalApprovalComponent },
      { path: 'reports/:type/:reportId/:workflowTransactionId', component: ReportRequisitionComponent },
      { path: 'reports/Reconciliation/:financialYearEnd/:reportMonth/:monthStart/:monthEnd/:reconVal', component: ReportReconciliationOverviewByAccountComponent },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule),
      },
      {
        path: 'setup',
        loadChildren: () => import('./setup/setup.module').then(mod => mod.SetupModule),
      },
      {
        path: 'setupclass',
        loadChildren: () => import('./setupClass/setupClass.module').then(mod => mod.SetupClassModule),
      },
      {
        path: 'assetmanagement',
        loadChildren: () => import('./assetManagement/assetManagement.module').then(mod => mod.AssetManagementModule),
      },
      {
        path: 'rfid',
        loadChildren: () => import('./rfid/rfid.module').then(mod => mod.RfidModule),
      },
      {
        path: 'integration',
        loadChildren: () => import('./integration/integration.module').then(mod => mod.IntegrationModule),
      },
      {
        path: 'monitoring',
        loadChildren: () => import('./monitoring/monitoring.module').then(mod => mod.MonitoringModule),
      },
      {
        path: 'wip',
        loadChildren: () => import('./wip/wip.module').then(mod => mod.WipModule),
      },
      {
        path: 'financialTransaction',
        loadChildren: () => import('./financialTransaction/financialTransaction.module').then(mod => mod.FinancialTransactionModule),
      },
      {
        path: 'conditionAssesment',
        loadChildren: () => import('./conditionAssesment/conditionAssesment.module').then(mod => mod.ConditionAssesmentModule),
      },
      {
        path: 'scan',
        loadChildren: () => import('./scan/scan.module').then(mod => mod.ScanModule),
      },
      {
        path: 'workflow',
        loadChildren: () => import('./workflow/workflow.module').then(mod => mod.WorkflowModule),
      },
      {
        path: 'gis',
        loadChildren: () => import('./gis/gis.module').then(mod => mod.GisModule),
      },
      {
        path: 'help',
        loadChildren: () => import('./help/help.module').then(mod => mod.HelpModule),
      },
      {
        path: 'maintenance',
        loadChildren: () => import('./maintenance/maintenance.module').then(mod => mod.MaintenanceModule),
      },
      {
        path: 'insurance',
        loadChildren: () => import('./insurance/insurance.module').then(mod => mod.InsuranceModule),
      },
      {
        path: 'bookingManagement',
        loadChildren: () => import('./bookingManagement/bookingManagement.module').then(mod => mod.BookingManagementModule),
      },
      {
        path: 'warranty',
        loadChildren: () => import('./warranty/warranty.module').then(mod => mod.WarrantyModule),
      },
      {
        path: 'leaseManagement',
        loadChildren: () => import('./leaseManagement/lease-management.module').then(mod => mod.LeaseManagementModule),
      },
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  // Not found
  { path: '**', redirectTo: 'home' }
];
