import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DepreciationRun } from 'src/app/_models/depreciationRun';
import { DepreciationAudit } from 'src/app/_models/depreciationAudit';

@Injectable({
   providedIn: 'root',
})
export class DepreciationService {
   baseUrl = environment.apiUrl;
   constructor(private http: HttpClient) {}
   getDepreciation(): Observable<DepreciationRun> {
      return this.http.get<DepreciationRun>(this.baseUrl + 'depreciation');
   }
   runDepreciation(depreciationRun: DepreciationRun) {
      return this.http.put(this.baseUrl + 'depreciation', depreciationRun);
   }
   approveDepreciation() {
      return this.http.put(this.baseUrl + 'depreciation/approve', '');
   }
   rollbackDepreciation() {
      return this.http.put(this.baseUrl + 'depreciation/rollback', '');
   }
   getDepreciationAuditById(id: number): Observable<DepreciationAudit> {
      return this.http.get<DepreciationAudit>(this.baseUrl + 'depreciation/audit/' + id);
   }
   export(): Observable<Blob> {
      return this.http.get(this.baseUrl + 'depreciation/export', {
         responseType: 'blob',
      });
   }
   exportProductionOfUnit(): Observable<Blob> {
      return this.http.get(this.baseUrl + 'depreciation/export/productionofunit', {
         responseType: 'blob',
      });
   }
   exportForecast(BeginDate: string, EndDate: string, IsGlClassification: boolean, ExcludePriorDisposals: boolean, ExcludeExpenseItems: boolean): Observable<Blob> {
      // tslint:disable-next-line:max-line-length
      return this.http.get(
         this.baseUrl +
            'assetregister/exportforecast?BeginDate=' +
            BeginDate +
            '&EndDate=' +
            EndDate +
            '&IsGlClassification=' +
            IsGlClassification +
            '&ExcludePriorDisposals=' +
            ExcludePriorDisposals +
            '&ExcludeExpense=' +
            ExcludeExpenseItems,
         {
            responseType: 'blob',
         }
      );
   }
}
