import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { User } from '../../_models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl = environment.apiUrl;
  userSelected = new Subject<User>();

  passwordSelected = new Subject<User>();
  AvatarSelected = new Subject<User>();
  AvatarChanged = new Subject<string>();

  constructor(private http: HttpClient) {}

  changeEditState(user: User) {
    this.userSelected.next(user);
  }
  changePasswordResetState(user: User) {
    this.passwordSelected.next(user);
  }
  changeAvatarState(user: User) {
    this.AvatarSelected.next(user);
  }
  avatarChangedState(url: string) {
    this.AvatarChanged.next(url);
  }

  getUsersFull(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'user/list/full');
  }
  getAssetManagers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'user/list/assetmanagers');
  }
  getLossControlOfficers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'user/list/losscontrolofficers');
  }
  getAssetChampions(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'user/list/assetchampions');
  }
  getDepartmentHeads(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'user/list/departmentHeads');
  }
  getMaintenanceManagers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'user/list/maintenancemanagers');
  }
  getUser(id): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'user/' + id);
  }
  updateUser(id: number, user: User) {
    return this.http.put(this.baseUrl + 'user/' + id, user);
  }
  updateProfile(id: number, user: User) {
    return this.http.put(this.baseUrl + 'user/profile/' + id, user);
  }
  resetPassword(user: User) {
    return this.http.put(this.baseUrl + 'user/resetpassword', user);
  }
  createUser(user: User) {
    return this.http.post(this.baseUrl + 'user', user);
  }

}
