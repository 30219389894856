import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { User } from '../_models/user';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { UserService } from '../admin/_services/user.service';
import { SettingService } from '../admin/_services/setting.service';
import { Setting } from '../_models/setting';
import { RoleService } from '../admin/_services/role.service';
import { Role } from '../_models/role';
import { forEachChild } from 'typescript';

@Component({
   selector: 'app-nav',
   templateUrl: './nav.component.html',
   styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
   user: User;
   roles: Role[];
   baseUrl = environment.apiUrl;
   profilePic: string;
   integrateWith = false;
   showMenu = true;

   constructor(
      private authService: AuthService,
      private routerService: Router,
      private userService: UserService,
      private settingService: SettingService,
      private el: ElementRef,
      private renderer: Renderer2
   ) {
      window.addEventListener('message', this.handlePortalMessage.bind(this));
   }

   private handlePortalMessage(event: MessageEvent) {
      var navUrl = event.data.payload.url.substring(2);

      if (navUrl == '/menu' && !this.showMenu) {
         navUrl = '/';
         this.showMenu = true;
         var menuArray = [];
         menuArray.push({
            caption: 'Use Portal Menu',
            icon: 'far fa-fw fa-cogs',
            url: '/menu',
            children: [],
         });
         menuArray.push({
            caption: 'Home',
            icon: 'far fa-fw fa-home',
            url: '/',
            children: [],
         });
         const jsonResult = { type: 'setMenu', payload: { menu: menuArray } };
         window.parent.postMessage(jsonResult, '*');
         this.routerService.navigate([navUrl]);
         return;
      }

      if (navUrl == '/menu' && this.showMenu) {
         navUrl = '/';
         this.showMenu = false;
         this.setupMenuForPortal();
         this.routerService.navigate([navUrl]);
         return;
      }

      this.routerService.navigate([navUrl]);
   }

   ngOnInit() {
      this.user = this.authService.currentUser;

      // if (self != top) {    ---------------- Disabled portal menu ----------------
      //    // this.showMenu = false; 
      //    // this.setupMenuForPortal();
      // }

      this.profilePic = 'user/photo/' + this.user.id + '?' + new Date().getTime();

      this.settingService.getSetting('INTEGRATEWITH').subscribe((settingData: Setting) => {
         if (settingData.settingValue === 'Normal') this.integrateWith = true;
      });

      this.userService.AvatarChanged.subscribe((s: string) => {
         if (s) {
            this.profilePic = 'user/photo/' + this.user.id + '?' + new Date().getTime();
            localStorage.setItem('user', JSON.stringify(this.user));
         } else {
            this.profilePic = this.authService.currentUser.url;
         }
      });
   }
   closeLogoEdit(s: string): void {
      if (s) {
         this.profilePic = 'user/photo/' + this.user.id + '?' + new Date().getTime();
      }
   }
   logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.authService.decodedToken = null;
      this.authService.currentUser = null;
      this.routerService.navigate(['/login']);
   }

   setupMenuForPortal() {
      window.parent.postMessage(this.parseMenu(), '*');
   }

   parseMenu(): any {
      var menuArray = [];
      var childItemArrayA = [];
      var childItemArrayB = [];
      var childItemArrayC = [];

      menuArray.push({
         caption: 'Use Integrated Menu',
         icon: 'far fa-fw fa-cogs',
         url: '/menu',
         children: [],
      });

      menuArray.push({
         caption: 'Home',
         icon: 'far fa-fw fa-home',
         url: '/',
         children: [],
      });

      if (this.authService.roleMatch(['SYSTEM-ADMIN'])) {
         menuArray.push({
            caption: 'Settings',
            icon: 'far fa-fw fa-cog',
            url: '/admin/setting',
            children: [],
         });
      }

      // ============================================================ Admin ============================================================
      if (this.authService.roleMatch([
         'SYSTEM-ADMIN',
         'ADMIN-USERS-VIEW',
         'ADMIN-ROLES-VIEW',
         'ADMIN-SYSTEM_ERRORS-VIEW',
         'ADMIN-REPORTS-VIEW',
         'ADMIN-RELEASE_NOTES-VIEW'
      ])) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ADMIN-USERS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Users',
               icon: 'far fa-fw fa-user',
               url: '/admin/user',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ADMIN-ROLES-VIEW'])) {
            childItemArrayA.push({
               caption: 'Roles',
               icon: 'far fa-fw fa-users',
               url: '/admin/role',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ADMIN-SYSTEM_ERRORS-VIEW', 'ADMIN-SYSTEM_ERRORS-PRINTVIEW'])) {
            childItemArrayA.push({
               caption: 'System Errors',
               icon: 'far fa-fw fa-exclamation-circle',
               url: '/admin/errorlog',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN'])) {
            childItemArrayA.push({
               caption: 'Audit Log',
               icon: 'far fa-fw fa-pen',
               url: '/admin/auditlog',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ADMIN-REPORTS-VIEW', 'ADMIN-REPORTS-PRINTVIEW'])) {
            childItemArrayA.push({
               caption: 'Reports',
               icon: 'far fa-fw fa-print',
               url: '/admin/reportdashboard',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ADMIN-RELEASE_NOTES-VIEW', 'ADMIN-RELEASE_NOTES-PRINTVIEW'])) {
            childItemArrayA.push({
               caption: 'Release Notes',
               icon: 'far fa-fw fa-clipboard',
               url: '/admin/releasenotes',
            });
         }
         menuArray.push({
            caption: 'Admin',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayA,
         });
         childItemArrayA = [];
      }

      // ============================================================ Setup ============================================================
      if (this.authService.roleMatch([
         'SYSTEM-ADMIN',
         'SETUP-GENERAL-BUSINESS_AREA-VIEW',
         'SETUP-GENERAL-COST_CENTRE-VIEW',
         'SETUP-GENERAL-DIMENSIONS-VIEW',
         'SETUP-GENERAL-SUPPLIER-VIEW',
         'SETUP-LOCATION-SITE-VIEW',
         'SETUP-LOCATION-BUILDING-VIEW',
         'SETUP-LOCATION-ROOM-VIEW',
      ])) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'SETUP-GENERAL-BUSINESS_AREA-VIEW'])) {
            childItemArrayA.push({
               caption: 'Business Area',
               icon: 'far fa-fw fa-clone',
               url: '/setup/businessarea',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'SETUP-GENERAL-COST_CENTRE-VIEW'])) {
            childItemArrayA.push({
               caption: 'Cost Centre',
               icon: 'far fa-fw fa-coins',
               url: '/setup/costcentre',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'SETUP-GENERAL-DIMENSIONS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Dimensions',
               icon: 'far fa-fw fa-cubes',
               url: '/setup/dimension',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'SETUP-GENERAL-SUPPLIER-VIEW'])) {
            childItemArrayA.push({
               caption: 'Supplier',
               icon: 'far fa-fw fa-hand-holding-usd',
               url: '/setup/supplier',
            });
         }

         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'SETUP-LOCATION-SITE-VIEW'])) {
            childItemArrayB.push({
               caption: 'Sites',
               icon: 'far fa-fw fa-flag',
               url: '/setup/site',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'SETUP-LOCATION-BUILDING-VIEW'])) {
            childItemArrayB.push({
               caption: 'Buildings',
               icon: 'far fa-fw fa-building',
               url: '/setup/building',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'SETUP-LOCATION-ROOM-VIEW'])) {
            childItemArrayB.push({
               caption: 'Rooms',
               icon: 'far fa-fw fa-home',
               url: '/setup/room',
            });
         }

         menuArray.push({
            caption: 'Setup',
            icon: 'far fa-fw fa-folder',
            children: [
               {
                  caption: 'General Setup',
                  icon: 'far fa-fw fa-folder',
                  children: childItemArrayA,
               },
               {
                  caption: 'Location Setup',
                  icon: 'far fa-fw fa-folder',
                  children: childItemArrayB,
               },
            ],
         });
         childItemArrayA = [];
         childItemArrayB = [];
      }

      // ============================================================ Classification Setup ============================================================
      if (this.authService.roleMatch([
         'SYSTEM-ADMIN',
         'CLASSIFICATION_SETUP-CLASSIFICATIONS-VIEW',
         'CLASSIFICATION_SETUP-ACCOUNTS-VIEW',
         'CLASSIFICATION_SETUP-ASSET_GROUP-VIEW',
         'CLASSIFICATION_SETUP-ASSET_TYPE-VIEW',
         'CLASSIFICATION_SETUP-COMPONENT_TYPE-VIEW',
         'CLASSIFICATION_SETUP-DESCRIPTOR_TYPE-VIEW',
         'CLASSIFICATION_SETUP-ATTRIBUTES-VIEW',
         'CLASSIFICATION_SETUP-GL_ACCOUNTS-VIEW',
         'CLASSIFICATION_SETUP-WAT-WAT_TYPE-VIEW',
         'CLASSIFICATION_SETUP-WAT-WAT_VERSION-VIEW',
         'CLASSIFICATION_SETUP-WAT-WAT_REGISTER-VIEW',
         'CLASSIFICATION_SETUP-WAT-WAT_REGISTER-PRINTVIEW',
      ])) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-ASSET_GROUP-VIEW'])) {
            childItemArrayA.push({
               caption: 'Asset Group',
               icon: 'far fa-fw fa-cogs',
               url: '/setupclass/assetgroup',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-ASSET_TYPE-VIEW'])) {
            childItemArrayA.push({
               caption: 'Asset Type',
               icon: 'far fa-fw fa-tools',
               url: '/setupclass/assettype',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-COMPONENT_TYPE-VIEW'])) {
            childItemArrayA.push({
               caption: 'Component Type',
               icon: 'far fa-fw fa-cogs',
               url: '/setupclass/componenttype',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-DESCRIPTOR_TYPE-VIEW'])) {
            childItemArrayA.push({
               caption: 'Descriptor Type',
               icon: 'far fa-fw fa-atom',
               url: '/setupclass/descriptortype',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-ATTRIBUTES-VIEW'])) {
            childItemArrayA.push({
               caption: 'Attributes',
               icon: 'far fa-fw fa-cogs',
               url: '/setupclass/attribute',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-GL_ACCOUNTS-VIEW'])) {
            childItemArrayA.push({
               caption: 'GL Accounts',
               icon: 'far fa-fw fa-cogs',
               url: '/setupclass/account',
            });
         }

         menuArray.push({
            caption: 'Classification Setup',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayA,
         });
         childItemArrayA = [];
      }

      // ============================================================ Asset Management ============================================================
      if (this.authService.roleMatch([
         'SYSTEM-ADMIN',
         'ASSET_MNGT-REGISTER-FAR_EXPORT',
         'ASSET_MNGT-REGISTER-JOURNAL_INTEGRATION-VIEW',
         'ASSET_MNGT-REGISTER-CORRECTION_JOURNAL-VIEW',
         'ASSET_MNGT-REGISTER-3RD_PARTY_EXPORT',
         'ASSET_MNGT-DIMENSION_CHANGES-VIEW',
         'ASSET_MNGT-COST_CENTRE_CHANGES-VIEW',
         'ASSET_MNGT-CLASSIFICATION_CHANGE-VIEW',
         'ASSET_MNGT-BARCODE_REPLACEMENT-VIEW',
         'ASSET_MNGT-TRANSACTION_HISTORY_REPORT-VIEW',
      ])) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-REGISTER-FAR_EXPORT', 'ASSET_MNGT-REGISTER-JOURNAL_INTEGRATION-VIEW'])) {
            childItemArrayA.push({
               caption: 'Asset Register',
               icon: 'far fa-fw fa-chart-line',
               url: '/assetmanagement/asset/register',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-REGISTER-JOURNAL_INTEGRATION-VIEW', 'ASSET_MNGT-REGISTER-CORRECTION_JOURNAL-VIEW'])) {
            childItemArrayA.push({
               caption: 'Correction Journals',
               icon: 'far fa-fw fa-check-circle',
               url: '/assetmanagement/asset/correctionjournalintegration',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-REGISTER-3RD_PARTY_EXPORT'])) {
            childItemArrayA.push({
               caption: '3rd Party Exports',
               icon: 'far fa-fw fa-cogs',
               url: '/assetmanagement/asset/exportthirdparty',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-DIMENSION_CHANGES-VIEW'])) {
            childItemArrayA.push({
               caption: 'Dimension Change',
               icon: 'far fa-fw fa-layer-group',
               url: '/assetmanagement/asset/dimensionchange',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-COST_CENTRE_CHANGES-VIEW'])) {
            childItemArrayA.push({
               caption: 'Cost Centre Change',
               icon: 'far fa-fw fa-coins',
               url: '/assetmanagement/asset/costcentrechange',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-CLASSIFICATION_CHANGE-VIEW'])) {
            childItemArrayA.push({
               caption: 'Classification Change',
               icon: 'far fa-fw fa-cogs',
               url: '/assetmanagement/asset/classificationchange',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-BARCODE_REPLACEMENT-VIEW'])) {
            childItemArrayA.push({
               caption: 'Barcode Replacement',
               icon: 'far fa-fw fa-barcode',
               url: '/assetmanagement/asset/barcodereplacement',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-TRANSACTION_HISTORY_REPORT-VIEW'])) {
            childItemArrayA.push({
               caption: 'Transaction History',
               icon: 'far fa-fw fa-history',
               url: '/assetmanagement/asset/transactionhistory',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-GROUPS-GRP-CANCEL'])) {
            childItemArrayA.push({
               caption: 'Remove Bulk Assets',
               icon: 'far fa-fw fa-trash',
               url: '/assetmanagement/asset/removebulk',
            });
         }

         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-GROUPS-GRP-VIEW'])) {
            childItemArrayB.push({
               caption: 'General',
               icon: 'far fa-fw fa-cogs',
               url: 'assetmanagement/asset/general/GENERAL',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-GROUPS-GRP-VIEW'])) {
            childItemArrayB.push({
               caption: 'Vehicle',
               icon: 'far fa-fw fa-truck',
               url: 'assetmanagement/asset/vehicle/VEHICLE',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-GROUPS-GRP-VIEW'])) {
            childItemArrayB.push({
               caption: 'Road',
               icon: 'far fa-fw fa-road',
               url: '/assetmanagement/asset/road/ROAD',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-GROUPS-GRP-VIEW'])) {
            childItemArrayB.push({
               caption: 'Land',
               icon: 'far fa-fw fa-tree',
               url: '/assetmanagement/asset/land/LAND',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-GROUPS-GRP-VIEW'])) {
            childItemArrayB.push({
               caption: 'Building',
               icon: 'far fa-fw fa-building',
               url: '/assetmanagement/asset/building/BUILDING',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-GROUPS-GRP-VIEW'])) {
            childItemArrayB.push({
               caption: 'Water',
               icon: 'far fa-fw fa-bath',
               url: '/assetmanagement/asset/water/WATER',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-GROUPS-GRP-VIEW'])) {
            childItemArrayB.push({
               caption: 'Storm Water',
               icon: 'far fa-fw fa-water',
               url: '/assetmanagement/asset/stormwater/STORMWATER',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-GROUPS-GRP-VIEW'])) {
            childItemArrayB.push({
               caption: 'Sanitation',
               icon: 'far fa-fw fa-toilet-paper',
               url: '/assetmanagement/asset/sanitation/SANITATION',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-GROUPS-GRP-VIEW'])) {
            childItemArrayB.push({
               caption: 'Electricity',
               icon: 'far fa-fw fa-lightbulb',
               url: '/assetmanagement/asset/electricity/ELECTRICITY',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ASSET_MNGT-GROUPS-GRP-VIEW'])) {
            childItemArrayB.push({
               caption: 'Railway',
               icon: 'far fa-fw fa-train',
               url: '/assetmanagement/asset/railway/RAILWAY',
            });
         }

         childItemArrayA.push({
            caption: 'Groups',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayB,
         });

         menuArray.push({
            caption: 'Asset Management',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayA
         });

         childItemArrayA = [];
         childItemArrayB = [];
      }

      // ============================================================ Transactions ============================================================
      if (this.authService.roleMatch([
         'SYSTEM-ADMIN',
         'TRANSACTIONS-MONTHEND_YEAREND-RUN_MONTH_END-RUN',
         'TRANSACTIONS-MONTHEND_YEAREND-RUN_MONTH_END-APPROVE',
         'TRANSACTIONS-MONTHEND_YEAREND-RUN_MONTH_END-ROLLBACK',
         'TRANSACTIONS-MONTH_END_REPORT-VIEW',
         'TRANSACTIONS-MONTH_END_REPORT-PRINTVIEW',
         'TRANSACTIONS-RECONCILLIATIONS-VIEW',
         'TRANSACTIONS-RECONCILLIATIONS-PRINTVIEW',
         'TRANSACTIONS-DEPRECIATION-RUN_MONTHLY_DEP-RUN',
         'TRANSACTIONS-DEPRECIATION-PROD_OF_UNIT-VIEW',
         'TRANSACTIONS-DEPRECIATION-FORECAST-EXPORT',
         'TRANSACTIONS-TRANSACTIONS-ACQUISITIONS-VIEW',
         'TRANSACTIONS-TRANSACTIONS-DISPOSALS-VIEW',
         'TRANSACTIONS-TRANSACTIONS-RUL_CHANGE-VIEW',
         'TRANSACTIONS-TRANSACTIONS-RESIDUAL_CHANGE-VIEW',
         'TRANSACTIONS-TRANSACTIONS-UPGRADES-VIEW',
         'TRANSACTIONS-TRANSACTIONS-IMPAIRMENTS-VIEW',
         'TRANSACTIONS-TRANSACTIONS-REVALUATION-VIEW',
         'TRANSACTIONS-TRANSACTIONS-ADJUSTMENTS-VIEW',
         'TRANSACTIONS-TRANSACTIONS-JOURNAL_ADJUSTMENTS-VIEW',
         'TRANSACTIONS-REPORTS-VIEW',
         'TRANSACTIONS-REPORTS-PRINTVIEW',
      ])) {
         if (this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'TRANSACTIONS-MONTHEND_YEAREND-RUN_MONTH_END-RUN',
            'TRANSACTIONS-MONTHEND_YEAREND-RUN_MONTH_END-APPROVE',
            'TRANSACTIONS-MONTHEND_YEAREND-RUN_MONTH_END-ROLLBACK',
            'TRANSACTIONS-MONTH_END_REPORT-VIEW',
            'TRANSACTIONS-MONTH_END_REPORT-PRINTVIEW',
         ])) {
            childItemArrayA.push({
               caption: 'Month End',
               icon: 'far fa-fw fa-running',
               url: '/financialTransaction/monthend',
            });
         }
         if (this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'TRANSACTIONS-DEPRECIATION-RUN_MONTHLY_DEP-RUN',
            'TRANSACTIONS-DEPRECIATION-PROD_OF_UNIT-VIEW',
            'TRANSACTIONS-DEPRECIATION-FORECAST-EXPORT',
         ])) {
            childItemArrayA.push({
               caption: 'Reconciliations',
               icon: 'far fa-fw fa-balance-scale',
               url: '/financialTransaction/reconciliation',
            });
         }

         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'TRANSACTIONS-DEPRECIATION-RUN_MONTHLY_DEP-RUN'])) {
            childItemArrayB.push({
               caption: 'Run Monthly',
               icon: 'far fa-fw fa-running',
               url: '/financialTransaction/depreciation',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'TRANSACTIONS-DEPRECIATION-PROD_OF_UNIT-VIEW'])) {
            childItemArrayB.push({
               caption: 'Production Of Unit',
               icon: 'far fa-fw fa-truck-moving',
               url: '/financialTransaction/depreciation/productionofunit',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'TRANSACTIONS-DEPRECIATION-FORECAST-EXPORT'])) {
            childItemArrayB.push({
               caption: 'Forecast',
               icon: 'far fa-fw fa-chart-line',
               url: '/financialTransaction/depreciationforecast',
            });
         }

         childItemArrayC.push({
            caption: 'Month/Year End',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayA,
         });
         childItemArrayC.push({
            caption: 'Depreciation',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayB,
         });
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-ACQUISITIONS-VIEW'])) {
            childItemArrayC.push({
               caption: 'Acquisitions',
               icon: 'far fa-fw fa-credit-card',
               url: '/financialTransaction/acquisition',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-DISPOSALS-VIEW'])) {
            childItemArrayC.push({
               caption: 'Disposals',
               icon: 'far fa-fw fa-recycle',
               url: '/financialTransaction/disposal',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-RUL_CHANGE-VIEW'])) {
            childItemArrayC.push({
               caption: 'Rul Changes',
               icon: 'far fa-fw fa-hourglass',
               url: '/financialTransaction/rulchange',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-RESIDUAL_CHANGE-VIEW'])) {
            childItemArrayC.push({
               caption: 'Residual Changes',
               icon: 'far fa-fw fa-coins',
               url: '/financialTransaction/residualchange',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-UPGRADES-VIEW'])) {
            childItemArrayC.push({
               caption: 'Upgrades',
               icon: 'far fa-fw fa-redo',
               url: '/financialTransaction/upgrade',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-IMPAIRMENTS-VIEW'])) {
            childItemArrayC.push({
               caption: 'Impairments',
               icon: 'far fa-fw fa-hand-point-down',
               url: '/financialTransaction/impairment',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-REVALUATION-VIEW'])) {
            childItemArrayC.push({
               caption: 'Revaluations',
               icon: 'far fa-fw fa-money-bill-wave',
               url: '/financialTransaction/revaluation',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'TRANSACTIONS-TRANSACTIONS-ADJUSTMENTS-VIEW'])) {
            childItemArrayC.push({
               caption: 'Adjustments',
               icon: 'far fa-fw fa-adjust',
               url: '/financialTransaction/adjustment',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'TRANSACTIONS-REPORTS-VIEW', 'TRANSACTIONS-REPORTS-PRINTVIEW'])) {
            childItemArrayC.push({
               caption: 'Reports',
               icon: 'far fa-fw fa-print',
               url: '/financialTransaction/reportdashboard',
            });
         }

         menuArray.push({
            caption: 'Transactions',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayC,
         });

         childItemArrayA = [];
         childItemArrayB = [];
         childItemArrayC = [];
      }

      // ============================================================ Wear and Tear ============================================================
      if (this.authService.roleMatch([
         'SYSTEM-ADMIN',
         'CLASSIFICATION_SETUP-WAT-WAT_TYPE-VIEW',
         'CLASSIFICATION_SETUP-WAT-WAT_VERSION-VIEW',
         'CLASSIFICATION_SETUP-WAT-WAT_REGISTER-VIEW',
         'CLASSIFICATION_SETUP-WAT-WAT_REGISTER-PRINTVIEW',
      ])) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-WAT-WAT_TYPE-VIEW'])) {
            childItemArrayA.push({
               caption: 'Type',
               icon: 'far fa-fw fa-clone',
               url: '/setupclass/wat-type',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-WAT-WAT_VERSION-VIEW'])) {
            childItemArrayA.push({
               caption: 'Version',
               icon: 'far fa-fw fa-coins',
               url: '/setupclass/wat-version',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-WAT-WAT_REGISTER-VIEW', 'CLASSIFICATION_SETUP-WAT-WAT_REGISTER-PRINTVIEW'])) {
            childItemArrayA.push({
               caption: 'WAT Register',
               icon: 'far fa-fw fa-chart-line',
               url: '/setupclass/wat-register',
            });
         }
         menuArray.push({
            caption: 'Wear and Tear',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayA,
         });
         childItemArrayA = [];
      }

      // ============================================================ Work in Progress ============================================================
      if (this.authService.roleMatch([
         'SYSTEM-ADMIN',
         'WIP-SETUP-ENGINEERS-VIEW',
         'WIP-SETUP-CONTRACTORS-VIEW',
         'WIP-SETUP-FUND-VIEW',
         'WIP-CHECK_LIST-QUESTIONS-VIEW',
         'WIP-CHECK_LIST-TEMPLATE-VIEW',
         'WIP-WIP-PROJECT-VIEW',
         'WIP-WIP-TRANSACTIONS-VIEW',
         'WIP-WIP-ALLOCATION_ADJUSTMENTS-VIEW',
         'WIP-MASTER_ASSETS-COMPONENTS-VIEW',
         'WIP-WIP-REPORTS-VIEW',
         'WIP-WIP-REPORTS-PRINTVIEW',
      ])) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WIP-SETUP-ENGINEERS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Engineers',
               icon: 'far fa-fw fa-pencil-ruler',
               url: '/wip/engineer',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WIP-SETUP-CONTRACTORS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Contractors',
               icon: 'far fa-fw fa-hard-hat',
               url: '/wip/contractor',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WIP-SETUP-FUND-VIEW'])) {
            childItemArrayA.push({
               caption: 'Fund',
               icon: 'far fa-fw fa-university',
               url: '/wip/fund',
            });
         }

         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WIP-CHECK_LIST-QUESTIONS-VIEW'])) {
            childItemArrayB.push({
               caption: 'Questions',
               icon: 'far fa-fw fa-question-circle',
               url: '/wip/question',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WIP-CHECK_LIST-TEMPLATE-VIEW'])) {
            childItemArrayB.push({
               caption: 'Checklist Template',
               icon: 'far fa-fw fa-clipboard-list',
               url: '/wip/checklisttemplate',
            });
         }

         childItemArrayA.push({
            caption: 'Checklist Setup',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayB,
         });

         childItemArrayC.push({
            caption: 'Setup',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayA,
         });

         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WIP-WIP-PROJECT-VIEW'])) {
            childItemArrayC.push({
               caption: 'Project',
               icon: 'far fa-fw fa-project-diagram',
               url: '/wip/project',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WIP-WIP-CERTIFICATES-VIEW'])) {
            childItemArrayC.push({
               caption: 'Certificate',
               icon: 'far fa-fw fa-certificate',
               url: '/wip/certificate',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WIP-WIP-TRANSACTIONS-VIEW'])) {
            childItemArrayC.push({
               caption: 'Transaction',
               icon: 'far fa-fw fa-money-bill',
               url: '/wip/transaction',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WIP-WIP-ALLOCATION_ADJUSTMENTS-VIEW'])) {
            childItemArrayC.push({
               caption: 'Allocation Adjustment',
               icon: 'far fa-fw fa-money-bill-alt',
               url: '/wip/allocationAdjustment',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WIP-MASTER_ASSETS-COMPONENTS-VIEW'])) {
            childItemArrayC.push({
               caption: 'Master Asset',
               icon: 'far fa-fw fa-truck-monster',
               url: '/wip/masterAsset',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WIP-WIP-REPORTS-VIEW', 'WIP-WIP-REPORTS-PRINTVIEW'])) {
            childItemArrayC.push({
               caption: 'Reports',
               icon: 'far fa-fw fa-project-diagram',
               url: '/wip/reportdashboard1',
            });
         }

         menuArray.push({
            caption: 'Work in Progress',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayC,
         });

         childItemArrayA = [];
         childItemArrayB = [];
         childItemArrayC = [];
      }

      // ============================================================ Lease Management ============================================================
      if (this.authService.roleMatch([
         'SYSTEM-ADMIN'
      ])) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-WAT-WAT_TYPE-VIEW'])) {
            childItemArrayA.push({
               caption: 'Lease Agreements',
               icon: 'far fa-fw fa-balance-scale',
               url: '/leaseManagement/leaseAgreement',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'CLASSIFICATION_SETUP-WAT-WAT_VERSION-VIEW'])) {
            childItemArrayA.push({
               caption: 'Lease Agreement Journals',
               icon: 'far fa-fw fa-running',
               url: '/leaseManagement/leaseAgreementJournal',
            });
         }
         menuArray.push({
            caption: 'Wear and Tear',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayA,
         });
         childItemArrayA = [];
      }

      // ============================================================ Scanned Assets ============================================================
      if (this.authService.roleMatch(['SYSTEM-ADMIN', 'SCANNED_ASSETS-SCAN_PROJECT-VIEW', 'SCANNED_ASSETS-CAS-VIEW', 'SCANNED_ASSETS-ITEMS-VIEW', 'SCANNED_ASSETS-DUMP-VIEW'])) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'SCANNED_ASSETS-SCAN_PROJECT-VIEW'])) {
            childItemArrayA.push({
               caption: 'Scan Project',
               icon: 'far fa-fw fa-project-diagram',
               url: '/scan/scanproject',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'SCANNED_ASSETS-CAS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Condition Assessment Setup',
               icon: 'far fa-fw fa-clipboard-check',
               url: '/conditionAssesment/condition',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'SCANNED_ASSETS-ITEMS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Scanned Items',
               icon: 'far fa-fw fa-tasks',
               url: '/scan/inventorylist',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'SCANNED_ASSETS-DUMP-VIEW'])) {
            childItemArrayA.push({
               caption: 'Scanned Items Dump',
               icon: 'far fa-fw fa-layer-group',
               url: '/scan/dumpinventorylist',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'SCANNED_ASSETS-REPORTS-VIEW', 'SCANNED_ASSETS-REPORTS-PRINTVIEW'])) {
            childItemArrayA.push({
               caption: 'Reports',
               icon: 'far fa-fw fa-print',
               url: '/scan/reportdashboard',
            });
         }

         menuArray.push({
            caption: 'Scanned Assets',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayA,
         });

         childItemArrayA = [];
      }

      // ============================================================ RFID Tracking ============================================================
      if (
         this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'RFID-RFID-ZONES-VIEW',
            'RFID-RFID-READERS-VIEW',
            'RFID-READ_LOG-VIEW',
            'RFID-READ_LOG-PRINTVIEW',
            'RFID-RFID-MOVEMENTS-VIEW',
            'RFID-RFID-SECURITY_MOVEMENTS-VIEW',
            'RFID-ERROR_LOGS-VIEW',
            'RFID-ERROR_LOGS-PRINTVIEW',
         ])
      ) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'RFID-RFID-ZONES-VIEW'])) {
            childItemArrayA.push({
               caption: 'Zones',
               icon: 'far fa-fw fa-tasks',
               url: '/rfid/zone',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'RFID-RFID-READERS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Readers',
               icon: 'far fa-fw fa-clone',
               url: '/rfid/reader',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'RFID-READ_LOG-VIEW', 'RFID-READ_LOG-PRINTVIEW'])) {
            childItemArrayA.push({
               caption: 'Read Log',
               icon: 'far fa-fw fa-folder',
               url: '/rfid/readlog',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'RFID-RFID-MOVEMENTS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Movements',
               icon: 'far fa-fw fa-bars',
               url: '/rfid/movement/false',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'RFID-RFID-SECURITY_MOVEMENTS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Security Movements',
               icon: 'far fa-fw fa-home',
               url: '/rfid/securitymovement/true',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'RFID-ERROR_LOGS-VIEW', 'RFID-ERROR_LOGS-PRINTVIEW'])) {
            childItemArrayA.push({
               caption: 'Error Log',
               icon: 'far fa-fw fa-exclamation-circle',
               url: '/rfid/errorlog',
            });
         }

         menuArray.push({
            caption: 'RFID Tracking',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayA,
         });

         childItemArrayA = [];
      }

      // ============================================================ Geograpical Info ============================================================
      if (
         this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'GEO_INFO-GEO_INFO-REGIONS-VIEW',
            'GEO_INFO-GEO_INFO-LAYERS-VIEW',
            'GEO_INFO-GEO_INFO-LAYERS_PER_TYPE-VIEW',
            'GEO_INFO-GEO_INFO-GEO_ASSETS-VIEW',
         ])
      ) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'GEO_INFO-GEO_INFO-REGIONS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Regions',
               icon: 'far fa-fw fa-map-pin',
               url: '/gis/region',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'GEO_INFO-GEO_INFO-LAYERS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Layers',
               icon: 'far fa-fw fa-layer-group',
               url: '/gis/layer',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'GEO_INFO-GEO_INFO-LAYERS_PER_TYPE-VIEW'])) {
            childItemArrayA.push({
               caption: 'Layers Per Type',
               icon: 'far fa-fw fa-layer-group',
               url: '/gis/layertype',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'GEO_INFO-GEO_INFO-GEO_ASSETS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Geo Assets',
               icon: 'far fa-fw fa-map-marked-alt',
               url: '/gis/map',
            });
         }

         menuArray.push({
            caption: 'Geographical Info',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayA,
         });

         childItemArrayA = [];
      }

      // ============================================================ Monitoring ============================================================
      if (
         this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'MONITORING-MONITORING-DASHBOARD-VIEW',
            'MONITORING-ACTIONS-LOST_STOLEN_ASSETS-VIEW',
            'MONITORING-ASSET_MOVEMENTS-NEW_MOVEMENTS-VIEW',
            'MONITORING-ASSET_MOVEMENTS-ARCHIVE-VIEW',
         ])
      ) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'MONITORING-MONITORING-DASHBOARD-VIEW'])) {
            childItemArrayA.push({
               caption: 'Dashboard',
               icon: 'far fa-fw fa-tachometer-alt',
               url: '/monitoring/dashboard',
            });
         }

         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'MONITORING-ACTIONS-LOST_STOLEN_ASSETS-VIEW'])) {
            childItemArrayB.push({
               caption: 'Lost/Stolen Assets',
               icon: 'far fa-fw fa-trash-alt',
               url: 'monitoring/list/loss',
            });
         }

         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'MONITORING-ASSET_MOVEMENTS-NEW_MOVEMENTS-VIEW'])) {
            childItemArrayC.push({
               caption: 'New Movements',
               icon: 'far fa-fw fa-project-diagram',
               url: '/monitoring/assetmovement',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'MONITORING-ASSET_MOVEMENTS-ARCHIVE-VIEW'])) {
            childItemArrayC.push({
               caption: 'Archive',
               icon: 'far fa-fw fa-box-open',
               url: '/monitoring/assetmovementarc',
            });
         }

         childItemArrayA.push({
            caption: 'Monitoring Actions',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayB,
         });
         childItemArrayA.push({
            caption: 'Asset Movements',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayC,
         });

         menuArray.push({
            caption: 'Monitoring',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayA
         });

         childItemArrayA = [];
         childItemArrayB = [];
         childItemArrayC = [];
      }

      // ============================================================ Maintenance ============================================================
      if (this.authService.roleMatch([
         'SYSTEM-ADMIN',
         'MAINTENANCE-MANAGER',
         'MTC-SETUP-LABOUR_RESOURCE_TYPE-CREATE',
         'MTC-SETUP-LABOUR_RESOURCE_TYPE-APPROVE',
         'MTC-SETUP-LABOUR_RESOURCE_TYPE-EDIT',
         'MTC-SETUP-LABOUR_RESOURCE_TYPE-DELETE',
         'MTC-SETUP-LABOUR_RESOURCE_TYPE-VIEW',
         'MTC-SETUP-LABOUR_RESOURCE-CREATE',
         'MTC-SETUP-LABOUR_RESOURCE-APPROVE',
         'MTC-SETUP-LABOUR_RESOURCE-EDIT',
         'MTC-SETUP-LABOUR_RESOURCE-DELETE',
         'MTC-SETUP-LABOUR_RESOURCE-VIEW',
         'MTC-SETUP-ADJUST_LABOUR_RATES-CREATE',
         'MTC-SETUP-ADJUST_LABOUR_RATES-APPROVE',
         'MTC-SETUP-ADJUST_LABOUR_RATES-EDIT',
         'MTC-SETUP-ADJUST_LABOUR_RATES-DELETE',
         'MTC-SETUP-ADJUST_LABOUR_RATES-VIEW',
         'MTC-SETUP-ASSET_RESOURCE_TYPE-CREATE',
         'MTC-SETUP-ASSET_RESOURCE_TYPE-APPROVE',
         'MTC-SETUP-ASSET_RESOURCE_TYPE-EDIT',
         'MTC-SETUP-ASSET_RESOURCE_TYPE-DELETE',
         'MTC-SETUP-ASSET_RESOURCE_TYPE-VIEW',
         'MTC-SETUP-ASSET_RESOURCES-CREATE',
         'MTC-SETUP-ASSET_RESOURCES-APPROVE',
         'MTC-SETUP-ASSET_RESOURCES-EDIT',
         'MTC-SETUP-ASSET_RESOURCES-DELETE',
         'MTC-SETUP-ASSET_RESOURCES-VIEW',
         'MTC-SETUP-ADJUST_ASSET_RATES-CREATE',
         'MTC-SETUP-ADJUST_ASSET_RATES-APPROVE',
         'MTC-SETUP-ADJUST_ASSET_RATES-EDIT',
         'MTC-SETUP-ADJUST_ASSET_RATES-DELETE',
         'MTC-SETUP-ADJUST_ASSET_RATES-VIEW',
         'MTC-SETUP-TOOL_RESOURCE_TYPE-CREATE',
         'MTC-SETUP-TOOL_RESOURCE_TYPE-APPROVE',
         'MTC-SETUP-TOOL_RESOURCE_TYPE-EDIT',
         'MTC-SETUP-TOOL_RESOURCE_TYPE-DELETE',
         'MTC-SETUP-TOOL_RESOURCE_TYPE-VIEW',
         'MTC-SETUP-TOOL_RESOURCES-CREATE',
         'MTC-SETUP-TOOL_RESOURCES-APPROVE',
         'MTC-SETUP-TOOL_RESOURCES-EDIT',
         'MTC-SETUP-TOOL_RESOURCES-DELETE',
         'MTC-SETUP-TOOL_RESOURCES-VIEW',
         'MTC-SETUP-ADJUST_TOOL_RATES-CREATE',
         'MTC-SETUP-ADJUST_TOOL_RATES-APPROVE',
         'MTC-SETUP-ADJUST_TOOL_RATES-EDIT',
         'MTC-SETUP-ADJUST_TOOL_RATES-DELETE',
         'MTC-SETUP-ADJUST_TOOL_RATES-VIEW',
         'MTC-SETUP-ACTIVITY_TYPE-CREATE',
         'MTC-SETUP-ACTIVITY_TYPE-APPROVE',
         'MTC-SETUP-ACTIVITY_TYPE-EDIT',
         'MTC-SETUP-ACTIVITY_TYPE-DELETE',
         'MTC-SETUP-ACTIVITY_TYPE-VIEW',
         'MTC-SETUP-STRATEGY-VIEW',
         'MTC-SETUP-TENANTS-CREATE',
         'MTC-SETUP-TENANTS-APPROVE',
         'MTC-SETUP-TENANTS-EDIT',
         'MTC-SETUP-TENANTS-DELETE',
         'MTC-SETUP-TENANTS-VIEW',
         'MTC-SETUP-TEAMS-CREATE',
         'MTC-SETUP-TEAMS-APPROVE',
         'MTC-SETUP-TEAMS-EDIT',
         'MTC-SETUP-TEAMS-DELETE',
         'MTC-SETUP-TEAMS-VIEW',
         'MTC-INVENTORY_SETUP-WAREHOUSES-CREATE',
         'MTC-INVENTORY_SETUP-WAREHOUSES-APPROVE',
         'MTC-INVENTORY_SETUP-WAREHOUSES-EDIT',
         'MTC-INVENTORY_SETUP-WAREHOUSES-DELETE',
         'MTC-INVENTORY_SETUP-WAREHOUSES-VIEW',
         'MTC-INVENTORY_SETUP-STOREROOMS-CREATE',
         'MTC-INVENTORY_SETUP-STOREROOMS-APPROVE',
         'MTC-INVENTORY_SETUP-STOREROOMS-EDIT',
         'MTC-INVENTORY_SETUP-STOREROOMS-DELETE',
         'MTC-INVENTORY_SETUP-STOREROOMS-VIEW',
         'MTC-INVENTORY_SETUP-ISLES-CREATE',
         'MTC-INVENTORY_SETUP-ISLES-APPROVE',
         'MTC-INVENTORY_SETUP-ISLES-EDIT',
         'MTC-INVENTORY_SETUP-ISLES-DELETE',
         'MTC-INVENTORY_SETUP-ISLES-VIEW',
         'MTC-INVENTORY_SETUP-ROWS-CREATE',
         'MTC-INVENTORY_SETUP-ROWS-APPROVE',
         'MTC-INVENTORY_SETUP-ROWS-EDIT',
         'MTC-INVENTORY_SETUP-ROWS-DELETE',
         'MTC-INVENTORY_SETUP-ROWS-VIEW',
         'MTC-INVENTORY_SETUP-BINS-CREATE',
         'MTC-INVENTORY_SETUP-BINS-APPROVE',
         'MTC-INVENTORY_SETUP-BINS-EDIT',
         'MTC-INVENTORY_SETUP-BINS-DELETE',
         'MTC-INVENTORY_SETUP-BINS-VIEW',
         'MTC-PARTS-PART_CATEGORY-CREATE',
         'MTC-PARTS-PART_CATEGORY-APPROVE',
         'MTC-PARTS-PART_CATEGORY-EDIT',
         'MTC-PARTS-PART_CATEGORY-DELETE',
         'MTC-PARTS-PART_CATEGORY-VIEW',
         'MTC-PARTS-MANAGE_PARTS-CREATE',
         'MTC-PARTS-MANAGE_PARTS-APPROVE',
         'MTC-PARTS-MANAGE_PARTS-EDIT',
         'MTC-PARTS-MANAGE_PARTS-DELETE',
         'MTC-PARTS-MANAGE_PARTS-VIEW',
         'MTC-PARTS-MANAGE_PARTS-RECEIVE',
         'MTC-PARTS-STOCK_REQUISITION-CREATE',
         'MTC-PARTS-STOCK_REQUISITION-APPROVE',
         'MTC-PARTS-STOCK_REQUISITION-EDIT',
         'MTC-PARTS-STOCK_REQUISITION-DELETE',
         'MTC-PARTS-STOCK_REQUISITION-VIEW',
         'MTC-PARTS-COLLECT_RETURN-VIEW',
         'MTC-PARTS-COLLECT_RETURN-COLLECT',
         'MTC-PARTS-COLLECT_RETURN-RETURN',
         'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-COMPLETE',
         'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-EDIT',
         'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-DELETE',
         'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-VIEW',
         'MTC-USAGE_MONITORING-ASSET_TRACKING_READY-ADD_READING',
         'MTC-USAGE_MONITORING-ASSET_TRACKING_READY-REMOVE',
         'MTC-USAGE_MONITORING-ASSET_TRACKING_READY-VIEW',
         'MTC-WORKORDER-REQUESTS-CREATE',
         'MTC-WORKORDER-REQUESTS-APPROVE',
         'MTC-WORKORDER-REQUESTS-EDIT',
         'MTC-WORKORDER-REQUESTS-DELETE',
         'MTC-WORKORDER-REQUESTS-VIEW',
         'MTC-WORKORDER-ACTIVITIES-CREATE',
         'MTC-WORKORDER-ACTIVITIES-APPROVE',
         'MTC-WORKORDER-ACTIVITIES-EDIT',
         'MTC-WORKORDER-ACTIVITIES-DELETE',
         'MTC-WORKORDER-ACTIVITIES-VIEW',
         'MTC-WORKORDER-TRIGGERED_ASSETS-CREATE',
         'MTC-WORKORDER-TRIGGERED_ASSETS-VIEW',
         'MTC-WORKORDER-WORKORDERS-CREATE',
         'MTC-WORKORDER-WORKORDERS-EDIT',
         'MTC-WORKORDER-WORKORDERS-VIEW',
         'MTC-WORKORDER-WORKORDERS-SCHEDULE',
         'MTC-WORKORDER-SCHEDULE_WORKORDERS-SCHEDULE',
         'MTC-WORKORDER-SCHEDULE_WORKORDERS-EDIT_SCHEDULE',
         'MTC-WORKORDER-SCHEDULE_WORKORDERS-VIEW_SCHEDULE',
         'MTC-WORKORDER-PICK_WORKORDER-VIEW',
         'MTC-WORKORDER-MY_WORKORDERS-VIEW',
         'MTC-WORKORDER-JOBCARD_ASSESSMENTS-CREATE',
         'MTC-WORKORDER-JOBCARD_ASSESSMENTS-APPROVE',
         'MTC-WORKORDER-JOBCARD_ASSESSMENTS-EDIT',
         'MTC-WORKORDER-JOBCARD_ASSESSMENTS-DELETE',
         'MTC-WORKORDER-JOBCARD_ASSESSMENTS-VIEW',
         'MTC-REPORTS-VIEW',
         'MTC-REPORTS-PRINTVIEW',
      ])) {
         if (this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'MAINTENANCE-MANAGER',
            'MTC-SETUP-LABOUR_RESOURCE_TYPE-CREATE',
            'MTC-SETUP-LABOUR_RESOURCE_TYPE-APPROVE',
            'MTC-SETUP-LABOUR_RESOURCE_TYPE-EDIT',
            'MTC-SETUP-LABOUR_RESOURCE_TYPE-DELETE',
            'MTC-SETUP-LABOUR_RESOURCE_TYPE-VIEW',
         ])) {
            childItemArrayA.push({
               caption: 'Labour Resource Type',
               icon: 'far fa-fw fa-users-cog',
               url: '/maintenance/labourresourcetype',
            });
         }
         if (this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'MAINTENANCE-MANAGER',
            'MTC-SETUP-LABOUR_RESOURCE-CREATE',
            'MTC-SETUP-LABOUR_RESOURCE-APPROVE',
            'MTC-SETUP-LABOUR_RESOURCE-EDIT',
            'MTC-SETUP-LABOUR_RESOURCE-DELETE',
            'MTC-SETUP-LABOUR_RESOURCE-VIEW',
         ])) {
            childItemArrayA.push({
               caption: 'Labour Resource',
               icon: 'far fa-fw fa-users',
               url: '/maintenance/labourresource',
            });
         }
         if (this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'MAINTENANCE-MANAGER',
            'MTC-SETUP-ADJUST_LABOUR_RATES-CREATE',
            'MTC-SETUP-ADJUST_LABOUR_RATES-APPROVE',
            'MTC-SETUP-ADJUST_LABOUR_RATES-EDIT',
            'MTC-SETUP-ADJUST_LABOUR_RATES-DELETE',
            'MTC-SETUP-ADJUST_LABOUR_RATES-VIEW',
         ])) {
            childItemArrayA.push({
               caption: 'Adjust Labour Rate',
               icon: 'far fa-fw fa-people-arrows',
               url: '/maintenance/adjustlabourresource',
            });
         }

         if (this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'MAINTENANCE-MANAGER',
            'MTC-SETUP-ASSET_RESOURCE_TYPE-CREATE',
            'MTC-SETUP-ASSET_RESOURCE_TYPE-APPROVE',
            'MTC-SETUP-ASSET_RESOURCE_TYPE-EDIT',
            'MTC-SETUP-ASSET_RESOURCE_TYPE-DELETE',
            'MTC-SETUP-ASSET_RESOURCE_TYPE-VIEW',
         ])) {
            childItemArrayB.push({
               caption: 'Asset Resource Type',
               icon: 'far fa-fw fa-truck',
               url: '/maintenance/resourcetype',
            });
         }
         if (this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'MAINTENANCE-MANAGER',
            'MTC-SETUP-ASSET_RESOURCES-CREATE',
            'MTC-SETUP-ASSET_RESOURCES-APPROVE',
            'MTC-SETUP-ASSET_RESOURCES-EDIT',
            'MTC-SETUP-ASSET_RESOURCES-DELETE',
            'MTC-SETUP-ASSET_RESOURCES-VIEW',
         ])) {
            childItemArrayB.push({
               caption: 'Asset Resource',
               icon: 'far fa-fw fa-truck-pickup',
               url: '/maintenance/resource',
            });
         }
         if (this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'MAINTENANCE-MANAGER',
            'MTC-SETUP-ADJUST_ASSET_RATES-CREATE',
            'MTC-SETUP-ADJUST_ASSET_RATES-APPROVE',
            'MTC-SETUP-ADJUST_ASSET_RATES-EDIT',
            'MTC-SETUP-ADJUST_ASSET_RATES-DELETE',
            'MTC-SETUP-ADJUST_ASSET_RATES-VIEW',
         ])) {
            childItemArrayB.push({
               caption: 'Adjust Resource Rate',
               icon: 'far fa-fw fa-people-arrows',
               url: '/maintenance/adjustresource',
            });
         }

         if (this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'MAINTENANCE-MANAGER',
            'MTC-SETUP-TOOL_RESOURCE_TYPE-CREATE',
            'MTC-SETUP-TOOL_RESOURCE_TYPE-APPROVE',
            'MTC-SETUP-TOOL_RESOURCE_TYPE-EDIT',
            'MTC-SETUP-TOOL_RESOURCE_TYPE-DELETE',
            'MTC-SETUP-TOOL_RESOURCE_TYPE-VIEW',
         ])) {
            childItemArrayC.push({
               caption: 'Tool Type',
               icon: 'far fa-fw fa-wrench',
               url: '/maintenance/tooltype',
            });
         }
         if (this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'MAINTENANCE-MANAGER',
            'MTC-SETUP-TOOL_RESOURCES-CREATE',
            'MTC-SETUP-TOOL_RESOURCES-APPROVE',
            'MTC-SETUP-TOOL_RESOURCES-EDIT',
            'MTC-SETUP-TOOL_RESOURCES-DELETE',
            'MTC-SETUP-TOOL_RESOURCES-VIEW',
         ])) {
            childItemArrayC.push({
               caption: 'Tool',
               icon: 'far fa-fw fa-tools',
               url: '/maintenance/tool',
            });
         }
         if (this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'MAINTENANCE-MANAGER',
            'MTC-SETUP-ADJUST_TOOL_RATES-CREATE',
            'MTC-SETUP-ADJUST_TOOL_RATES-APPROVE',
            'MTC-SETUP-ADJUST_TOOL_RATES-EDIT',
            'MTC-SETUP-ADJUST_TOOL_RATES-DELETE',
            'MTC-SETUP-ADJUST_TOOL_RATES-VIEW',
         ])) {
            childItemArrayC.push({
               caption: 'Adjust Tool Rate',
               icon: 'far fa-fw fa-exchange-alt',
               url: '/maintenance/adjusttool',
            });
         }

         var childItemArrayD = [];
         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'MAINTENANCE-MANAGER',
               'MTC-SETUP-TEAMS-CREATE',
               'MTC-SETUP-TEAMS-APPROVE',
               'MTC-SETUP-TEAMS-EDIT',
               'MTC-SETUP-TEAMS-DELETE',
               'MTC-SETUP-TEAMS-VIEW',
            ])
         ) {
            childItemArrayD.push({
               caption: 'Activity Type',
               icon: 'far fa-fw fa-pencil-ruler',
               url: '/maintenance/activitytype',
            });
         }
         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'MAINTENANCE-MANAGER',
               'MTC-SETUP-TEAMS-CREATE',
               'MTC-SETUP-TEAMS-APPROVE',
               'MTC-SETUP-TEAMS-EDIT',
               'MTC-SETUP-TEAMS-DELETE',
               'MTC-SETUP-TEAMS-VIEW',
            ])
         ) {
            childItemArrayD.push({
               caption: 'Strategy',
               icon: 'far fa-fw fa-directions',
               url: '/maintenance/strategy',
            });
         }
         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'MAINTENANCE-MANAGER',
               'MTC-SETUP-TEAMS-CREATE',
               'MTC-SETUP-TEAMS-APPROVE',
               'MTC-SETUP-TEAMS-EDIT',
               'MTC-SETUP-TEAMS-DELETE',
               'MTC-SETUP-TEAMS-VIEW',
            ])
         ) {
            childItemArrayD.push({
               caption: 'Client',
               icon: 'far fa-fw fa-user-tag',
               url: '/maintenance/tenant',
            });
         }
         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'MAINTENANCE-MANAGER',
               'MTC-SETUP-TEAMS-CREATE',
               'MTC-SETUP-TEAMS-APPROVE',
               'MTC-SETUP-TEAMS-EDIT',
               'MTC-SETUP-TEAMS-DELETE',
               'MTC-SETUP-TEAMS-VIEW',
            ])
         ) {
            childItemArrayD.push({
               caption: 'Team',
               icon: 'far fa-fw fa-user-friends',
               url: '/maintenance/team',
            });
         }

         var childItemArrayE = [];
         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'MAINTENANCE-MANAGER',
               'MTC-INVENTORY_SETUP-WAREHOUSES-CREATE',
               'MTC-INVENTORY_SETUP-WAREHOUSES-APPROVE',
               'MTC-INVENTORY_SETUP-WAREHOUSES-EDIT',
               'MTC-INVENTORY_SETUP-WAREHOUSES-DELETE',
               'MTC-INVENTORY_SETUP-WAREHOUSES-VIEW',
            ])
         ) {
            childItemArrayE.push({
               caption: 'Warehouses',
               icon: 'far fa-fw fa-home',
               url: '/maintenance/warehouses',
            });
         }
         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'MAINTENANCE-MANAGER',
               'MTC-INVENTORY_SETUP-STOREROOMS-CREATE',
               'MTC-INVENTORY_SETUP-STOREROOMS-APPROVE',
               'MTC-INVENTORY_SETUP-STOREROOMS-EDIT',
               'MTC-INVENTORY_SETUP-STOREROOMS-DELETE',
               'MTC-INVENTORY_SETUP-STOREROOMS-VIEW',
            ])
         ) {
            childItemArrayE.push({
               caption: 'Storerooms',
               icon: 'far fa-fw fa-door-closed',
               url: '/maintenance/storerooms',
            });
         }
         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'MAINTENANCE-MANAGER',
               'MTC-INVENTORY_SETUP-ISLES-CREATE',
               'MTC-INVENTORY_SETUP-ISLES-APPROVE',
               'MTC-INVENTORY_SETUP-ISLES-EDIT',
               'MTC-INVENTORY_SETUP-ISLES-DELETE',
               'MTC-INVENTORY_SETUP-ISLES-VIEW',
            ])
         ) {
            childItemArrayE.push({
               caption: 'Isles',
               icon: 'far fa-fw fa-grip-lines',
               url: '/maintenance/isles',
            });
         }
         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'MAINTENANCE-MANAGER',
               'MTC-INVENTORY_SETUP-ROWS-CREATE',
               'MTC-INVENTORY_SETUP-ROWS-APPROVE',
               'MTC-INVENTORY_SETUP-ROWS-EDIT',
               'MTC-INVENTORY_SETUP-ROWS-DELETE',
               'MTC-INVENTORY_SETUP-ROWS-VIEW',
            ])
         ) {
            childItemArrayE.push({
               caption: 'Rows',
               icon: 'far fa-fw fa-sitemap',
               url: '/maintenance/rows',
            });
         }
         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'MAINTENANCE-MANAGER',
               'MTC-INVENTORY_SETUP-BINS-CREATE',
               'MTC-INVENTORY_SETUP-BINS-APPROVE',
               'MTC-INVENTORY_SETUP-BINS-EDIT',
               'MTC-INVENTORY_SETUP-BINS-DELETE',
               'MTC-INVENTORY_SETUP-BINS-VIEW',
            ])
         ) {
            childItemArrayE.push({
               caption: 'Bins',
               icon: 'far fa-fw fa-dumpster',
               url: '/maintenance/bins',
            });
         }

         var childItemArrayF = [];
         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'MAINTENANCE-MANAGER',
               'MTC-PARTS-PART_CATEGORY-CREATE',
               'MTC-PARTS-PART_CATEGORY-APPROVE',
               'MTC-PARTS-PART_CATEGORY-EDIT',
               'MTC-PARTS-PART_CATEGORY-DELETE',
               'MTC-PARTS-PART_CATEGORY-VIEW',
            ])
         ) {
            childItemArrayF.push({
               caption: 'Part Category',
               icon: 'far fa-fw fa-sitemap',
               url: '/maintenance/partcategory',
            });
         }
         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'MAINTENANCE-MANAGER',
               'MTC-PARTS-MANAGE_PARTS-CREATE',
               'MTC-PARTS-MANAGE_PARTS-APPROVE',
               'MTC-PARTS-MANAGE_PARTS-EDIT',
               'MTC-PARTS-MANAGE_PARTS-DELETE',
               'MTC-PARTS-MANAGE_PARTS-VIEW',
               'MTC-PARTS-MANAGE_PARTS-RECEIVE',
            ])
         ) {
            childItemArrayF.push({
               caption: 'Manage Parts',
               icon: 'far fa-fw fa-wrench',
               url: '/maintenance/part',
            });
         }
         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'MAINTENANCE-MANAGER',
               'MTC-PARTS-STOCK_REQUISITION-CREATE',
               'MTC-PARTS-STOCK_REQUISITION-APPROVE',
               'MTC-PARTS-STOCK_REQUISITION-EDIT',
               'MTC-PARTS-STOCK_REQUISITION-DELETE',
               'MTC-PARTS-STOCK_REQUISITION-VIEW',
            ])
         ) {
            childItemArrayF.push({
               caption: 'Stock Requisition',
               icon: 'far fa-fw fa-shopping-basket',
               url: '/maintenance/stockrequisition',
            });
         }
         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'MAINTENANCE-MANAGER',
               'MTC-PARTS-COLLECT_RETURN-VIEW',
               'MTC-PARTS-COLLECT_RETURN-COLLECT',
               'MTC-PARTS-COLLECT_RETURN-RETURN',
            ])
         ) {
            childItemArrayF.push({
               caption: 'Collect/Return Parts',
               icon: 'far fa-fw fa-exchange-alt',
               url: '/maintenance/workorder/collection',
            });
         }

         var childItemArrayG = [];
         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'MAINTENANCE-MANAGER',
               'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-COMPLETE',
               'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-EDIT',
               'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-DELETE',
               'MTC-USAGE_MONITORING-USAGE_PENDING_SETUP-VIEW',
            ])
         ) {
            childItemArrayG.push({
               caption: 'Assets Pending Setup',
               icon: 'far fa-fw fa-tachometer-alt',
               url: '/maintenance/usage',
            });
         }
         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'MAINTENANCE-MANAGER',
               'MTC-USAGE_MONITORING-ASSET_TRACKING_READY-ADD_READING',
               'MTC-USAGE_MONITORING-ASSET_TRACKING_READY-REMOVE',
               'MTC-USAGE_MONITORING-ASSET_TRACKING_READY-VIEW',
            ])
         ) {
            childItemArrayG.push({
               caption: 'Assets Tracking Active',
               icon: 'far fa-fw fa-chart-line',
               url: '/maintenance/usage/active',
            });
         }

         var childItemArrayH = [];
         if (this.authService.roleMatch(['SYSTEM-ADMIN'])) {
            childItemArrayH.push({
               caption: 'Requests',
               icon: 'far fa-fw fa-shopping-basket',
               url: '/maintenance/requisition',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN'])) {
            childItemArrayH.push({
               caption: 'Activities',
               icon: 'far fa-fw fa-wrench',
               url: '/maintenance/activity',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN'])) {
            childItemArrayH.push({
               caption: 'Triggered Assets',
               icon: 'far fa-fw fa-object-group',
               url: '/maintenance/group',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN'])) {
            childItemArrayH.push({
               caption: 'Work Orders',
               icon: 'far fa-fw fa-briefcase',
               url: '/maintenance/workorder',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN'])) {
            childItemArrayH.push({
               caption: 'Schedule',
               icon: 'far fa-fw fa-calendar-alt',
               url: '/maintenance/schedule',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN'])) {
            childItemArrayH.push({
               caption: 'Pick Work Order',
               icon: 'far fa-fw fa-hand-pointer',
               url: '/maintenance/pickworkorder/null',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN'])) {
            childItemArrayH.push({
               caption: 'My Work Orders',
               icon: 'far fa-fw fa-user-edit',
               url: '/maintenance/workorder/owner',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN'])) {
            childItemArrayH.push({
               caption: 'Job Card Assessment',
               icon: 'far fa-fw fa-clipboard-check',
               url: '/maintenance/jobcard/jobcard-assessment',
            });
         }

         menuArray.push({
            caption: 'Maintenance',
            icon: 'far fa-fw fa-folder',
            children: [
               {
                  caption: 'Setup',
                  icon: 'far fa-fw fa-folder',
                  children: [
                     {
                        caption: 'Resources',
                        icon: 'far fa-fw fa-folder',
                        children: [
                           {
                              caption: 'Labour Resources',
                              icon: 'far fa-fw fa-folder',
                              children: childItemArrayA,
                           },
                           {
                              caption: 'Asset Resources',
                              icon: 'far fa-fw fa-folder',
                              children: childItemArrayB,
                           },
                           {
                              caption: 'Tool Resources',
                              icon: 'far fa-fw fa-folder',
                              children: childItemArrayC,
                           },
                        ],
                     },
                     {
                        caption: 'Activity Type',
                        icon: 'far fa-fw fa-pencil-ruler',
                        url: '/maintenance/activitytype',
                     },
                     {
                        caption: 'Strategy',
                        icon: 'far fa-fw fa-directions',
                        url: '/maintenance/strategy',
                     },
                     {
                        caption: 'Client',
                        icon: 'far fa-fw fa-user-tag',
                        url: '/maintenance/tenant',
                     },
                     {
                        caption: 'Team',
                        icon: 'far fa-fw fa-user-friends',
                        url: '/maintenance/team',
                     },
                  ],
               },
               {
                  caption: 'Inventory Setup',
                  icon: 'far fa-fw fa-folder',
                  children: childItemArrayE,
               },
               {
                  caption: 'Parts',
                  icon: 'far fa-fw fa-folder',
                  children: childItemArrayF,
               },
               {
                  caption: 'Asset Usage Monitoring',
                  icon: 'far fa-fw fa-folder',
                  children: childItemArrayG,
               },
               {
                  caption: 'Work Order',
                  icon: 'far fa-fw fa-folder',
                  children: childItemArrayH,
               },
               {
                  caption: 'Reports',
                  icon: 'far fa-fw fa-print',
                  url: '/maintenance/reportdashboard',
               },
            ],
         });

         childItemArrayA = [];
         childItemArrayB = [];
         childItemArrayC = [];
      }

      // ============================================================ ERP Integration ============================================================
      if (
         this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_1-VIEW',
            'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_2-VIEW',
            'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_3-VIEW',
            'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_4-VIEW',
            'ERP-OTHER_INVOICE_IMPORTS-EXTERNAL_INVOICES-VIEW',
            'ERP-OTHER_INVOICE_IMPORTS-ARCHIVED_INVOICES-VIEW',
            'ERP-AUDIT-ACCOUNTS-VIEW',
            'ERP-AUDIT-ASSETS-VIEW',
            'ERP-AUDIT-SUPPLIERS-VIEW',
            'ERP-AUDIT-ERRORS-VIEW',
         ])
      ) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ERP-SCN_INTEGRATION-APPROVED_ASSETS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Approved Assets',
               icon: 'far fa-fw fa-cogs',
               url: '/scan/scanintegrated',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ERP-SCN_INTEGRATION-ERRORS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Errors',
               icon: 'far fa-fw fa-exclamation-circle',
               url: '/integration/errors',
            });
         }

         if (
            this.authService.roleMatch([
               'SYSTEM-ADMIN',
               'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_1-VIEW',
               'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_2-VIEW',
               'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_3-VIEW',
               'ERP-ASSET_INVOICE_IMPORTS-REVIEW_STEP_4-VIEW',
            ])
         ) {
            childItemArrayB.push({
               caption: 'Asset Invoice Imports',
               icon: 'far fa-fw fa-flag',
               url: 'integration/importsNormal',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ERP-OTHER_INVOICE_IMPORTS-ARCHIVED_INVOICES-VIEW'])) {
            childItemArrayB.push({
               caption: 'Archived Invoices',
               icon: 'far fa-fw fa-flag',
               url: '/integration/importsNormalArchive',
            });
         }
         // if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ERP-SCN_INTEGRATION-APPROVED_ASSETS-VIEW'])) {
         childItemArrayB.push({
            caption: 'Asset Journal Imports',
            icon: 'far fa-fw fa-adjust',
            url: '/integration/asset-journal-imports',
         });
         // }
         // if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ERP-SCN_INTEGRATION-APPROVED_ASSETS-VIEW'])) {
         childItemArrayB.push({
            caption: 'Asset Sales Imports',
            icon: 'far fa-fw fa-money-bill',
            url: '/integration/asset-sales-imports',
         });
         // }

         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ERP-AUDIT-ACCOUNTS-VIEW'])) {
            childItemArrayC.push({
               caption: 'Accounts',
               icon: 'far fa-fw fa-cogs',
               url: 'integration/accounts',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ERP-AUDIT-ASSETS-VIEW'])) {
            childItemArrayC.push({
               caption: 'Assets',
               icon: 'far fa-fw fa-cogs',
               url: '/integration/assets',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ERP-AUDIT-SUPPLIERS-VIEW'])) {
            childItemArrayC.push({
               caption: 'Suppliers',
               icon: 'far fa-fw fa-cogs',
               url: '/integration/suppliers',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'ERP-AUDIT-ERRORS-VIEW'])) {
            childItemArrayC.push({
               caption: 'Errors',
               icon: 'far fa-fw fa-exclamation-circle',
               url: '/integration/errors',
            });
         }

         menuArray.push({
            caption: 'ERP Integration',
            icon: 'far fa-fw fa-folder',
            children: [
               {
                  caption: 'Scan Integration',
                  icon: 'far fa-fw fa-folder',
                  children: childItemArrayA,
               },
               {
                  caption: 'ERP Imports',
                  icon: 'far fa-fw fa-folder',
                  children: childItemArrayB,
               },
               {
                  caption: 'Integration Audit',
                  icon: 'far fa-fw fa-folder',
                  children: childItemArrayC,
               },
            ],
         });

         childItemArrayA = [];
         childItemArrayB = [];
         childItemArrayC = [];
      }

      // ============================================================ Insurance ============================================================
      if (
         this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'INSURANCE-CONFIGURE-INSURER-VIEW',
            'INSURANCE-CONFIGURE-POLICIES-VIEW',
            'INSURANCE-CONFIGURE-CONTACTS-VIEW',
            'INSURANCE-CONFIGURE-GROUPS-VIEW',
            'INSURANCE-CONFIGURE-LINK-VIEW',
            'INSURANCE-PROCESSING-REGISTER-VIEW',
            'INSURANCE-PROCESSING-ASSETS_PENDING_LIST-VIEW',
            'INSURANCE-PROCESSING-CLAIMS-VIEW',
            'INSURANCE-REPORTS-VIEW',
            'INSURANCE-REPORTS-PRINTVIEW',
         ])
      ) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'INSURANCE-CONFIGURE-INSURER-VIEW'])) {
            childItemArrayA.push({
               caption: 'Insurer',
               icon: 'far fa-fw fa-user',
               url: '/insurance/insurer',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'INSURANCE-CONFIGURE-POLICIES-VIEW'])) {
            childItemArrayA.push({
               caption: 'Policies',
               icon: 'far fa-fw fa-cogs',
               url: '/insurance/policy',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'INSURANCE-CONFIGURE-CONTACTS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Contacts',
               icon: 'far fa-fw fa-users',
               url: '/insurance/ins-contact',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'INSURANCE-CONFIGURE-GROUPS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Groups',
               icon: 'far fa-fw fa-users-cog',
               url: '/insurance/ins-group',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'INSURANCE-CONFIGURE-LINK-VIEW'])) {
            childItemArrayA.push({
               caption: 'Link Assets to Groups',
               icon: 'far fa-fw fa-cogs',
               url: '/insurance/ins-assets',
            });
         }

         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'INSURANCE-PROCESSING-REGISTER-VIEW'])) {
            childItemArrayB.push({
               caption: 'Insurance Register',
               icon: 'far fa-fw fa-chart-line',
               url: '/insurance/insurance-register',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'INSURANCE-PROCESSING-ASSETS_PENDING_LIST-VIEW'])) {
            childItemArrayB.push({
               caption: 'Pending List',
               icon: 'far fa-fw fa-balance-scale',
               url: '/insurance/pending-list',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'INSURANCE-PROCESSING-CLAIMS-VIEW'])) {
            childItemArrayB.push({
               caption: 'Claim Processing',
               icon: 'far fa-fw fa-check',
               url: '/insurance/claim',
            });
         }

         childItemArrayC.push({
            caption: 'Configure',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayA,
         });
         childItemArrayC.push({
            caption: 'Processing',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayB,
         });
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'INSURANCE-REPORTS-VIEW', 'INSURANCE-REPORTS-PRINTVIEW'])) {
            childItemArrayC.push({
               caption: 'Reports',
               icon: 'far fa-fw fa-print',
               url: '/insurance/ins-report',
            });
         }

         menuArray.push({
            caption: 'Insurance',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayC,
         });

         childItemArrayA = [];
         childItemArrayB = [];
         childItemArrayC = [];
      }

      // ============================================================ Warranty Management ============================================================
      if (
         this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'WARRANTY_MNGT-SETUP-TYPES-VIEW',
            'WARRANTY_MNGT-SETUP-PROVIDERS-VIEW',
            'WARRANTY_MNGT-WARRANTY_MNGT-CONTRACTORS-VIEW',
            'WARRANTY_MNGT-WARRANTY_MNGT-ALL-VIEW',
            'WARRANTY_MNGT-WARRANTY_MNGT-REPORTS-VIEW',
         ])
      ) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WARRANTY_MNGT-SETUP-TYPES-VIEW'])) {
            childItemArrayA.push({
               caption: 'Types',
               icon: 'far fa-fw fa-paperclip',
               url: '/warranty/types',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WARRANTY_MNGT-SETUP-PROVIDERS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Providers',
               icon: 'far fa-fw fa-truck',
               url: '/warranty/providers',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WARRANTY_MNGT-WARRANTY_MNGT-CONTRACTORS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Contractors',
               icon: 'far fa-fw fa-hard-hat',
               url: '/warranty/contractors',
            });
         }

         childItemArrayB.push({
            caption: 'Setup',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayB,
         });

         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WARRANTY_MNGT-WARRANTY_MNGT-ALL-VIEW'])) {
            childItemArrayB.push({
               caption: 'All Warranties',
               icon: 'far fa-fw fa-list-alt',
               url: '/warranty/all',
            });
         }
         // if (this.authService.roleMatch(['SYSTEM-ADMIN', 'INSURANCE-CONFIGURE-INSURER-VIEW'])) {
         childItemArrayB.push({
            caption: 'Reports',
            icon: 'far fa-fw fa-print',
            url: '/warranty/reports',
         });
         // }

         menuArray.push({
            caption: 'Warranty Management',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayB,
         });

         childItemArrayA = [];
         childItemArrayB = [];
      }

      // ============================================================ Booking Management ============================================================
      if (this.authService.roleMatch(['SYSTEM-ADMIN', 'BOOKING-MANAGER'])) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'BOOKING-MANAGER'])) {
            childItemArrayA.push({
               caption: 'Booking',
               icon: 'far fa-fw fa-exchange-alt',
               url: '/bookingManagement/bookings',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'BOOKING-MANAGER'])) {
            childItemArrayA.push({
               caption: 'Clients',
               icon: 'far fa-fw fa-user',
               url: '/bookingManagement/client',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'BOOKING-MANAGER'])) {
            childItemArrayA.push({
               caption: 'Asset',
               icon: 'far fa-fw fa-project-diagram',
               url: '/bookingManagement/bmm-asset',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'BOOKING-MANAGER'])) {
            childItemArrayA.push({
               caption: 'Monitoring',
               icon: 'far fa-fw fa-tachometer-alt',
               url: '/bookingManagement/monitorBookings',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'BOOKING-MANAGER'])) {
            childItemArrayA.push({
               caption: 'Reports',
               icon: 'far fa-fw fa-print',
               url: '/bookingManagement/reportsBookings',
            });
         }

         menuArray.push({
            caption: 'Booking Management',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayA,
         });

         childItemArrayA = [];
      }

      // ============================================================ Workflow ============================================================
      if (
         this.authService.roleMatch([
            'SYSTEM-ADMIN',
            'WORKFLOW-PROCESSING-SETUP-VIEW',
            'WORKFLOW-PROCESSING-ALL-VIEW',
            'WORKFLOW-PROCESSING-APPROVALS-VIEW',
            'WORKFLOW-PROCESSING-REJECTIONS-VIEW',
         ])
      ) {
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WORKFLOW-PROCESSING-SETUP-VIEW'])) {
            childItemArrayA.push({
               caption: 'Setup',
               icon: 'far fa-fw fa-cog',
               url: '/workflow/setup',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WORKFLOW-PROCESSING-ALL-VIEW'])) {
            childItemArrayA.push({
               caption: 'All Workflows',
               icon: 'far fa-fw fa-list-alt',
               url: '/workflow/all',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WORKFLOW-PROCESSING-APPROVALS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Approvals',
               icon: 'far fa-fw fa-check',
               url: '/workflow/open',
            });
         }
         if (this.authService.roleMatch(['SYSTEM-ADMIN', 'WORKFLOW-PROCESSING-REJECTIONS-VIEW'])) {
            childItemArrayA.push({
               caption: 'Rejections to Review',
               icon: 'far fa-fw fa-thumbs-down',
               url: '/workflow/rejected',
            });
         }

         menuArray.push({
            caption: 'Workflow',
            icon: 'far fa-fw fa-folder',
            children: childItemArrayA,
         });

         childItemArrayA = [];
      }

      const jsonResult = { type: 'setMenu', payload: { menu: menuArray } };
      return jsonResult;
   }
}
