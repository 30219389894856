import { Component, Input, OnInit } from '@angular/core';

@Component({
   selector: 'app-skeleton',
   templateUrl: './skeleton.component.html',
   styleUrls: ['./skeleton.component.css'],
})
export class AMSSkeletonComponent implements OnInit {
   @Input()
   Size: string;
   constructor() {}
   ngOnInit() {}
}
