import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { TelerikReportViewerComponent } from '@progress/telerik-angular-report-viewer';
import { ActivatedRoute } from '@angular/router';
import { SettingService } from 'src/app/admin/_services/setting.service';
import { environment } from 'src/environments/environment';
import $ from 'jquery';

@Component({
   selector: 'app-report-date-parameter',
   templateUrl: './report-date-parameter.component.html',
   styleUrls: ['./report-date-parameter.component.css'],
   animations: [fadeInOnEnterAnimation()],
})
export class ReportDateParameterComponent implements OnInit, AfterViewInit {
   @ViewChild('viewer1') viewer: TelerikReportViewerComponent;
   dateUpdated = false; // Flag to indicate that date format has been set
   reportName: string;
   companyName: string;
   registrationNumber: string;
   logo: string;
   fromDate: Date;
   toDate: Date;

   viewerContainerStyle = {
      position: 'fixed',
      top: '74px',
      bottom: '0',
      right: '0',
      left: '240px',
      ['font-family']: 'ms sans serif',
   };

   Url = environment.apiUrl + 'reports';

   constructor(private route: ActivatedRoute, private settingService: SettingService) {}
   ngAfterViewInit(): void {
      this.settingService.getSettings().subscribe((settingList) => {
         settingList.forEach((setting) => {
            if (setting.id === 'LOGO') {
               if (setting.settingValue === '') {
                  this.logo = 'Company.png';
               } else {
                  this.logo = '../../' + setting.settingValue;
               }
            }
            if (setting.id === 'COMPNAME') {
               this.companyName = setting.settingValue;
            }
            if (setting.id === 'COMPREGNO') {
               this.registrationNumber = setting.settingValue;
            }
         });
         this.route.paramMap.subscribe((params) => {
            this.reportName = params.get('type') + '.trdp';

            const mToDate = new Date(new Date(params.get('toDate')));
            const userTimezoneOffsetTo = mToDate.getTimezoneOffset() * 60000;
            this.toDate = new Date(mToDate.getTime() - userTimezoneOffsetTo);

            const mFromDate = new Date(new Date(params.get('fromDate')));
            const userTimezoneOffsetFrom = mFromDate.getTimezoneOffset() * 60000;
            this.fromDate = new Date(mFromDate.getTime() - userTimezoneOffsetFrom);
         });

         const rs = {
            report: this.reportName,
            parameters: { Logo: this.logo, CompanyName: this.companyName + ' - ' + this.registrationNumber, FromDate: this.fromDate, ToDate: this.toDate },
         };
         this.viewer.setReportSource(rs);
      });
   }

   setDateFormat(dateFormat) {
      var datepicker = $('#reportViewer1').find("[data-role='datepicker']");
      if (datepicker.length > 0) {
         this.dateUpdated = true;

         for (var i = 0; i < datepicker.length; i += 1) {
            var dp = $(datepicker[i]).data('kendoDatePicker');
            var oldValue = dp.value();

            dp.setOptions({
               format: dateFormat,
            });
            dp.value(oldValue);
         }
      }
   }

   onUpdateUi() {
      // Skip if date format already set
      if (!this.dateUpdated) {
         this.setDateFormat('dd/MM/yyyy'); //SPECIFY THE DATE FORMAT HERE
      }
   }

   ngOnInit() {}
}
