import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Imports } from '../_models/import';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
   providedIn: 'root',
})
export class ImportService {
   baseUrl = environment.apiUrl;
   ImportSelected = new Subject<Imports>();
   ProgressSelected = new Subject<string>();
   constructor(private http: HttpClient) {}

   changeImportState(i: Imports) {
      this.ImportSelected.next(i);
   }
   triggerProgress(s: string) {
      this.ProgressSelected.next(s);
   }
   export(OnlyTemplate: boolean, url: string): Observable<Blob> {
      // tslint:disable-next-line:max-line-length
      return this.http.get(this.baseUrl + url + OnlyTemplate, {
         responseType: 'blob',
      });

      // 'user/export/'
   }
}
