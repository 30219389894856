import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../_services/dashboard.service';
import { DashboardNumbers } from 'src/app/_models/dashboardNumbers';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { SettingService } from 'src/app/admin/_services/setting.service';
import { forkJoin } from 'rxjs';
import { DepreciationService } from 'src/app/financialTransaction/_services/depreciation.service';
import { Router } from '@angular/router';
import { NotifyService } from 'src/app/_services/notify.service';

@Component({
   selector: 'app-dashboard-numbers',
   templateUrl: './dashboard-numbers.component.html',
   styleUrls: ['./dashboard-numbers.component.scss'],
   animations: [fadeInOnEnterAnimation()],
})
export class DashboardNumbersComponent implements OnInit {
   monthEndDate: Date;
   constructor(private service: DashboardService, private depService: DepreciationService, private notify: NotifyService, private router: Router) {}
   dashboardNumbers = {
      accDep: 0,
      accImp: 0,
      bookValue: 0,
      cost: 0,
      countBarcode: 0,
      revaluation: 0,
   };

   loadingNumbers = false;
   smtpHostDefault: boolean;
   smtpHostError: boolean;

   ngOnInit() {
      this.loadingNumbers = true;
      forkJoin(this.service.getDashboardNumbers(), this.depService.getDepreciation(), this.service.getDashboardSmtp()).subscribe(
         ([data, dep, smtp]) => {
            this.monthEndDate = dep.monthEndDate;
            this.loadingNumbers = false;
            this.dashboardNumbers = data;
            this.smtpHostDefault = smtp.hostDefault;
            this.smtpHostError = smtp.hostError;
         },
         (error) => {
            this.loadingNumbers = false;
            const message = 'Failed to load dashboard numbers: ' + error;
            this.notify.error(message);
         }
      );
   }
   openReportDepartment() {
      this.router.navigate(['reports/DashCompOverview']);
   }
   openReportClassification() {
      this.router.navigate(['reports/DashAssetClassOverview']);
   }
   openReportSupplier() {
      this.router.navigate(['reports/DashSupplierOverview']);
   }
}
