import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { UserService } from '../admin/_services/user.service';
import { NotifyService } from 'src/app/_services/notify.service';
import { Subject } from 'rxjs';

@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
   valForm: UntypedFormGroup;
   loading = false;
   siteExpired = false;
   loginVisible = true;
   licenseValid = true;
   migrationError = '';

   constructor(private fb: UntypedFormBuilder, private authService: AuthService, private notify: NotifyService, private routerService: Router, private userService: UserService) {
      window.addEventListener('message', this.handlePortalMessage.bind(this));

      if (self == top) {
         // not in portal
      } else {
         this.getSessionFromParent();
         return;
      }
   }

   getSessionFromParent() {
      window.parent.postMessage({ type: 'requestUserSession' }, '*');
   }

   private tokenSubject = new Subject<string>();
   token$ = this.tokenSubject.asObservable();

   private handlePortalMessage(event: MessageEvent) {
      if (event?.data.type === 'userSession') {
         if (!event.data.payload?.backend?.token) {
            throw new Error('Got userSession event without event.data.payload.backend.token.');
         }
         this.tokenSubject.next(event.data.payload.backend.token);

         this.authService.portallogin(event.data.payload);

         this.loading = false;
         this.routerService.navigate(['/']);
         // }
      }
   }


   login($ev, value: any) {

      $ev.preventDefault();
      this.loading = true;

      if (self == top) {
         // not in portal
      } else {
         this.notify.error('Please use Asamco Portal to log in');
         return;
      }

      // tslint:disable-next-line:forin
      for (const c in this.valForm.controls) {
         this.valForm.controls[c].markAsTouched();
      }
      if (this.valForm.valid) {
         this.authService.login(value).subscribe(
            (login: any) => {
               const isReset = localStorage.getItem('isReset');
               if (isReset === 'true') {
                  this.userService.changePasswordResetState({
                     username: this.valForm.controls['username'].value,
                     id: 0,
                     knownAs: '',
                     created: new Date('2019-01-01'),
                     lastActive: new Date('2019-01-01'),
                     url: '',
                     name: '',
                     surname: '',
                     active: true,
                     roleIds: [],
                     costCentreID: '',
                     frequency: '',
                  });
                  this.loading = false;
               } else {
                  this.loading = false;
                  this.routerService.navigate(['/']);
                  const message = 'Logged in successfully';
                  this.notify.success(message);
               }
            },
            (error) => {
               this.loading = false;
               const message = error;
               this.notify.error(message);
            },
            () => { }
         );
      }
   }

   ngOnInit() {
      this.loginVisible = false;
      this.authService.checkSiteExpired().subscribe(
         (data) => {
            if (data === 0) {
               // 0 means the site is not registered
               this.routerService.navigate(['/register']);
            } else if (data === 2) {
               this.licenseValid = false;
            } else {
               this.licenseValid = true;
               this.valForm = this.fb.group({
                  username: [null, Validators.compose([Validators.required])],
                  password: [null, Validators.required],
               });
            }
            this.loginVisible = true;
         },
         (error) => {
            const message = error;
            this.notify.error(message);
         }
      );
      this.authService.checkMigration().subscribe(
         (data) => {
            if (data.message !== '') {
               this.migrationError = 'MIGRATION ERRORS: ' + data.message;
            }
         },
         (error) => {
            const message = error;
            this.notify.error(message);
         }
      );
   }
}
