import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportComponent } from './_shared/import/import.component';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { RippleModule } from '@progress/kendo-angular-ripple';
import { LayoutModule, PanelBarModule } from '@progress/kendo-angular-layout';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { UploadModule, FileSelectModule } from '@progress/kendo-angular-upload';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { MDBSpinningPreloader, MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { HasRoleDirective } from './_directives/hasRole.directive';
import { HasRoleDisableDirective } from './_directives/hasRoleDisable.directive';
import { TableDataDirective } from './_directives/table-data.directive';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { RatingComponent } from './_shared/rating/rating.component';
import { AssetStatusComponent } from './_shared/asset-status/asset-status.component';
import { AssetSelectionComponent } from './assetManagement/asset/asset-selection/asset-selection.component';
import { TableDataSearchAssetDirective } from './_directives/table-data-search-asset.directive';
import { PopupAnchorDirective } from './_directives/popup.anchor-target.directive';
import { InformationBoxComponent } from './_shared/information-box/information-box.component';
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import { SecondTableDataDirective } from './_directives/secondTable-data.directive';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { ImportLogDirective } from './_directives/import-log.directive';
import { ClassificationSelectComponent } from './setupClass/classification/classification-select/classification-select.component';
import { UpgradeEditComponent } from './financialTransaction/upgrade/upgrade-edit/upgrade-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Import2Component } from './_shared/import2/import2.component';
import { DocumentComponent } from './_shared/document/document.component';
import { EmptyPipe } from './_pipes/empty.pipe';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { HasNotRoleDirective } from './_directives/hasNotRole.directive';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { DisposalEditComponent } from './financialTransaction/disposal/disposal-edit/disposal-edit.component';
import { GalleryModule, GALLERY_CONFIG } from 'ng-gallery';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { LabelModule } from '@progress/kendo-angular-label';
import { AssetSearchComponent } from './maintenance/asset-search/asset-search.component';
import { ExcelPasteDirective } from './_directives/excel-paste.directive';
import { BooleanToStringPipe } from './_pipes/booleanToString.pipe';
import { DimensionsComponent } from './_shared/Dimensions/dimensions.component';
import { ICON_SETTINGS, IconsModule } from '@progress/kendo-angular-icons';
import { AMSSkeletonComponent } from './_shared/skeleton/skeleton.component';
import { AssetSelectionMultiComponent } from './assetManagement/asset/asset-selection-multi/asset-selection-multi.component';
import { TableDataSearchAssetByAccountNoDirective } from './_directives/table-data-search-asset-by-account-no.directive';

@NgModule({
   imports: [
      FormsModule,
      ReactiveFormsModule,
      CommonModule,
      ButtonsModule,
      InputsModule,
      RippleModule,
      LayoutModule,
      DialogsModule,
      UploadModule,
      FileSelectModule,
      DropDownsModule,
      GridModule,
      PopupModule,
      TooltipModule,
      DateInputsModule,
      NotificationModule,
      GaugesModule,
      PDFModule,
      ExcelModule,
      TreeViewModule,
      TelerikReportingModule,
      ChartsModule,
      IndicatorsModule,
      TreeListModule,
      ListViewModule,
      SchedulerModule,
      LabelModule,
      IconsModule,
      MDBBootstrapModulesPro.forRoot(),
   ],
   declarations: [
      EmptyPipe,
      BooleanToStringPipe,
      ImportComponent,
      Import2Component,
      DocumentComponent,
      InformationBoxComponent,
      RatingComponent,
      AssetStatusComponent,
      AssetSelectionComponent,
      AssetSelectionMultiComponent,
      HasRoleDirective,
      HasRoleDisableDirective,
      HasNotRoleDirective,
      TableDataDirective,
      TableDataSearchAssetByAccountNoDirective,
      TableDataSearchAssetDirective,
      PopupAnchorDirective,
      SecondTableDataDirective,
      ImportLogDirective,
      ClassificationSelectComponent,
      UpgradeEditComponent,
      DisposalEditComponent,
      AssetSearchComponent,
      ExcelPasteDirective,
      DimensionsComponent,
      AMSSkeletonComponent,
   ],
   providers: [
      { provide: ICON_SETTINGS, useValue: { type: 'font' } },
      MDBSpinningPreloader,
      {
         provide: GALLERY_CONFIG,
         useValue: {
            dots: true,
            imageSize: 'cover',
         },
      },
   ],
   exports: [
      EmptyPipe,
      BooleanToStringPipe,
      NotificationModule,
      AssetSelectionComponent,
      AssetSelectionMultiComponent,
      ImportComponent,
      Import2Component,
      DocumentComponent,
      InformationBoxComponent,
      RatingComponent,
      AssetStatusComponent,
      HasRoleDirective,
      HasRoleDisableDirective,
      HasNotRoleDirective,
      TableDataDirective,
      TableDataSearchAssetByAccountNoDirective,
      TableDataSearchAssetDirective,
      SecondTableDataDirective,
      ImportLogDirective,
      ButtonsModule,
      InputsModule,
      RippleModule,
      LayoutModule,
      DialogsModule,
      UploadModule,
      FileSelectModule,
      DropDownsModule,
      GridModule,
      TooltipModule,
      DateInputsModule,
      GaugesModule,
      PDFModule,
      ExcelModule,
      TreeViewModule,
      TelerikReportingModule,
      ClassificationSelectComponent,
      UpgradeEditComponent,
      DisposalEditComponent,
      ChartsModule,
      IndicatorsModule,
      TreeListModule,
      ListViewModule,
      SchedulerModule,
      LabelModule,
      IconsModule,
      AssetSearchComponent,
      ExcelPasteDirective,
      DimensionsComponent,
      MDBBootstrapModulesPro,
      AMSSkeletonComponent,
   ],
})
export class GeneralModule { }
