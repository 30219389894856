import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AMSClient } from '../_models/amsClient';
import { NotifyService } from 'src/app/_services/notify.service';
import { Router } from '@angular/router';

@Component({
   selector: 'app-register',
   templateUrl: './register.component.html',
   styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
   loading = false;
   valForm: UntypedFormGroup;
   client: AMSClient;
   constructor(private fb: UntypedFormBuilder, private authService: AuthService, private notify: NotifyService, private routerService: Router) {}

   ngOnInit() {
      this.valForm = this.fb.group({
         clientCode: [null, Validators.compose([Validators.required])],
         siteCode: [null, Validators.required],
         password: [null, Validators.required],
      });
   }

   register($ev) {
      $ev.preventDefault();
      this.loading = true;
      // tslint:disable-next-line: forin
      for (const c in this.valForm.controls) {
         this.valForm.controls[c].markAsTouched();
      }

      if (this.valForm.valid) {
         this.client = Object.assign({}, this.valForm.value);
         this.authService.registerClient(this.client).subscribe(
            () => {
               this.loading = false;
               var message = 'Successfully Created';
               this.notify.success(message);
               this.routerService.navigate(['/login']);
            },
            (error) => {
               this.loading = false;
               var message = error;
               this.notify.error(message);
            }
         );
      }
   }
}
