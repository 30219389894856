<link href="https://kendo.cdn.telerik.com/2022.1.301/styles/kendo.common.min.css" rel="stylesheet" />
<link href="https://kendo.cdn.telerik.com/2022.1.301/styles/kendo.bootstrap.min.css" rel="stylesheet" />
<div [@fadeInOnEnter] >
  <tr-viewer
     #viewer1
     [containerStyle]="viewerContainerStyle"
     [serviceUrl]="Url"
     [viewMode]="'PRINT_PREVIEW'"
     [scaleMode]="'SPECIFIC'"
     [scale]="1.0"
     [parametersAreaVisible]="true"
     [pageMode]="'CONTINUOUS_SCROLL'"
     [parametersAreaPosition]="'RIGHT'"
     [enableAccessibility]="false"
    
     [UpdateUi]="onUpdateUi()"
     [parameters]="{
        editors: {
          singleSelect: 'COMBO_BOX',
          multiSelect: 'LIST_VIEW'
        }
     }"
  >
  </tr-viewer>
</div>
