import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Role } from '../../_models/role';
import { Observable, Subject } from 'rxjs';
import { PaginatedResult } from '../../_models/pagination';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  baseUrl = environment.apiUrl;
  roleSelected = new Subject<Role>();

  constructor(private http: HttpClient) {}

  changeEditState(role: Role) {
    this.roleSelected.next(role);
  }

  getRolesFull() {
    return this.http.get<Role[]>(this.baseUrl + 'roles');
  }
  getRole(id): Observable<Role> {
    return this.http.get<Role>(this.baseUrl + 'roles/' + id);
  }
  updateRole(id: number, role: Role) {
    return this.http.put(this.baseUrl + 'roles/' + id, role);
  }
  createRole(role: Role) {
    return this.http.post(this.baseUrl + 'roles', role);
  }
  delereRole(id: number) {
    return this.http.delete(this.baseUrl + 'roles/' + id);
  }
}
