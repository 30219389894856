import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';

import { NavComponent } from './_nav/nav.component';
import { routes } from './routes';
import { HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthGuard } from './_guards/auth.guard';

import { AuthService } from './_services/auth.service';
import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { LayoutComponent } from './_layout/layout.component';

import { ListService } from './_services/list.service';

import { RoleService } from './admin/_services/role.service';
import { UserService } from './admin/_services/user.service';
import { SettingService } from './admin/_services/setting.service';
import { ImportService } from './_services/import.service';
import { GeneralModule } from './general.module';
import { BusinessAreaService } from './setup/_services/businessArea.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoginComponent } from './_login/login.component';
import { LoginPasswordChangeComponent } from './_login/loginPasswordChange/loginPasswordChange.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ListSecondService } from './_services/list-second.service';
import { DashboardNumbersComponent } from './dashboard/dashboard-numbers/dashboard-numbers.component';
import { ReportComponent } from './_reportViewer/report/report.component';
import { ReportParameterComponent } from './_reportViewer/report/report-parameter/report-parameter.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { RegisterComponent } from './_register/register.component';
import { ReportDateParameterComponent } from './_reportViewer/report/report-date-parameter/report-date-parameter.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { ReportJournalApprovalComponent } from './_reportViewer/report/report-journal-approval/report-journal-approval.component';
import { ReportReconciliationOverviewByAccountComponent } from './_reportViewer/report/report-reconciliation-overview-by-account/report-reconciliation-overview-by-account.component';
import { DatePipe } from '@angular/common';
import { ReportRequisitionComponent } from './_reportViewer/report/report-requisition/report-requisition.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';


export function tokenGetter() {
   return localStorage.getItem('token');
}
@NgModule({
   declarations: [
      AppComponent,
      NavComponent,
      LayoutComponent,
      LoginComponent,
      RegisterComponent,
      LoginPasswordChangeComponent,
      DashboardNumbersComponent,
      ReportComponent,
      ReportParameterComponent,
      ReportDateParameterComponent,
      ReportJournalApprovalComponent,
      ReportReconciliationOverviewByAccountComponent,
      ReportRequisitionComponent,
   ],
   imports: [
      GeneralModule,
      HttpClientModule,
      BrowserModule,
      BrowserAnimationsModule,
      ReactiveFormsModule,
      FormsModule,
      RouterModule.forRoot(routes, {
         scrollPositionRestoration: 'enabled',
         anchorScrolling: 'enabled',
         scrollOffset: [0, 64],
      }),
      JwtModule.forRoot({
         config: {
            tokenGetter: tokenGetter,
            allowedDomains: ['localhost:5001'],
            disallowedRoutes: ['localhost:5001/api/auth'],
         },
      }),
      PopupModule,
      DateInputsModule,
      LabelModule,
      ButtonsModule,
      IconsModule,
   ],
   // All services must be specified here. The JWT Module currently can't handle lazy loading
   providers: [
      ImportService,
      AuthGuard,
      AuthService,
      ErrorInterceptorProvider,
      ListService,
      RoleService,
      UserService,
      SettingService,
      BusinessAreaService,
      ListSecondService,
      DatePipe,
   ],
   bootstrap: [AppComponent],
   schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
